import * as React from 'react';
import { Title } from '../components/title';
import { ReactSVG } from 'react-svg';
import urlServer from '../server'
import eraser from '../assets/eraser.svg';
import Spinner from '../components/spinner';
import ReactGA from 'react-ga';

//const DateFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/, '$3.$2.$1 $4:$5:$6').slice(0, -9)} else { return "" } };
const DateFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} else { return "" } };

const NumFormatter = ( string ) => {
    let neg = parseFloat(string) < 0
    let str = parseFloat(string) < 0 ? parseFloat(string) * -1 : parseFloat(string)
    let splitString = parseFloat(str).toFixed(2).split(""); 
    const floatNumbers = [","].concat(splitString.slice(splitString.indexOf('.') + 1, splitString.length))
    let reverseArray = splitString.reverse();
    let wocomma = reverseArray.slice(reverseArray.indexOf('.') + 1, reverseArray.length)
    let i = 0
    while(wocomma.length >= i+((i+1)*3+1)) {
      wocomma.splice((i+1)*4-1, 0, '.')
      i++
    }
    let joinArray = wocomma.reverse().concat(floatNumbers);
    
    if(neg) { joinArray.splice(0,0,"-") }
  
    return joinArray.join("")
  };
  
export default class MisComprobantes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            accordion: false,
            verFiltros: false,
            filtrosMarginTop: false,
            postPedidos: {
                "fechaDesde": new Date((new Date()).setDate((new Date()).getDate() - 29)).toISOString().slice(0,10),
                "fechaHasta": new Date().toISOString().slice(0,10),
                "puntoVentaId": JSON.parse(localStorage.infoToken).entidad_id
              },
            busqueda: "",
            comprobantes: [],
            loading: false,
            retiroPresencial: false
        }
    }

    validarVencimientoToken(mensaje)
    {
        if (mensaje){
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) 
            {
              localStorage.removeItem('ddr-auth');
              localStorage.removeItem('ddr-token');
              localStorage.removeItem('is_authenticated');
              localStorage.removeItem('token');
              localStorage.removeItem('infoToken');
            // localStorage.removeItem('refresh-token');
              localStorage.removeItem('expires_in');
              localStorage.removeItem('expires_at');
              window.location.reload();
            }
        }
        return
    }

    componentDidMount = () => {
        this.buscarComprobantes();
        this.retiroPresencialHabilitado();
    }

    buscarComprobantes = () => {
        ReactGA.event({
            category: 'MisComprobantes',
            action: 'Listar Comprobantes'
        });
          
        this.setState(
            {
                loading: true,
                fechaDesdeFiltrada: DateFormatter(this.state.postPedidos.fechaDesde).slice(0, 10),
                fechaHastaFiltrada: DateFormatter(this.state.postPedidos.fechaHasta).slice(0, 10)
            });

        var url = urlServer + '/api/pedidopasadofuturo/miscomprobantes/buscar';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(this.state.postPedidos),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.setState({comprobantes: result})
            this.setState({loading: false});
        }).catch((error) => {
            console.log('error, ', error);
            this.validarVencimientoToken(error.message);
            this.setState({loading: false});
        })
    }

    ocultarFiltros = () => {
        let fechaHasta = document.getElementById('fecha-hasta')
        let fechaDesde = document.getElementById('fecha-desde')
        fechaHasta.min = fechaDesde.value
        this.setState({
            filtrosMarginTop: false,
            postPedidos: {
                ...this.state.postPedidos,
                fechaDesde: fechaDesde.value ? fechaDesde.value : new Date().toISOString().slice(0, 10),
                fechaHasta: fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
            }
        })
        fechaHasta.value = fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
             
      const div = document.getElementById('filtro')
      let divMarginTop = div.getElementsByClassName('filter')[0]
      const marginTop = divMarginTop.offsetHeight
      if (div.className.indexOf('overflow-hidden') !== -1) {
        divMarginTop.style.marginTop = "0"
        setTimeout(() => {div.className = div.className.replace('overflow-hidden', '')}, 550)
        this.setState({verFiltros: true})
      } else {
        div.className += 'overflow-hidden'
        divMarginTop.style.marginTop = `${-marginTop - 3}px`
        this.setState({verFiltros: false})

      }
    }

    clearFilter = async () => {
        const fechaDesde = document.getElementById('fecha-desde')
        const fechaHasta = document.getElementById('fecha-hasta')

        fechaDesde.value = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10)
        fechaHasta.value = new Date().toISOString().slice(0, 10)
        fechaHasta.min = fechaDesde.value
    
        this.setState({
            postPedidos: {
                ...this.state.postPedidos,
                fechaDesde: fechaDesde.value ? fechaDesde.value : new Date().toISOString().slice(0, 10),
                fechaHasta: fechaHasta.value ? fechaHasta.value : new Date().toISOString().slice(0, 10)
            }
        })
    }

    retiroPresencialHabilitado = () => {
        const Links = JSON.parse(localStorage.infoToken)[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];

        //var result = false;
        if (typeof Links !== 'undefined') {
            Links.forEach(element => {
                if (element === 'KIOSCO.Tienda.RetiroPresencial') {
                    this.setState({retiroPresencial: true});
                }
            });
        }       

    }

    imprimirPDF = (pedidoPasadoContenedorId) => {
        ReactGA.event({
            category: 'MisComprobantes',
            action: 'Imprimir'
          });
    
        document.getElementById('open-modal').click();

        var url = urlServer + '/api/pedidopasadofuturo/tienda/comprobante/imprimir';
        var data = {
            pedidoPasadoContenedorId: pedidoPasadoContenedorId,
            tienePermisoRetiroPresencial: this.state.retiroPresencial
        };
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.arrayBuffer();
        })
        .then((result) => {
            document.getElementById('close-modal').click();

            var resultcontent = result;
            var byteArray = new Uint8Array(resultcontent);
            const newBlob = new Blob([byteArray], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            if (window.__POIsNativeMode) {
                link.href = `blobDownload/comprobantecompra_${pedidoPasadoContenedorId.toString()}.pdf/${byteArray}`;
            } else {
                link.href = data;
                link.download = `comprobantecompra_${pedidoPasadoContenedorId.toString()}.pdf`;
                setTimeout(function () {
                    window.URL.revokeObjectURL(data);
                }, 100);
            }
            link.click();
        }).catch((error) => {
            document.getElementById('close-modal').click();
            this.validarVencimientoToken(error.message);
            console.log('error al imprimir comprobante ', error);
        })
    }

    render() {

        const { loading, comprobantes} = this.state
        
        const comprobantEstilo = {
            color: 'rgb(234, 63, 63)', 
            fontWeight: '500', 
            fontSize: '14px'
        }
        const colorLeyenda = {
            color: '#343435'
        }

        const colorLeyendaFecha = {
            color: '#343435',
            fontSize: '13px'
        }
        
        return (
            <div id='pedidos' className="container text-left">
                <Title 
                    classes=""
                    title='Mis Comprobantes'
                    accordion={this.state.accordion}
                    alterAccordion={() => {this.setState({accordion: !this.state.accordion}); this.ocultarFiltros();}}
                />

                <div id="filtro" className={`row ${this.state.verFiltros ? '':'overflow-hidden'}`} style={{display: this.state.verFiltros ? 'block' : 'none', marginTop: this.state.filtrosMarginTop ? '200px': ''}}>
                    <div className={"filter"} > 
                        <div className=" d-flex justify-content-between" style={{marginBottom: '10px'}}>
                            <div style={{width: '33%'}} >
                                <div style={{marginBottom: '12px'}} >
                                Desde
                                </div>
                                <div>
                                    <input id="fecha-desde"
                                        className="form-control filter-input"
                                        type="date"
                                        value={this.state.postPedidos.fechaDesde}
                                        onChange={e => {
                                                let fechaHasta = document.getElementById('fecha-hasta')
                                                fechaHasta.min = e.target.value
                                                this.setState({
                                                    postPedidos: {
                                                    ...this.state.postPedidos, 
                                                    fechaDesde: e.target.value,
                                                    fechaHasta: fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                                                    }
                                                })
                                                fechaHasta.value = fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                                                }} />
                                </div>
                            </div>
                            <div style={{width: '33%'}} >
                                <div style={{marginBottom: '12px'}} >
                                Hasta
                                </div>
                                <div>
                                    <input id="fecha-hasta"
                                        className="form-control filter-input"
                                        type="date"
                                        onChange={e =>
                                            this.setState({
                                                postPedidos: {
                                                    ...this.state.postPedidos,
                                                    fechaHasta: e.target.value
                                                }
                                            })} />
                                </div>
                            </div>
                            <div style={{width: '24%'}} >
                                <div className="eraser" onClick={() => this.clearFilter()}>
                                LIMPIAR
                                <ReactSVG src={eraser} style={{width: '16px'}} />
                                </div>
                                <div style={{width: '100%' }} className="btn button-theme " onClick={() => this.buscarComprobantes()}> Filtrar </div>
                            </div>
                        </div>                      
                    </div>
                </div>
                <button id="open-modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#loader" style={{display: 'none'}}>
                </button>
                <div className="modal" id="loader" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content text-center">
                            <div className="modal-body">
                                <Spinner />
                            </div>
                            Descargando PDF...
                            <div className="modal-footer" style={{display: 'none'}}>
                                <button id="close-modal" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100 ">
                    <input className="w-100 form-control" type="text" placeholder="Buscar" onChange={e => this.setState({busqueda: e.target.value})}/>
                </div>
                <div style={{paddingBottom: '100px'}}>   
                    { loading === true ?
                        <Spinner style={{fontSize: '8px'}} />
                    :                    
                    <div className="cards">
                        {comprobantes.length > 0 ? comprobantes.filter(a => JSON.stringify(Object.values(a)).toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1).map((c, index) => {
                            return (
                                <div key={index} className="box" style={{padding: '15px'}}>
                                    <div className='d-flex justify-content-between mb-2'>
                                        <div style={comprobantEstilo}>
                                            Comprobante {c.pedidoPasadoContenedorId}
                                        </div>
                                        <div>
                                            <span style={colorLeyendaFecha}>{c.fecha}</span>
                                        </div>
                                    </div>
                                    <div style={{display: c.compraPesosCantidad > 0 ? 'flex':'none'}} className='justify-content-between'>
                                        <div className="desc-reclamo">
                                            COMPRA: <span style={colorLeyenda}>{c.compraPesosCantidad === 1 ? c.compraPesosCantidad + ' Unidad' : c.compraPesosCantidad + ' Unidades'}</span>
                                        </div>
                                        <div className="desc-reclamo">
                                            MONTO: <span style={colorLeyenda}>${NumFormatter(c.compraPesosMonto.toString())}</span>
                                        </div>
                                    </div>
                                    <div style={{display: c.habilitadoIncentivo === true && c.compraPorPuntosCantidad > 0 ? 'flex':'none'}} className='justify-content-between mt-2'>
                                        <div className="desc-reclamo">
                                            CANJE: <span style={colorLeyenda}>{c.compraPorPuntosCantidad === 1 ? c.compraPorPuntosCantidad + ' Unidad' : c.compraPorPuntosCantidad + ' Unidades'}</span>
                                        </div>
                                        <div className="desc-reclamo">
                                            PUNTOS: <span style={colorLeyenda}>{c.compraporPuntosPuntos}</span>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '8px'}} >
                                        <div>
                                            &nbsp;
                                        </div>
                                        <div className="descargarcc" style={{color: 'rgb(142, 149, 165)'}} onClick={() => this.imprimirPDF(c.pedidoPasadoContenedorId)} >
                                            DETALLE
                                            <span className="ml-1" style={{fontSize: '12px', color: '#8E95A5', fontFamily: 'FontAwesome'}} >
                                            
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }):
                        <div className="not-found" >
                          No se encontraron comprobantes para la fecha entre {this.state.fechaDesdeFiltrada} y {this.state.fechaHastaFiltrada}
                        </div>}                                                
                    </div>
                    }
                </div>
            </div>
        )
    }
}