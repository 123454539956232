import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg'
import doublearrowup from '../assets/doublearrowup.svg'

export function ThemeInput(props) {
    const [ idFocus, setIdFocus ] = useState('')
    const { hintText, defaultValue, className, inputStyle, labelText, type, readonly, onChange, autoComplete, style, callbackFocus } = props
    var {value} = props
    const idInput = labelText.replace(/ /g, '-').toLowerCase()
 
    const inputFocus = async (e) => {
        await setIdFocus(e.target.id)
    }
    const focusOut = async (e, callbackFocus) => {
        if(idFocus === e.target.id) {
            
            if (callbackFocus) {
                var usuarioPropiedades = [
                    {nombre: "email", id: 9},
                    {nombre:"teléfono", id: 6},
                    {nombre:"calle", id: 18},
                    {nombre:"altura", id: 19},
                    {nombre:"nombre", id: 12},
                    {nombre:"apellido", id: 13},
                    {nombre:"n°-de-vendedor", id: 8},
                    {nombre:"n°-de-línea", id:10},
                    {nombre:"nombre-del-paquete", id:11},
                    {nombre:"n°-de-la-distribuidora", id:14},
                    {nombre:"n°-de-vendedor-según-resumen-de-cuenta",id:8}
                ]
               
                var inputId = e.target.id
                function buscarValor(elemento){
                    return elemento.nombre === inputId
                }
                var datos = {}
                var usuarioPropiedadId = usuarioPropiedades.find(buscarValor)
                const valorEmail = document.getElementById("email").value
                

                        if(usuarioPropiedadId) {
                            datos = {
                                email: valorEmail,
                                propiedadValor: usuarioPropiedadId.id,
                                valor: e.target.value,

                            }  
                            callbackFocus(datos)
                            
                        }
                        if(inputId === "nueva-contraseña"){
                            const nombreDistribuidoraId = document.getElementById("distribuidoraId").value
                            
                            datos = {
                                email: valorEmail,
                                propiedadValor: 7,
                                valor: nombreDistribuidoraId,
                            }  
                            callbackFocus(datos)

                            var aux = null;
                            const provinciaId = document.getElementById("provinciaId");
                            aux = provinciaId.value
                            if(aux !== "-1" && aux !== "")
                            {
                                datos = {
                                    email: valorEmail,
                                    propiedadValor: 15,
                                    valor: aux,
                                }  
                                callbackFocus(datos)
                            }

                            const departamentoId = document.getElementById("partidoId");
                            aux = departamentoId.value
                            if(aux !== "-1" && aux !== "")
                            {
                                datos = {
                                    email: valorEmail,
                                    propiedadValor: 16,
                                    valor: aux,
                                }  
                                callbackFocus(datos)
                            }

                            const localidadId = document.getElementById("localidadId");
                            aux = localidadId.value
                            if(aux !== "-1" && aux !== "")
                            {
                                datos = {
                                    email: valorEmail,
                                    propiedadValor: 17,
                                    valor: aux,
                                }  
                                callbackFocus(datos)
                            }

                           
                        }
            }
            await setIdFocus('')
        }
    }

    return(
        <div className={`theme-input ${className}`} style={style} >
            <input 
                type={type || "text"}
                id={idInput}  
                defaultValue={defaultValue} 
                style={inputStyle} 
                className={ idFocus === idInput ? 'theme-input-box input-focus' : 'theme-input-box'} 
                placeholder={hintText} 
                onFocus={inputFocus} 
                onBlur={(e) => focusOut(e,callbackFocus)} 
                value={value}
                readOnly={readonly} 
                autoComplete={autoComplete || 'off'} 
                onChange={event => {onChange(event)}} />
        </div>
    )
}

export function ThemeButton(props) {
    const { onClick, labelText, style } = props

    return(
        <div className="div-button" style={style} >
            <button className=" theme-button" onClick={onClick}>
             {labelText || 'Submit'}
            </button>
        </div>
    )
}

export function ThemeTopBar(props) {
    const { title } = props

    return(
        <div className="theme-bar background-themecolor">
            <h1 className="theme-title">{title || 'Título'}</h1>
        </div>
        )
}

export function ArrowUp(props) {    
    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.scrollY !== 0){
            setShowScroll(true)
        } else if (showScroll && window.scrollY === 0){
            setShowScroll(false)
        }
    };
    
    window.addEventListener('scroll', checkScrollTop)

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const botonEnviarExiste = document.getElementById('boton-enviar')
    const barraPrecioExiste = document.getElementById('barraPrecio')
    const barraCantidadDevolver = document.getElementById('barra-cantidad-devolver')
    const barraAltaSuscripcion = document.getElementById('barraAltaSuscripcion')
    const barraterminoscondiciones = document.getElementById('barra-terminos-condiciones')
   
    const { style } = props
    return(
        <div id="arrow-up" className={"scrollTop " + (!showScroll ? 'd-none' : '')} onClick={() => { scrollTop() }} style={{ ...style, bottom: (botonEnviarExiste!=null ? (barraPrecioExiste!=null || barraCantidadDevolver != null || barraAltaSuscripcion != null) ? '145px': (barraterminoscondiciones != null) ? '145px' : '110px' : (barraterminoscondiciones != null) ? '100px' :'60px' ), right: '20px', cursor: 'pointer'}} >
            <ReactSVG className="arrow-up" src={doublearrowup} />
        </div>
    )
}

export function ArrowUpPerfil(props) {    
    const [showScroll, setShowScroll] = useState(false)
    const perfil = document.getElementById('perfil-seccion')

    const checkScrollTop = () => {
        if (!showScroll && perfil.scrollY !== 0){
            setShowScroll(true)
        } else if (showScroll && perfil.scrollY === 0){
            setShowScroll(false)
        }
    };
    
    if (perfil) perfil.addEventListener('scroll', checkScrollTop)

    const scrollTop = () =>{
        perfil.scrollTo({top: 0, behavior: 'smooth'})
    };

    const botonEnviarExiste = document.getElementById('boton-enviar')
    const barraPrecioExiste = document.getElementById('barraPrecio')
   
    const { style } = props
    return(
        <div id="arrow-upPerfil" className={"scrollTop " + (!showScroll ? 'd-none' : '')} onClick={() => { scrollTop() }} style={{ ...style, bottom: (botonEnviarExiste!=null ? barraPrecioExiste!=null? '145px' : '110px':'60px'), right: '20px', cursor: 'pointer'}} >
            <ReactSVG className="arrow-up" src={doublearrowup} />
        </div>
    )
}

export function ArrowUpModalSingleButton(props) {    
    const [showScroll, setShowScroll] = useState(false)
    const [body, setBody] = useState(null)

    
    useEffect(() =>{
        setBody(document.getElementById('modal-body-id-singlebutton'))
        if (body) 
        {
            body.addEventListener('scroll', checkScrollTop)
        }
    }, [body])
       
    const checkScrollTop = () => {
        if ( body && !showScroll && body.scrollY !== 0 ){
            setShowScroll(true)
        } else if ( body && showScroll && body.scrollY === 0 ){
            setShowScroll(false)
        }
    };
    
    //if (perfil) perfil.addEventListener('scroll', checkScrollTop)

    const scrollTop = () =>{
        if(body)
        {
            body.scrollTo({top: 0, behavior: 'smooth'})
        }   
    };

    const botonEnviarExiste = document.getElementById('boton-enviar')
    const barraPrecioExiste = document.getElementById('barraPrecio')
   
    const { style } = props
    return(
        <div id="arrow-upPerfil" className={"scrollTop " + (!showScroll ? 'd-none' : '')} onClick={() => { scrollTop() }} style={{ ...style, bottom: (botonEnviarExiste!=null ? barraPrecioExiste!=null? '145px' : '130px':'60px'), right: '20px', cursor: 'pointer'}} >
            <ReactSVG className="arrow-up" src={doublearrowup} />
        </div>
    )
}