import * as React from "react";
//import brandLogo from '../assets/brand-logo.png'
import Consumer from "../store/perfil";
import flechaPerfil from "../assets/flecha-perfil.svg";
import gps from "../assets/gps.svg";
import horario from "../assets/horario.svg";
import guardar from "../assets/guardar.svg";
import fotoPerfil from "../assets/perfil.svg";
import seleccionar from "../assets/seleccionar-reclamos.svg";
import telefono from "../assets/telefono.svg";
import repartologo from "../assets/repartologo.svg";
import metodopagologo from "../assets/metodopagologo.svg";
import identificacion from "../assets/identificacion.svg";
import urlServer from "../server";
import { ReactSVG } from "react-svg";
import TimeInput from "material-ui-time-picker";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Map from "./Map";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { guardarConexion } from "../guardarConexion";
import { ThemeInput } from "./items";
import questionMark from "../assets/questionMark.svg";
import {
  getTelefono,
  getNombreYapellido,
  getEmail,
} from "../localStorageManager";
import { ArrowUpPerfil } from "./items";

const MySwal = withReactContent(Swal);

export default class Perfil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datosPVbackup: {},
      datosPV: {},
      mapModal: false,
      modNombre: false,
      modTelefono: false,
      modContraseña: false,
      modUbicacion: false,
      modGPS: false,
      modHorario: false,
      modBooleanos: false,
      selectedOption: null,
      defaultSel: {
        localidad: null,
        departamento: null,
        provincia: null,
      },
      camposAModificar: [],
      listaDepartamentos: [],
      listaLocalidades: [],
      listaProvincias: [],
      provinciaSelected: { value: null, label: "Seleccione una provincia" },
      localidadSelected: null,
      departamentoSelected: { value: null, label: "Seleccione un partido" },
      usuarioAModificar: {
        usuarioId: JSON.parse(localStorage.infoToken).usuario_id,
      },
      usuario: {},
      typechecked: "dia",
      diachecked: true,
      lavchecked: false,
      horarioAModificar: [],
      domicilioAModificar: [],
      errorProv: null,
      errorDpto: null,
      errorLocal: null,
      errorCalle: null,
      errorAltura: null,
      redirect: false,
      init: false,
      deshabilitarSwitchEfectivo: "auto",
      deshabilitarSwitchPosnet: "auto",
      deshabilitarSwitchBilleteraVirtual: "auto",
      mostrarAvisoMedioPago: false,
      mostrarNombreCompleto: false,
      alertaApellido: false,
      alertaNombre: false,
      currentProvincia: "",
      currentPartido: "",
      currentLocalidad: "",
      localidadSelectedBackup: {},
      provinciaSelectedBackup: {},
      departamentoSelectedBackup: {},
      estaEnPerfil: this.props.estaEnPerfil,
      email: getEmail(),
    };
  }

  validarVencimientoToken(mensaje) {
    if (mensaje) {
      if (
        mensaje === "Authorization has been denied for this request." ||
        mensaje.indexOf("Unexpected token") !== -1
      ) {
        localStorage.removeItem("ddr-auth");
        localStorage.removeItem("ddr-token");
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("infoToken");
        // localStorage.removeItem('refresh-token');
        localStorage.removeItem("expires_in");
        localStorage.removeItem("expires_at");
        //
        localStorage.removeItem("menu-ProdMasVendido");
        window.location.reload();
        return;
      }
    }
    return;
  }

  cargar = async () => {
    await this.listarProvincias();
    const { datosPVbackup } = this.state;
    await this.setState({
      provinciaSelected: this.state.listaProvincias.filter(
        (e) =>
          e.value ===
          (datosPVbackup.domicilio && datosPVbackup.domicilio.provinciaId)
      )[0],
      provinciaSelectedBackup: this.state.listaProvincias.filter(
        (e) =>
          e.value ===
          (datosPVbackup.domicilio && datosPVbackup.domicilio.provinciaId)
      )[0],
    });
    if (
      this.state.listaProvincias.length > 0 &&
      this.state.datosPVbackup.domicilio
    ) {
      await this.listarDepartamentos(
        datosPVbackup.domicilio.partidoDepartamentoDescripcion.toLowerCase()
      );
      const departamentoSelected =
        this.state.listaDepartamentos.filter(
          (e) =>
            e.label.toLowerCase() ===
            datosPVbackup.domicilio.partidoDepartamentoDescripcion.toLowerCase()
        )[0] ||
        this.state.listaDepartamentos.filter((e) => e.value === null)[0];
      await this.listarLocalidades(
        datosPVbackup.domicilio.localidadDescripcion.toLowerCase()
      );
      const localidadSelected =
        this.state.listaLocalidades.filter(
          (e) =>
            e.label.toLowerCase() ===
            datosPVbackup.domicilio.localidadDescripcion.toLowerCase()
        )[0] || this.state.listaLocalidades.filter((e) => e.value === null)[0];
      this.setState({
        departamentoSelected: departamentoSelected,
        localidadSelected: localidadSelected,
        departamentoSelectedBackup: departamentoSelected,
        localidadSelectedBackup: localidadSelected,
      });
    }
  };

  enviarModificaciones = async (arr) => {
    const puntoVentaId = JSON.parse(localStorage.infoToken).entidad_id;
    const usuarioIdUltimaModificacion = JSON.parse(
      localStorage.infoToken
    ).usuario_id;
    const data = {
      puntoVentaId,
      usuarioIdUltimaModificacion,
      valores: arr,
    };
    if (data.valores.length > 0) {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.token,
      };
      const respuesta = await fetch(urlServer + "/api/puntoVenta/modificar", {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(data),
        headers,
      })
        .then((response) => response.text())
        .catch((error) => {
          console.log("error", error);
        })
        .then((result) => {
          const res = JSON.parse(result);

          this.setState({
            datosPVbackup: this.state.datosPV,
            provinciaSelectedBackup: this.state.provinciaSelected,
            departamentoSelectedBackup: this.state.departamentoSelected,
            localidadSelectedBackup: this.state.localidadSelected,
          });

          if (arr === this.state.domicilioAModificar) {
            this.setState({
              domicilioAModificar: [],
            });
          }

          MySwal.fire({
            icon: "success",
            title: "¡Se han actualizado los datos con éxito!",
            showConfirmButton: false,
            timer: 1500,
            customClass: "swalIconCustom",
          });

          return res;
        })
        .catch((error) => {
          this.validarVencimientoToken(error.message);
          console.log("error", error);
        });
      return respuesta;
    }
  };

  enviarUsuario = async (obj) => {
    var token = JSON.parse(localStorage.infoToken);
    //const usuarioId = JSON.parse(localStorage.infoToken).usuario_id
    const usuarioId = token.usuario_id;
    let data = {
      ...obj,
      usuarioId,
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    const respuesta = await fetch(urlServer + "/api/usuario/modificar", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify(data),
      headers,
    })
      .then((response) => response.text())
      .catch((error) => {
        console.log("error", error);
      })
      .then(async (result) => {
        const res = JSON.parse(result);
        this.setState({
          usuarioAModificar: {},
        });

        if (typeof obj.telefono !== "undefined") {
          token.telefono = obj.telefono;
        }

        if (
          typeof obj.nombre !== "undefined" ||
          typeof obj.apellido !== "undefined"
        ) {
          if (typeof obj.nombre !== "undefined")
            token.nombre = obj.nombre.trim();

          if (typeof obj.apellido !== "undefined")
            token.apellido = obj.apellido.trim();

          token.nya = token.nombre + " " + token.apellido;
        }

        localStorage.setItem("infoToken", JSON.stringify(token));
        //this.props.GuardarDatosPerfil();

        return res;
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  separarPalabras = (arr = {}) => {
    const repartoInfo = {
      tieneReparto: arr.tieneReparto ? arr.tieneReparto : false,
      entregaSuscripcion: arr.entregaSuscripcion
        ? arr.entregaSuscripcion
        : false,
      cargaDiario: arr.cargarDiario ? arr.cargarDiario : false,
      cargaRevista: arr.cargaRevista ? arr.cargaRevista : false,
    };
    const palabras = Object.keys(repartoInfo);
    const booleanos = palabras.map((palabra, index) => {
      let boolean = {};
      boolean[palabra] = arr[palabra];
      boolean.item = palabra;
      boolean.texto =
        palabra.slice(0, palabra.match(/[A-Z]/).index) +
        " " +
        palabra.slice(palabra.match(/[A-Z]/).index, palabra.length);
      boolean.texto = boolean.texto.toUpperCase().trim();
      return boolean;
    });
    const divs = booleanos.map((boolean, index) => {
      return (
        <React.Fragment key={index}>
          <div className="d-flex  justify-content-between align-items-center pr-0 f-13 fw-500">
            <div>{boolean.texto}</div>
            <div>
              <div className="">
                <div className="custom-control custom-switch">
                  <label className="switch">
                    <input
                      type="checkbox"
                      id={"customSwitch" + index}
                      defaultChecked={boolean[boolean.item] ? true : null}
                      onClick={(e) => {
                        const modificado = {
                          Campo:
                            "reparto." +
                            boolean.item[0].toUpperCase().trim() +
                            boolean.item.slice(1, boolean.item.length),
                          Valor: e.target.checked.toString().trim(),
                        };

                        if (
                          boolean.item[0].toUpperCase().trim() +
                            boolean.item.slice(1, boolean.item.length) ===
                          "TieneReparto"
                        ) {
                          ReactGA.event({
                            category: "Perfil",
                            action: "Mostrar Tiene Reparto del Punto Venta",
                          });
                        }

                        if (
                          boolean.item[0].toUpperCase().trim() +
                            boolean.item.slice(1, boolean.item.length) ===
                          "EntregaSuscripcion"
                        ) {
                          ReactGA.event({
                            category: "Perfil",
                            action:
                              "Mostrar Entrega Suscripcion del Punto de Venta",
                          });
                        }

                        if (
                          boolean.item[0].toUpperCase().trim() +
                            boolean.item.slice(1, boolean.item.length) ===
                          "CargaDiario"
                        ) {
                          ReactGA.event({
                            category: "Perfil",
                            action: "Mostrar Carga Diario del Punto de Venta",
                          });
                        }

                        if (
                          boolean.item[0].toUpperCase().trim() +
                            boolean.item.slice(1, boolean.item.length) ===
                          "CargaRevista"
                        ) {
                          ReactGA.event({
                            category: "Perfil",
                            action: "Mostrar Carga Revista del Punto de Venta",
                          });
                        }

                        this.enviarModificaciones([modificado]);
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </React.Fragment>
      );
    });
    return divs;
  };

  letrasMayus = (nombre) => {
    let array = nombre.split("");
    let mayus = array.filter((e) => e !== " " && e === e.toUpperCase().trim());
    return mayus.slice(0, 2).join("");
  };

  logOut = async () => {
    const apiUrl = urlServer + "/api/account/logout";
    let myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.token);
    let usuarioId = JSON.parse(localStorage.getItem("infoToken")).usuario_id;

    guardarConexion("LOGOUT", usuarioId);
    await fetch(apiUrl, {
      method: "POST",
      headers: myHeaders,
      redirect: "manual",
    })
      .then((response) => response.text())
      .catch((error) => {
        console.log("error", error);
      })
      .then((res) => {
        //localStorage.clear()
        localStorage.removeItem("ddr-auth");
        localStorage.removeItem("ddr-token");
        localStorage.removeItem("is_authenticated");
        localStorage.removeItem("token");
        localStorage.removeItem("infoToken");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("result");
        //
        localStorage.removeItem("menu-ProdMasVendido");

        this.setState({
          redirect: true,
        });
        this.props.setLanding([]);
        this.props.loggingOut();
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
  };

  ObtenerDomicilio = async () => {
    const data = {
      puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    const respuesta = await fetch(urlServer + "/api/puntoVenta/buscar", {
      method: "POST",
      redirect: "manual",
      body: JSON.stringify(data),
      headers,
    })
      .then((response) => response.json())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then(async (result) => {
        try {
          if (result) {
            let datosPV = result ? result : "";
            if (datosPV.domicilio) {
              if (!datosPV.domicilio.x || !datosPV.domicilio.y) {
                const coord = await this.getAddress(datosPV.domicilio);
                if (coord) {
                  datosPV.domicilio.x = coord.lat;
                  datosPV.domicilio.y = coord.lon;
                }
              }
            }
            let datosPVbackup =
              result !== undefined ? JSON.parse(JSON.stringify(datosPV)) : "";
            await this.setState({
              datosPVbackup,
              datosPV,
              currentLocalidad: datosPV.domicilio
                ? datosPV.domicilio.localidadDescripcion
                : "",
              currentPartido: datosPV.domicilio
                ? datosPV.domicilio.partidoDepartamentoDescripcion
                : "",
              currentProvincia: datosPV.domicilio
                ? datosPV.domicilio.provinciaDescripcion
                : "",
            });
          }
          return result;
        } catch (error) {
          console.log("ERROR DE OPEN STREET API >>>>>>>", error);
        }
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  agregarDias = () => {
    let horarios = this.state.datosPV.horarios
      ? this.state.datosPV.horarios
      : [];
    let domingo = [];
    let dias = [];
    if (!horarios || !horarios[0]) {
      for (let index = 1; index < 8; index++) {
        horarios[index - 1] = {
          diaSemana: index,
          horaInicio: null,
          horaFin: null,
        };
      }
    }
    for (let index = 0; index < horarios.length; index++) {
      const element = horarios[index];
      let dia = "";
      let diaCompleto = "";
      switch (element.diaSemana) {
        case 2:
          dia = "Lu";
          diaCompleto = "Lunes";
          break;
        case 3:
          dia = "Mar";
          diaCompleto = "Martes";
          break;
        case 4:
          dia = "Mié";
          diaCompleto = "Miercoles";
          break;
        case 5:
          dia = "Jue";
          diaCompleto = "Jueves";
          break;
        case 6:
          dia = "Vie";
          diaCompleto = "Viernes";
          break;
        case 7:
          dia = "Sáb";
          diaCompleto = "Sabado";
          break;
        case 1:
          dia = "Dom";
          diaCompleto = "Domingo";
          break;

        default:
          break;
      }
      element.dia = dia;
      element.diaCompleto = diaCompleto;
      if (element.diaSemana !== 1) {
        dias = [...dias, element];
      } else {
        domingo = element;
      }
    }
    dias = [...dias, domingo];
    let { datosPV } = this.state;
    datosPV = {
      ...datosPV,
      horarios: dias,
    };

    let datosPVbackup = JSON.parse(JSON.stringify(datosPV));
    this.setState({
      datosPV,
      datosPVbackup,
    });
  };

  renderHorario = () => {
    var horarios = this.state.datosPV.horarios
      ? this.state.datosPV.horarios
      : null;
    const render = horarios
      ? horarios.map((dia, index) => {
          return (
            <div
              key={index}
              className="d-flex w-100 px-2 justify-content-between"
            >
              <div className="fw-500">{dia.dia}</div>
              <div className="d-flex justify-content-between">
                <div>
                  {dia.horaInicio ? dia.horaInicio.slice(0, 5) : "--:--"}
                </div>
                <div>{dia.horaFin ? dia.horaFin.slice(0, 5) : "--:--"}</div>
              </div>
            </div>
          );
        })
      : null;

    return render;
  };

  renderInputs = () => {
    var horarios = this.state.datosPV.horarios
      ? this.state.datosPV.horarios
      : null;
    const render = horarios
      ? horarios.map((dia, index) => {
          let horaInicio = dia.horaInicio ? dia.horaInicio.split(":") : null;
          let horaFin = dia.horaFin ? dia.horaFin.split(":") : null;

          if (horaInicio) {
            horaInicio =
              +(parseInt(horaInicio[0]) + 3) * (60000 * 60) +
              +horaInicio[1] * 60000 +
              +horaInicio[2] * 1000;
          }

          if (horaFin) {
            horaFin =
              +(parseInt(horaFin[0]) + 3) * (60000 * 60) +
              +horaFin[1] * 60000 +
              +horaFin[2] * 1000;
          }
          return (
            <div key={index} className="  pr-0 justify-content-between">
              <div className="fw-500">{dia.dia}</div>
              <div
                className="d-flex time-inputs"
                style={
                  this.state.typechecked === "lav" && index > 0 && index < 5
                    ? { pointerEvents: "none", backgroundColor: "#E5E5E5   " }
                    : {}
                }
              >
                <TimeInput
                  mode="24h"
                  value={new Date(horaInicio ? horaInicio : 3600000 * 3)}
                  className="dias-time-input"
                  cancelLabel={"Cancelar"}
                  onChange={(time) => {
                    const stringInicio =
                      ("0" + time.getHours().toString()).slice(-2) +
                      ":" +
                      ("0" + time.getMinutes().toString()).slice(-2) +
                      ":00";
                    dia.horaInicio = stringInicio;
                    let dias = horarios;
                    let { horarioAModificar } = this.state;
                    let index = dias.findIndex(
                      (e) => e.diaSemana === dia.diaSemana
                    );
                    if (this.state.typechecked === "lav" && index < 5) {
                      let i = 0;
                      while (i < 5) {
                        dias[i].horaInicio = dia.horaInicio;
                        let modificado = {
                          Campo:
                            "horario." + dias[i].diaCompleto + ".HoraInicio",
                          Valor: stringInicio,
                        };
                        if (
                          horarioAModificar.filter(
                            (e) => e.Campo === modificado.Campo
                          ).length !== 0
                        ) {
                          horarioAModificar[
                            horarioAModificar.findIndex(
                              (e) => e.Campo === modificado.Campo
                            )
                          ].Valor = stringInicio;
                        } else {
                          horarioAModificar = [
                            ...horarioAModificar,
                            modificado,
                          ];
                        }
                        i++;
                      }
                    } else {
                      dias[index] = dia;
                      let modificado = {
                        Campo: "horario." + dia.diaCompleto + ".HoraInicio",
                        Valor: stringInicio,
                      };
                      if (
                        horarioAModificar.filter(
                          (e) => e.Campo === modificado.Campo
                        ).length !== 0
                      ) {
                        horarioAModificar[
                          horarioAModificar.findIndex(
                            (e) => e.Campo === modificado.Campo
                          )
                        ].Valor = stringInicio;
                      } else {
                        horarioAModificar = [...horarioAModificar, modificado];
                      }
                    }

                    this.setState({
                      datosPV: {
                        ...this.state.datosPV,
                        horarios: dias,
                      },
                      horarioAModificar,
                    });
                  }}
                />
                <div style={{ lineHeight: "25px", marginRight: "3px" }}>-</div>
                <TimeInput
                  mode="24h"
                  value={new Date(horaFin ? horaFin : 3600000 * 3)}
                  className="dias-time-input"
                  cancelLabel={"Cancelar"}
                  onChange={(time) => {
                    const stringFin =
                      ("0" + time.getHours().toString()).slice(-2) +
                      ":" +
                      ("0" + time.getMinutes().toString()).slice(-2) +
                      ":00";
                    dia.horaFin = stringFin;
                    let dias = horarios;
                    let index = dias.findIndex(
                      (e) => e.diaSemana === dia.diaSemana
                    );
                    let { horarioAModificar } = this.state;
                    if (this.state.typechecked === "lav" && index < 5) {
                      let i = 0;
                      while (i < 5) {
                        dias[i].horaFin = dia.horaFin;
                        let modificado = {
                          Campo: "horario." + dias[i].diaCompleto + ".HoraFin",
                          Valor: stringFin,
                        };
                        if (
                          horarioAModificar.filter(
                            (e) => e.Campo === modificado.Campo
                          ).length !== 0
                        ) {
                          horarioAModificar[
                            horarioAModificar.findIndex(
                              (e) => e.Campo === modificado.Campo
                            )
                          ].Valor = stringFin;
                        } else {
                          horarioAModificar = [
                            ...horarioAModificar,
                            modificado,
                          ];
                        }
                        i++;
                      }
                    } else {
                      dias[index] = dia;
                      let modificado = {
                        Campo: "horario." + dia.diaCompleto + ".HoraFin",
                        Valor: stringFin,
                      };
                      if (
                        horarioAModificar.filter(
                          (e) => e.Campo === modificado.Campo
                        ).length !== 0
                      ) {
                        horarioAModificar[
                          horarioAModificar.findIndex(
                            (e) => e.Campo === modificado.Campo
                          )
                        ].Valor = stringFin;
                      } else {
                        horarioAModificar = [...horarioAModificar, modificado];
                      }
                    }
                    this.setState({
                      datosPV: {
                        ...this.state.datosPV,
                        horarios: dias,
                      },
                      horarioAModificar,
                    });
                  }}
                />
              </div>
            </div>
          );
        })
      : null;

    return render;
  };

  listarProvincias = async () => {
    const data = {
      PalabraABuscar: "",
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    const respuesta = await fetch(
      urlServer + "/api/domicilio/provincia/listar",
      {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(data),
        headers,
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then((result) => {
        let options = result.map((prov) => {
          const value = {
            value: prov.provinciaId,
            label: prov.descripcion,
          };
          return value;
        });
        options = [
          { value: null, label: "Seleccione una provincia" },
          ...options,
        ];
        this.setState({
          listaProvincias: options,
        });
        return options;
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  listarDepartamentos = async (palabra) => {
    const data = {
      PalabraABuscar: palabra,
      ProvinciaId: this.state.provinciaSelected
        ? this.state.provinciaSelected.value
        : null,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    const respuesta = await fetch(
      urlServer + "/api/domicilio/departamento/listar",
      {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(data),
        headers,
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then(async (result) => {
        let options = result.map((dpto) => {
          const value = {
            value: dpto.partidoDepartamentoId,
            label: dpto.descripcion,
          };
          return value;
        });
        options = [{ value: null, label: "Seleccione un partido" }, ...options];
        const departamento = options.filter(
          (e) =>
            e.label ===
            (this.state.datosPV.domicilio &&
              this.state.datosPV.domicilio.partidoDepartamentoDescripcion)
        )[0];
        await this.setState({
          defaultSel: {
            ...this.state.defaultSel,
            departamento,
          },
        });
        this.setState({
          listaDepartamentos: options,
        });
        return options;
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  listarLocalidades = async (palabra) => {
    const data = {
      PalabraABuscar: palabra,
      ProvinciaId: this.state.provinciaSelected
        ? this.state.provinciaSelected.value
        : null,
      PartidoDepartamentoId: this.state.departamentoSelected
        ? this.state.departamentoSelected.value
        : null,
    };
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.token,
    };
    const respuesta = await fetch(
      urlServer + "/api/domicilio/localidad/listar",
      {
        method: "POST",
        redirect: "manual",
        body: JSON.stringify(data),
        headers,
      }
    )
      .then((response) => response.json())
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      })
      .then(async (result) => {
        let options = result.map((loc) => {
          const value = {
            value: loc.localidadId,
            label: loc.descripcion,
          };
          return value;
        });
        options = [
          { value: null, label: "Seleccione una Localidad" },
          ...options,
        ];
        const localidad = options.filter(
          (e) =>
            e.label ===
            (this.state.datosPV.domicilio &&
              this.state.datosPV.domicilio.localidadDescripcion)
        )[0];
        await this.setState({
          defaultSel: {
            ...this.state.defaultSel,
            localidad,
          },
        });
        this.setState({
          listaLocalidades: options,
        });
        return options;
      })
      .catch((error) => {
        this.validarVencimientoToken(error.message);
        console.log("error", error);
      });
    return respuesta;
  };

  handleProvinciaChange = async (selected) => {
    const campo = "domicilio.ProvinciaId";
    let modificado =
      selected.value &&
      selected.value !==
        (this.state.datosPV.domicilio &&
          this.state.datosPV.domicilio.provinciaId)
        ? {
            Campo: campo,
            Valor: selected.value,
          }
        : null;
    await this.setState({
      provinciaSelected: null,
      departamentoSelected: null,
    });
    let { datosPV } = this.state;

    if (datosPV.domicilio) {
      datosPV.domicilio.localidadDescripcion = "";
      datosPV.domicilio.partidoDepartamentoDescripcion = "";
    }

    await this.setState({
      datosPV,
    });
    this.setState({
      provinciaSelected: selected,
      departamentoSelected: this.state.listaDepartamentos.filter(
        (e) => (e.value = "null")
      )[0],
      currentProvincia: selected.label,
    });
    let { domicilioAModificar } = this.state;

    if (modificado) {
      if (
        domicilioAModificar.filter((e) => e.Campo === modificado.Campo)
          .length !== 0
      ) {
        domicilioAModificar[
          domicilioAModificar.findIndex((e) => e.Campo === modificado.Campo)
        ].Valor = modificado.Valor;
      } else {
        domicilioAModificar = [...domicilioAModificar, modificado];
      }
      if (datosPV.domicilio) {
        datosPV.domicilio.provinciaDescripcion = selected.label;
        datosPV.domicilio.provinciaId = selected.value;
      }

      this.setState({
        datosPV,
        domicilioAModificar,
      });
    } else {
      if (domicilioAModificar.filter((e) => e.Campo === campo).length !== 0) {
        domicilioAModificar = domicilioAModificar.filter(
          (e) => e.Campo !== campo
        );
      }
      this.setState({
        domicilioAModificar,
      });
    }

    if (
      domicilioAModificar.filter((e) => e.Campo === "domicilio.DepartamentoId")
        .length !== 0
    ) {
      domicilioAModificar[
        domicilioAModificar.findIndex(
          (e) => e.Campo === "domicilio.DepartamentoId"
        )
      ].Valor = null;
    } else {
      domicilioAModificar = [
        ...domicilioAModificar,
        { Campo: "domicilio.DepartamentoId", Valor: null },
      ];
    }

    if (
      domicilioAModificar.filter((e) => e.Campo === "domicilio.LocalidadId")
        .length !== 0
    ) {
      domicilioAModificar[
        domicilioAModificar.findIndex(
          (e) => e.Campo === "domicilio.LocalidadId"
        )
      ].Valor = null;
    } else {
      domicilioAModificar = [
        ...domicilioAModificar,
        { Campo: "domicilio.LocalidadId", Valor: null },
      ];
    }
  };

  handleDepartamentoChange = async (selected) => {
    let { datosPV } = this.state;
    const campo = "domicilio.DepartamentoId";
    let modificado =
      selected.value &&
      selected.value !==
        (this.state.datosPV.domicilio &&
          this.state.datosPV.domicilio.partidoDepartamentoId)
        ? {
            Campo: campo,
            Valor: selected.value,
          }
        : null;
    await this.setState({
      departamentoSelected: null,
    });
    this.setState({
      departamentoSelected: selected,
      currentPartido: selected.label,
    });
    let { domicilioAModificar } = this.state;
    if (modificado) {
      if (
        domicilioAModificar.filter((e) => e.Campo === modificado.Campo)
          .length !== 0
      ) {
        domicilioAModificar[
          domicilioAModificar.findIndex((e) => e.Campo === modificado.Campo)
        ].Valor = modificado.Valor;
      } else {
        domicilioAModificar = [...domicilioAModificar, modificado];
      }

      datosPV.domicilio.partidoDepartamentoDescripcion = selected.label;
      datosPV.domicilio.partidoDepartamentoId = selected.value;

      this.setState({
        datosPV,
        domicilioAModificar,
      });
    } else {
      if (domicilioAModificar.filter((e) => e.Campo === campo).length !== 0) {
        domicilioAModificar = domicilioAModificar.filter(
          (e) => e.Campo !== campo
        );
      }
      this.setState({
        domicilioAModificar,
      });
    }

    if (
      domicilioAModificar.filter((e) => e.Campo === "domicilio.LocalidadId")
        .length !== 0
    ) {
      domicilioAModificar[
        domicilioAModificar.findIndex(
          (e) => e.Campo === "domicilio.LocalidadId"
        )
      ].Valor = null;
    } else {
      domicilioAModificar = [
        ...domicilioAModificar,
        { Campo: "domicilio.LocalidadId", Valor: null },
      ];
    }
    if (datosPV.domicilio) datosPV.domicilio.localidadDescripcion = "";

    this.setState({
      datosPV,
      localidadSelected: { value: null, label: "Seleccione una Localidad" },
    });
  };

  handleLocalidadChange = (selected) => {
    let { datosPV } = this.state;
    const campo = "domicilio.LocalidadId";
    let modificado =
      selected.value &&
      selected.value !==
        (this.state.datosPV.domicilio &&
          this.state.datosPV.domicilio.localidadId)
        ? {
            Campo: campo,
            Valor: selected.value,
          }
        : null;
    this.setState({
      localidadSelected: selected,
      currentLocalidad: selected.label,
    });
    let { domicilioAModificar } = this.state;
    if (modificado) {
      if (
        domicilioAModificar.filter((e) => e.Campo === modificado.Campo)
          .length !== 0
      ) {
        domicilioAModificar[
          domicilioAModificar.findIndex((e) => e.Campo === modificado.Campo)
        ].Valor = modificado.Valor;
      } else {
        domicilioAModificar = [...domicilioAModificar, modificado];
      }

      datosPV.domicilio.localidadDescripcion = selected.label;
      datosPV.domicilio.localidadId = selected.value;

      this.setState({
        datosPV,
        domicilioAModificar,
      });
    } else {
      if (domicilioAModificar.filter((e) => e.Campo === campo).length !== 0) {
        domicilioAModificar = domicilioAModificar.filter(
          (e) => e.Campo !== campo
        );
      }
      this.setState({
        domicilioAModificar,
      });
    }
  };

  getAddress = async (domicilio) => {
    try {
      let data;
      let urlArr = [
        domicilio.localidadDescripcion
          ? "&county=" + domicilio.localidadDescripcion
          : "",
        domicilio.provinciaDescripcion
          ? "&state=" + domicilio.provinciaDescripcion
          : "",
        domicilio.calle
          ? "&street=" + domicilio.numero + " " + domicilio.calle
          : "",
        domicilio.partidoDepartamentoDescripcion
          ? "&city=" + domicilio.partidoDepartamentoDescripcion
          : "",
      ];
      let i = 0;
      while (!data) {
        let params = "";
        let index = i;
        for (i = index; i < urlArr.length; i++) {
          params += urlArr[i];
        }
        i = index + 1;
        const url = (
          "https://nominatim.openstreetmap.org/search?format=json&country=Argentina" +
          params
        ).replace(" ", "%20");
        const response = await fetch(url)
          .then((res) => {
            if (res?.ok) {
              return res.json();
            } else {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
          })
          .catch((err) => console.log(err));
        data = response ? (data = await response[0]) : null;
        if (i >= urlArr.length && !data) {
          break;
        }
      }
      return data;
    } catch (error) {
      console.log("ERROR DE OPEN STREET API >>>>>>>", error);
    }
  };

  validarDireccion() {
    const calle = document.getElementById("calle");
    const altura = document.getElementById("altura");
    const errorCalle = calle.value ? false : true;
    var errorAltura = false;
    if (altura.value) {
      errorAltura = altura.value >= 0 ? false : true;
    }
    altura.value < 0
      ? this.setState({ alturaInvalida: true })
      : this.setState({ alturaInvalida: false });
    const errorProv =
      this.state.provinciaSelected &&
      this.state.provinciaSelected.value !== "null" &&
      this.state.provinciaSelected.value !== null
        ? false
        : true;
    const errorDpto =
      this.state.departamentoSelected &&
      this.state.departamentoSelected.value !== "null" &&
      this.state.departamentoSelected.value !== null
        ? false
        : true;
    const errorLocal =
      this.state.localidadSelected &&
      this.state.localidadSelected.value !== "null" &&
      this.state.localidadSelected.value !== null
        ? false
        : true;
    calle.style.border = !calle.value ? "1px solid red" : "";
    altura.style.border = !altura.value ? "1px solid red" : "";
    this.setState({
      errorProv,
      errorDpto,
      errorLocal,
      errorCalle,
      errorAltura,
    });
  }

  controlarTelefonoInput = (tel = null) => {
    let phoneEx =
      /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/g;

    if (tel.match(phoneEx) || !tel) {
      return false;
    } else {
      return true;
    }
  };

  obtenerTelefonoNombreYApellido() {
    let nombreYappelido = getNombreYapellido();
    let tel = getTelefono();

    this.setState({
      usuario: {
        nombre: nombreYappelido.nombre,
        apellido: nombreYappelido.apellido,
        telefono: tel,
      },
    });
  }

  componentDidMount = async () => {
    this.obtenerTelefonoNombreYApellido();
    await this.ObtenerDomicilio();
    this.props.setStateEstaEnPerfil();
    this.deshabilitarSwitch();
    //const { datosPV } = this.state
    await this.setState({
      mapModal: true,
    });

    this.agregarDias();

    ReactGA.event({
      category: "Perfil",
      action: "Mostrar Perfil",
    });
  };

  deshabilitarSwitch() {
    const { datosPV } = this.state;
    if (datosPV.mediosPago) {
      if (
        datosPV.mediosPago.efectivo &&
        !datosPV.mediosPago.posnet &&
        !datosPV.mediosPago.billeteraVirtual
      ) {
        this.setState({ deshabilitarSwitchEfectivo: "none" });
      } else {
        this.setState({ deshabilitarSwitchEfectivo: "auto" });
      }

      if (
        !datosPV.mediosPago.efectivo &&
        datosPV.mediosPago.posnet &&
        !datosPV.mediosPago.billeteraVirtual
      ) {
        this.setState({ deshabilitarSwitchPosnet: "none" });
      } else {
        this.setState({ deshabilitarSwitchPosnet: "auto" });
      }

      if (
        !datosPV.mediosPago.efectivo &&
        !datosPV.mediosPago.posnet &&
        datosPV.mediosPago.billeteraVirtual
      ) {
        this.setState({ deshabilitarSwitchBilleteraVirtual: "none" });
      } else {
        this.setState({ deshabilitarSwitchBilleteraVirtual: "auto" });
      }
    }
  }

  mostrarNombreCompletoDistribuidora() {
    const { mostrarNombreCompleto } = this.state;
    let mostrarNombreEstilos = {};

    if (mostrarNombreCompleto) {
      mostrarNombreEstilos = {
        overflow: "unset",
        whiteSpace: "normal",
        textOverflow: "initial",
      };
    } else {
      mostrarNombreEstilos = {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      };
    }
    return mostrarNombreEstilos;
  }

  setBorderColorApellido() {
    const { alertaApellido } = this.state;

    return alertaApellido ? "red" : "#ced4da";
  }

  setBorderColorNombre() {
    const { alertaNombre } = this.state;

    return alertaNombre ? "red" : "#ced4da";
  }

  render() {
    const {
      datosPV,
      modUbicacion,
      modHorario,
      modNombre,
      modTelefono,
      modGPS,
      usuario,
      mapModal,
      errorProv,
      errorDpto,
      errorLocal,
      errorCalle,
      errorAltura,
      redirect,
      email,
    } = this.state;
    return (
      <Consumer>
        {(perfil) => (
          <div
            id="perfil-seccion"
            style={{ overflowY: "scroll", maxHeight: "calc(100vh - 44px)" }}
          >
            <div
              className="container text-left"
              style={{ marginBottom: "25px" }}
            >
              {/*
                            <div className="position-relative text-center">

                                <img className="brand-logo" src={brandLogo} alt="brand-logo" onClick={() => {
                                    
                                    this.props.setStateNoEstaEnPerfil()
                                    this.props.hidePerfil()
                                    
                                }} ></img>
                            </div>
                                                        */}
              <div className="perfil-pic mx-auto text-center">
                {perfil.img ? (
                  <img className="pic" src={perfil.img} alt="Tu Perfil" />
                ) : (
                  <div className="pic">
                    <img className="pic" src={fotoPerfil} alt="Tu Perfil" />
                  </div>
                )}
              </div>
              {redirect ? (
                <Redirect
                  push
                  to={{
                    pathname: "/",
                  }}
                />
              ) : null}
              <div className="perfil-nya mb-2 d-flex justify-content-between align-items-center">
                {perfil.username}
              </div>

              <div className="perfil-nya d-flex justify-content-between align-items-center">
                {modNombre ? (
                  <div className="d-flex">
                    <input
                      type="text"
                      className="form-control f-12"
                      placeholder="Nombre"
                      maxLength="60"
                      defaultValue={usuario.nombre ? usuario.nombre : ""}
                      style={{
                        width: "47%",
                        marginRight: "10px",
                        textTransform: "uppercase",
                        borderColor: this.setBorderColorNombre(),
                      }}
                      onChange={(e) => {
                        this.setState({
                          usuarioAModificar: {
                            ...this.state.usuarioAModificar,
                            nombre: e.target.value.toUpperCase().trim(),
                          },
                          usuario: {
                            ...this.state.usuario,
                            nombre: e.target.value.toUpperCase().trim(),
                          },
                        });
                      }}
                    />
                    <input
                      type="text"
                      className="form-control f-12"
                      placeholder="Apellido"
                      maxLength="60"
                      defaultValue={usuario.apellido ? usuario.apellido : ""}
                      style={{
                        width: "47%",
                        marginRight: "10px",
                        textTransform: "uppercase",
                        borderColor: this.setBorderColorApellido(),
                      }}
                      onChange={(e) => {
                        this.setState({
                          usuarioAModificar: {
                            ...this.state.usuarioAModificar,
                            apellido: e.target.value.toUpperCase().trim(),
                          },
                          usuario: {
                            ...this.state.usuario,
                            apellido: e.target.value.toUpperCase().trim(),
                          },
                        });
                      }}
                    />
                  </div>
                ) : (
                  <div>
                    {usuario.nombre ? usuario.nombre : ""}{" "}
                    {usuario.apellido ? usuario.apellido : ""}
                  </div>
                )}
                {modNombre ? (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      ReactGA.event({
                        category: "Perfil",
                        action: "Editar Nombre y Apellido de Usuario",
                      });

                      if (
                        this.state.usuarioAModificar.nombre === "" ||
                        this.state.usuarioAModificar.apellido === ""
                      ) {
                        MySwal.fire({
                          icon: "error",
                          title:
                            "Los campos Nombre y/o Apellido no deben estar vacios",
                          showConfirmButton: true,
                          confirmButtonText: "Aceptar",
                          customClass: "swalIconCustom",
                        });

                        if (this.state.usuarioAModificar.apellido === "")
                          this.setState({ alertaApellido: true });
                        if (this.state.usuarioAModificar.nombre === "")
                          this.setState({ alertaNombre: true });
                      } else if (
                        this.state.usuarioAModificar &&
                        (this.state.usuarioAModificar.nombre ||
                          this.state.usuarioAModificar.apellido)
                      ) {
                        const { nombre, apellido } =
                          this.state.usuarioAModificar;

                        this.setState({
                          modNombre: !this.state.modNombre,
                          alertaApellido: false,
                          alertaNombre: false,
                        });

                        this.enviarUsuario({ nombre, apellido });
                      } else {
                        this.setState({ modNombre: !this.state.modNombre });
                      }
                    }}
                  >
                    <ReactSVG
                      className="seleccionar"
                      src={guardar}
                      style={{
                        margin: "3px",
                        width: "23px",
                        height: "23px",
                        color: "#224372",
                        background: "#C7E6F9",
                        borderRadius: "50%",
                      }}
                    />
                  </span>
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        modNombre: !this.state.modNombre,
                      });
                    }}
                  >
                    <ReactSVG
                      className="seleccionar"
                      src={seleccionar}
                      style={{
                        margin: "3px",
                        width: "23px",
                        height: "23px",
                        color: "#224372",
                        background: "#C7E6F9",
                        borderRadius: "50%",
                      }}
                    />
                  </span>
                )}
              </div>
              <div>{email ? email : ""}</div>
              <div
                className="mb-2  d-flex justify-content-between align-items-center"
                style={{}}
              >
                {modTelefono ? (
                  <div className="d-flex">
                    <ThemeInput
                      hintText="Código área y número. Ejemplo: 1151027777"
                      labelText="Teléfono"
                      autoComplete="tel"
                      value={usuario.telefono ? usuario.telefono : ""}
                      type="tel"
                      className="form-control f-13"
                      defaultValue={usuario.telefono ? usuario.telefono : ""}
                      onChange={(e) => {
                        this.setState({
                          usuarioAModificar: {
                            ...this.state.usuarioAModificar,
                            telefono: e.target.value.trim(),
                          },
                          usuario: {
                            ...this.state.usuario,
                            telefono: e.target.value.trim(),
                          },
                        });
                      }}
                      style={{
                        width: "100%",
                        marginBottom: "0px",
                        height: "32px",
                      }}
                      inputStyle={{ width: "100%", paddingLeft: "0px" }}
                    />
                    <span>
                      <i
                        data-toggle="modal"
                        className="info-modal-tel"
                        data-target="#modalInfoTelefono"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          let htmlString =
                            '<ul class="text-left" style="font-size: 15px; fontWeight: 500;">' +
                            "<li>Acepta sólo números y sin espacios.</li>" +
                            "<li>Debes escribir el código de área y seguido el número de teléfono.</li>" +
                            "<strong>Ejemplos:</strong>" +
                            "<ul>" +
                            "<li>1151027777</li>" +
                            "<li>1145183833</li>" +
                            "<li>3411118888</li>" +
                            "<li>2611234567</li>" +
                            "</ul>" +
                            "<li>No acepta el valor 15 en el código de área, en su lugar podes colocar 11.</li>" +
                            "</ul>";
                          Swal.fire({
                            title:
                              '<strong style="font-size: 22px;">Información Teléfono</strong>',
                            icon: "info",
                            html: htmlString,
                            focusConfirm: true,
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "Aceptar",
                            customClass: "swalIconCustom",
                          });
                        }}
                      >
                        <span className="tel">
                          <ReactSVG
                            className="seleccionar"
                            src={questionMark}
                            style={{
                              margin: "3px",
                              width: "23px",
                              height: "23px",
                              color: "#224372",
                              background: "#C7E6F9",
                              borderRadius: "50%",
                            }}
                          />
                        </span>
                      </i>
                    </span>
                  </div>
                ) : (
                  <div id="telefono-perfil">
                    {usuario.telefono ? usuario.telefono : ""}{" "}
                  </div>
                )}
                {modTelefono ? (
                  <div className="modificar-tel-info">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.setState({
                          modTelefono: !this.state.modTelefono,
                        });

                        ReactGA.event({
                          category: "Perfil",
                          action: "Editar Telefono de Usuario",
                        });

                        let tel = document.getElementById("teléfono").value;

                        if (
                          this.state.usuario &&
                          this.state.usuario.telefono &&
                          !this.controlarTelefonoInput(tel)
                        ) {
                          const { telefono } = this.state.usuario;
                          this.enviarUsuario({ telefono });
                        } else {
                          MySwal.fire({
                            icon: "error",
                            title:
                              "Debes ingresar un número telefónico válido que contenga el código de área y el número. (Ejemplo: 1151027777)",
                            showConfirmButton: true,
                            confirmButtonText: "Aceptar",
                            customClass: "swalIconCustom",
                          });
                          this.state.usuario.telefono = getTelefono();
                          this.setState({ usuario: this.state.usuario });
                        }
                      }}
                    >
                      <ReactSVG
                        className="seleccionar"
                        src={guardar}
                        style={{
                          margin: "3px",
                          width: "23px",
                          height: "23px",
                          color: "#224372",
                          background: "#C7E6F9",
                          borderRadius: "50%",
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      this.setState({
                        modTelefono: !this.state.modTelefono,
                      });
                    }}
                  >
                    <ReactSVG
                      className="seleccionar"
                      src={telefono}
                      style={{
                        margin: "3px",
                        width: "23px",
                        height: "23px",
                        color: "#224372",
                        background: "#C7E6F9",
                        borderRadius: "50%",
                      }}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="pvkiosco" style={{ paddingBottom: "100px" }}>
              <div
                className="d-flex justify-content-center container"
                style={{ marginBottom: "10px" }}
              >
                <div className="pvkiosco-title">
                  <ReactSVG src={flechaPerfil} className="flecha-perfil" />
                  Punto de venta
                </div>
              </div>
              <div className="info-perfil container fw-500">
                <div
                  className="d-flex text-danger gps-perfil"
                  style={{ marginBottom: "10px" }}
                >
                  <ReactSVG src={identificacion} className="gps-svg" />
                  Identificación
                </div>
                <div className="nombre-distribuidora container">
                  <div
                    style={this.mostrarNombreCompletoDistribuidora()}
                    onClick={() =>
                      this.setState({
                        mostrarNombreCompleto:
                          !this.state.mostrarNombreCompleto,
                      })
                    }
                  >
                    <span>
                      <strong>DISTRIBUIDORA: </strong>
                      {datosPV.informacionPuntoVenta
                        ? datosPV.informacionPuntoVenta.distribuidora
                        : null}
                    </span>
                  </div>
                  <div
                    style={{
                      display:
                        datosPV.informacionPuntoVenta &&
                        datosPV.informacionPuntoVenta.tipo === "SDDRA"
                          ? "inline"
                          : "none",
                    }}
                  >
                    <span>
                      <strong>LINEA: </strong>
                      {datosPV.informacionPuntoVenta
                        ? datosPV.informacionPuntoVenta.numeroLinea
                        : null}
                    </span>
                  </div>
                  <div>
                    <span>
                      <strong>VENDEDOR: </strong>
                      {datosPV.informacionPuntoVenta
                        ? datosPV.informacionPuntoVenta.vendedor
                        : null}
                      {/* {datosPV.informacionPuntoVenta ? datosPV.informacionPuntoVenta.tipo === "SDDRA" ? 
                                             datosPV.informacionPuntoVenta.vendedor : datosPV.informacionPuntoVenta.codigoVendedor : null} */}
                    </span>
                  </div>
                  <div>
                    <span>
                      <strong>PAQUETE: </strong>
                      {datosPV.informacionPuntoVenta
                        ? datosPV.informacionPuntoVenta.paquete
                        : null}
                    </span>
                  </div>
                </div>
              </div>
              <hr />
              <div style={{ paddingBottom: "10px" }}>
                <div className="ubicacion-perfil container fw-500">
                  <div
                    className="d-flex justify-content-between align-items-center pr-0"
                    style={{ marginBottom: "5px" }}
                  >
                    <div className="d-flex gps-perfil text-danger">
                      <ReactSVG src={gps} className="gps-svg" />
                      Ubicación
                    </div>
                    {modUbicacion ? (
                      <div>
                        <div
                          className="btn btn-enviar"
                          style={{ marginRight: "5px" }}
                          onClick={() => {
                            this.setState({
                              modUbicacion: !modUbicacion,
                              modGPS: false,
                              datosPV: JSON.parse(
                                JSON.stringify(this.state.datosPVbackup)
                              ),
                              localidadSelected:
                                this.state.localidadSelectedBackup,
                              provinciaSelected:
                                this.state.provinciaSelectedBackup,
                              departamentoSelected:
                                this.state.departamentoSelectedBackup,
                              currentProvincia:
                                this.state.provinciaSelectedBackup.label,
                              currentPartido:
                                this.state.departamentoSelectedBackup.label,
                              currentLocalidad:
                                this.state.localidadSelectedBackup.label,
                            });
                          }}
                        >
                          Cancelar
                        </div>
                        <div
                          className="btn btn-enviar"
                          onClick={async () => {
                            await this.validarDireccion();
                            const {
                              errorCalle,
                              errorAltura,
                              errorProv,
                              errorDpto,
                              errorLocal,
                              alturaInvalida,
                            } = this.state;
                            if (
                              errorCalle ||
                              errorAltura ||
                              errorProv ||
                              errorDpto ||
                              errorLocal
                            ) {
                              let mensaje = `Faltan datos por completar${
                                alturaInvalida
                                  ? ". La altura es inv&aacutelida."
                                  : "."
                              }`;

                              MySwal.fire({
                                icon: "error",
                                title: mensaje,
                                showConfirmButton: false,
                                timer: 2000,
                                customClass: "swalIconCustom",
                              });
                            } else {
                              this.setState({
                                modUbicacion: !this.state.modUbicacion,
                                modGPS: false,
                              });
                              const { datosPVbackup } = this.state;
                              if (
                                datosPV.domicilio.x !==
                                  (datosPVbackup.domicilio &&
                                    datosPVbackup.domicilio.x) ||
                                datosPV.domicilio.y !==
                                  (datosPVbackup.domicilio &&
                                    datosPVbackup.domicilio.y)
                              ) {
                                const data = [
                                  {
                                    Campo: "domicilio.X",
                                    Valor: parseFloat(datosPV.domicilio.x),
                                  },
                                  {
                                    Campo: "domicilio.Y",
                                    Valor: parseFloat(datosPV.domicilio.y),
                                  },
                                ];
                                await this.enviarModificaciones(data);
                              }

                              ReactGA.event({
                                category: "Perfil",
                                action: "Editar Ubicación de Punto de Venta",
                              });

                              await this.enviarModificaciones(
                                this.state.domicilioAModificar
                              );
                              //this.props.GuardarDatosPerfil();
                            }
                          }}
                        >
                          Guardar
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-enviar"
                        onClick={async () => {
                          await this.cargar();
                          this.setState({
                            modUbicacion: !this.state.modUbicacion,
                            modGPS: true,
                          });
                        }}
                      >
                        Editar
                      </div>
                    )}
                  </div>
                  {mapModal ? (
                    <Map
                      validarDireccion={(e) => this.validarDireccion(e)}
                      setGPS={async (arr) => {
                        if (this.state.modGPS) {
                          let { datosPV } = this.state;
                          datosPV = {
                            ...datosPV,
                            domicilio: {
                              ...datosPV.domicilio,
                              x: arr[0],
                              y: arr[1],
                            },
                          };
                          await this.setState({
                            datosPV,
                          });
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      modGPS={modGPS}
                      obtenerUbicacion={async (data) => {
                        if (modGPS && data) {
                          const { datosPV } = this.state;
                          if (datosPV.domicilio.calle !== data.road) {
                            let campo = "domicilio.Calle";
                            let modificado =
                              data.road &&
                              data.road !==
                                (this.state.datosPVbackup.domicilio &&
                                  this.state.datosPVbackup.domicilio.calle)
                                ? {
                                    Campo: campo,
                                    Valor: data.road.toUpperCase().trim(),
                                  }
                                : null;
                            let { domicilioAModificar } = this.state;
                            if (modificado) {
                              if (
                                domicilioAModificar.filter(
                                  (e) => e.Campo === modificado.Campo
                                ).length !== 0
                              ) {
                                domicilioAModificar[
                                  domicilioAModificar.findIndex(
                                    (e) => e.Campo === modificado.Campo
                                  )
                                ].Valor = modificado.Valor;
                              } else {
                                domicilioAModificar = [
                                  ...domicilioAModificar,
                                  modificado,
                                ];
                              }
                              this.setState({
                                domicilioAModificar,
                              });
                            } else {
                              if (
                                domicilioAModificar.filter(
                                  (e) => e.Campo === campo
                                ).length !== 0
                              ) {
                                domicilioAModificar =
                                  domicilioAModificar.filter(
                                    (e) => e.Campo !== campo
                                  );
                              }
                              this.setState({
                                domicilioAModificar,
                              });
                            }
                          }
                          if (datosPV.domicilio.numero !== data.house_number) {
                            let campo = "domicilio.Numero";
                            let modificado =
                              data.house_number &&
                              data.house_number !==
                                (this.state.datosPVbackup.domicilio &&
                                  this.state.datosPVbackup.domicilio.numero)
                                ? {
                                    Campo: campo,
                                    Valor: data.house_number,
                                  }
                                : null;
                            let { domicilioAModificar } = this.state;
                            if (modificado) {
                              if (
                                domicilioAModificar.filter(
                                  (e) => e.Campo === modificado.Campo
                                ).length !== 0
                              ) {
                                domicilioAModificar[
                                  domicilioAModificar.findIndex(
                                    (e) => e.Campo === modificado.Campo
                                  )
                                ].Valor = modificado.Valor;
                              } else {
                                domicilioAModificar = [
                                  ...domicilioAModificar,
                                  modificado,
                                ];
                              }
                              this.setState({
                                domicilioAModificar,
                              });
                            } else {
                              if (
                                domicilioAModificar.filter(
                                  (e) => e.Campo === campo
                                ).length !== 0
                              ) {
                                domicilioAModificar =
                                  domicilioAModificar.filter(
                                    (e) => e.Campo !== campo
                                  );
                              }
                              this.setState({
                                domicilioAModificar,
                              });
                            }
                          }
                          datosPV.domicilio.calle = data.road
                            ? data.road
                            : null;
                          datosPV.domicilio.numero = data.house_number
                            ? data.house_number
                            : null;
                          const calle = document.getElementById("calle");
                          const altura = document.getElementById("altura");
                          calle.value = data.road ? data.road : null;
                          altura.value = data.house_number
                            ? data.house_number
                            : null;
                          const {
                            listaProvincias,
                            listaDepartamentos,
                            listaLocalidades,
                          } = this.state;
                          const provinciaSelected =
                            listaProvincias.filter(
                              (e) =>
                                e.label.toLowerCase() ===
                                (data.state_district.indexOf("omuna") === -1 &&
                                (data.state_district.indexOf("ciudad") !== -1 &&
                                data.state_district.indexOf("buenos aires") !==
                                  -1
                                  ? false
                                  : true)
                                  ? data.city.toLowerCase()
                                  : "capital federal"
                                ).toLowerCase()
                            )[0] ||
                            listaProvincias.filter(
                              (e) => e.value === null
                            )[0] ||
                            listaProvincias.filter(
                              (e) => e.value === "null"
                            )[0];
                          let departamentoSelected;
                          if (
                            provinciaSelected !== this.state.provinciaSelected
                          ) {
                            await this.handleProvinciaChange(provinciaSelected);
                          }
                          await this.listarDepartamentos(
                            data.state_district.indexOf("omuna") === -1 &&
                              (data.state_district.indexOf("ciudad") !== -1 &&
                              data.state_district.indexOf("buenos aires") !== -1
                                ? false
                                : true)
                              ? data.state_district.toLowerCase()
                              : "capital federal"
                          );
                          departamentoSelected =
                            this.state.listaDepartamentos.filter(
                              (e) =>
                                e.label.toLowerCase() ===
                                (data.state_district.indexOf("omuna") === -1 &&
                                (data.state_district.indexOf("ciudad") !== -1 &&
                                data.state_district.indexOf("buenos aires") !==
                                  -1
                                  ? false
                                  : true)
                                  ? data.state_district.toLowerCase()
                                  : "capital federal"
                                ).toLowerCase()
                            )[0] ||
                            listaDepartamentos.filter(
                              (e) => e.value === null
                            )[0] ||
                            listaDepartamentos.filter(
                              (e) => e.value === "null"
                            )[0];
                          await this.handleDepartamentoChange(
                            departamentoSelected
                          );
                          await this.listarLocalidades(
                            (data.municipality &&
                              data.municipality.toLowerCase()) ||
                              (data.subutb && data.suburb.toLowerCase())
                          );
                          const localidadSelected =
                            this.state.listaLocalidades.filter(
                              (e) =>
                                e.label.toLowerCase() ===
                                  (data.municipality || "").toLowerCase() ||
                                e.label.toLowerCase() ===
                                  (data.neighbourhood || "").toLowerCase() ||
                                e.label.toLowerCase() ===
                                  (data.suburb || "").toLowerCase()
                            )[0] ||
                            listaLocalidades.filter((e) => e.value === null)[0];
                          await this.handleLocalidadChange(localidadSelected);
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      x={
                        this.state.datosPV.domicilio
                          ? this.state.datosPV.domicilio.x
                          : -35
                      }
                      y={
                        this.state.datosPV.domicilio
                          ? this.state.datosPV.domicilio.y
                          : -65
                      }
                    />
                  ) : null}
                  {modUbicacion ? null : (
                    <div
                      className="mt-3"
                      onClick={() =>
                        this.setState({
                          mostrarDirecCompleta:
                            !this.state.mostrarDirecCompleta,
                        })
                      }
                    >
                      <div
                        className="mt-3 f-13 fw-500 direc-corta"
                        style={{
                          display: this.state.mostrarDirecCompleta
                            ? "none"
                            : "inline",
                          marginTop: "5px",
                        }}
                      >
                        {datosPV.domicilio
                          ? datosPV.domicilio.calle +
                            " " +
                            `${
                              datosPV.domicilio.numero
                                ? datosPV.domicilio.numero
                                : ""
                            }` +
                            " - " +
                            this.state.currentLocalidad +
                            " ... "
                          : null}
                      </div>
                      <div
                        className="mt-3 f-13 fw-500 direc-completa"
                        style={{
                          display: this.state.mostrarDirecCompleta
                            ? "inline"
                            : "none",
                          marginTop: "5px",
                        }}
                      >
                        {datosPV.domicilio
                          ? datosPV.domicilio.calle +
                            " " +
                            `${
                              datosPV.domicilio.numero
                                ? datosPV.domicilio.numero
                                : ""
                            }` +
                            " - " +
                            this.state.currentLocalidad +
                            " - " +
                            this.state.currentPartido +
                            " - " +
                            this.state.currentProvincia
                          : null}
                      </div>
                    </div>
                  )}
                  {modUbicacion ? (
                    <React.Fragment>
                      <div
                        className="d-flex mt-3"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="col-8 pl-0">
                          <span style={errorCalle ? { color: "red" } : {}}>
                            Calle
                          </span>
                          <input
                            id="calle"
                            type="text"
                            className="form-control"
                            placeholder="Calle"
                            maxLength="150"
                            defaultValue={
                              datosPV.domicilio && datosPV.domicilio.calle
                            }
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            style={{
                              marginRight: "10px",
                              textTransform: "uppercase",
                            }}
                            onChange={(e) => {
                              let campo = "domicilio.Calle";
                              let modificado =
                                e.target.value.trim() &&
                                e.target.value.trim() !==
                                  (this.state.datosPVbackup.domicilio &&
                                    this.state.datosPVbackup.domicilio.calle)
                                  ? {
                                      Campo: campo,
                                      Valor: e.target.value
                                        .toUpperCase()
                                        .trim(),
                                    }
                                  : null;
                              let { domicilioAModificar } = this.state;
                              if (modificado) {
                                if (
                                  domicilioAModificar.filter(
                                    (e) => e.Campo === modificado.Campo
                                  ).length !== 0
                                ) {
                                  domicilioAModificar[
                                    domicilioAModificar.findIndex(
                                      (e) => e.Campo === modificado.Campo
                                    )
                                  ].Valor = modificado.Valor;
                                } else {
                                  domicilioAModificar = [
                                    ...domicilioAModificar,
                                    modificado,
                                  ];
                                }
                                this.setState({
                                  domicilioAModificar,
                                });
                              } else {
                                if (
                                  domicilioAModificar.filter(
                                    (e) => e.Campo === campo
                                  ).length !== 0
                                ) {
                                  domicilioAModificar =
                                    domicilioAModificar.filter(
                                      (e) => e.Campo !== campo
                                    );
                                }
                                this.setState({
                                  domicilioAModificar,
                                });
                              }
                              let { datosPV } = this.state;
                              datosPV = {
                                ...datosPV,
                                domicilio: {
                                  ...datosPV.domicilio,
                                  calle: e.target.value.toUpperCase().trim(),
                                },
                              };
                              this.setState({
                                datosPV,
                              });
                            }}
                          ></input>
                        </div>
                        <div className="col-4 pl-0">
                          <span style={errorAltura ? { color: "red" } : {}}>
                            Altura
                          </span>
                          <input
                            id="altura"
                            type="text"
                            className="form-control"
                            placeholder="Altura"
                            maxLength="10"
                            defaultValue={
                              datosPV.domicilio && datosPV.domicilio.numero
                            }
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            style={{
                              marginRight: "5px",
                              textTransform: "uppercase",
                            }}
                            onChange={(e) => {
                              let campo = "domicilio.Numero";
                              let modificado =
                                e.target.value.trim() !==
                                (this.state.datosPVbackup.domicilio &&
                                  this.state.datosPVbackup.domicilio.numero)
                                  ? {
                                      Campo: campo,
                                      Valor:
                                        e.target.value === ""
                                          ? null
                                          : e.target.value,
                                    }
                                  : null;
                              let { domicilioAModificar } = this.state;
                              if (modificado) {
                                if (
                                  domicilioAModificar.filter(
                                    (e) => e.Campo === modificado.Campo
                                  ).length !== 0
                                ) {
                                  domicilioAModificar[
                                    domicilioAModificar.findIndex(
                                      (e) => e.Campo === modificado.Campo
                                    )
                                  ].Valor = modificado.Valor;
                                } else {
                                  domicilioAModificar = [
                                    ...domicilioAModificar,
                                    modificado,
                                  ];
                                }
                                this.setState({
                                  domicilioAModificar,
                                });
                              } else {
                                if (
                                  domicilioAModificar.filter(
                                    (e) => e.Campo === campo
                                  ).length !== 0
                                ) {
                                  domicilioAModificar =
                                    domicilioAModificar.filter(
                                      (e) => e.Campo !== campo
                                    );
                                }
                                this.setState({
                                  domicilioAModificar,
                                });
                              }
                              let { datosPV } = this.state;
                              datosPV = {
                                ...datosPV,
                                domicilio: {
                                  ...datosPV.domicilio,
                                  numero: e.target.value.toUpperCase().trim(),
                                },
                              };
                              this.setState({
                                datosPV,
                              });
                            }}
                          ></input>
                        </div>
                      </div>
                      <div className="d-flex" style={{ marginBottom: "14px" }}>
                        <div className="col-6 pl-0">
                          <span style={errorProv ? { color: "red" } : {}}>
                            Provincia
                          </span>
                          <Select
                            value={this.state.provinciaSelected}
                            placeholder={"Provincia"}
                            onChange={this.handleProvinciaChange}
                            options={this.state.listaProvincias}
                            className={errorProv ? "alert-red" : ""}
                          />
                        </div>
                        <div className="col-6 pl-0">
                          <span style={errorDpto ? { color: "red" } : {}}>
                            Partido
                          </span>
                          <AsyncSelect
                            key={
                              this.state.provinciaSelected &&
                              this.state.provinciaSelected.value
                                ? this.state.provinciaSelected.value
                                : 0
                            }
                            cacheOptions
                            value={this.state.departamentoSelected}
                            loadOptions={this.listarDepartamentos}
                            placeholder={"Partido"}
                            defaultOptions
                            isDisabled={
                              this.state.provinciaSelected &&
                              this.state.provinciaSelected.value !== null &&
                              this.state.provinciaSelected.value !== "null"
                                ? false
                                : true
                            }
                            onChange={this.handleDepartamentoChange}
                            className={errorDpto ? "alert-red" : ""}
                          />
                        </div>
                      </div>
                      <div className="d-flex" style={{ marginBottom: "14px" }}>
                        <div className="col-6 pl-0">
                          <span style={errorLocal ? { color: "red" } : {}}>
                            Localidad
                          </span>
                          <AsyncSelect
                            key={
                              this.state.departamentoSelected &&
                              this.state.departamentoSelected.value
                                ? this.state.departamentoSelected.value
                                : 0
                            }
                            cacheOptions
                            loadOptions={this.listarLocalidades}
                            value={this.state.localidadSelected}
                            placeholder={"Localidad"}
                            defaultOptions
                            isDisabled={
                              this.state.departamentoSelected &&
                              this.state.departamentoSelected.value !== null &&
                              this.state.departamentoSelected.value !== "null"
                                ? false
                                : true
                            }
                            onChange={this.handleLocalidadChange}
                            className={errorLocal ? "alert-red" : ""}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
                <hr />
                <div className="horarios-perfil container fw-500">
                  <div
                    className="d-flex justify-content-between align-items-center pr-0"
                    style={{ marginBottom: "10px" }}
                  >
                    <div className="d-flex text-danger gps-perfil">
                      <ReactSVG src={horario} className="gps-svg" />
                      Horario de Atención
                    </div>
                    {modHorario ? (
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          className="btn btn-enviar"
                          style={{ marginRight: "10px" }}
                          onClick={async () => {
                            var backup = {};
                            const data = {
                              puntoVentaId: JSON.parse(localStorage.infoToken)
                                .entidad_id,
                            };
                            const headers = {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                              Authorization: "Bearer " + localStorage.token,
                            };
                            const respuesta = await fetch(
                              urlServer + "/api/puntoVenta/buscar",
                              {
                                method: "POST",
                                redirect: "manual",
                                body: JSON.stringify(data),
                                headers,
                              }
                            )
                              .then((response) => response.json())
                              .catch((error) => {
                                this.validarVencimientoToken(error.message);
                                console.log("error", error);
                              })
                              .then(async (result) => {
                                backup = result;
                              })
                              .catch((error) => {
                                this.validarVencimientoToken(error.message);
                                console.log("error", error);
                              });

                            this.setState({
                              modHorario: !modHorario,
                              datosPV: backup,
                              typechecked: this.state.typecheckBackup,
                            });
                            this.agregarDias();
                          }}
                        >
                          Cancelar
                        </div>
                        <div
                          className="btn btn-enviar"
                          onClick={() => {
                            this.setState({
                              modHorario: !this.state.modHorario,
                              datosPVbackup: JSON.parse(
                                JSON.stringify(this.state.datosPV)
                              ),
                            });

                            ReactGA.event({
                              category: "Perfil",
                              action: "Editar Horarios de Punto de Venta",
                            });

                            this.enviarModificaciones(
                              this.state.horarioAModificar
                            );
                          }}
                        >
                          Guardar
                        </div>
                      </div>
                    ) : (
                      <div
                        className="btn btn-enviar"
                        onClick={() => {
                          this.setState({
                            modHorario: !this.state.modHorario,
                            typecheckBackup: this.state.typechecked,
                          });
                        }}
                      >
                        Editar
                      </div>
                    )}
                  </div>
                  {modHorario ? (
                    <React.Fragment>
                      <div className="d-flex">
                        <label
                          className="container3"
                          style={{ marginRight: "20px" }}
                        >
                          Día a día
                          <input
                            name={"typecheck"}
                            type="checkbox"
                            checked={
                              this.state.typechecked === "dia" ? true : false
                            }
                            value="dia"
                            onChange={(e) => {
                              let a = document.getElementsByName("typecheck");
                              for (let i = 0; i <= a.length; i++) {
                                if (a[i]) {
                                  a[i].checked = false;
                                }
                              }
                              e.target.checked = true;
                              this.setState({
                                typechecked: "dia",
                              });
                            }}
                          />
                          <span className="checkmark3"></span>
                        </label>
                        <label className="container3">
                          Repetir horario lunes a viernes
                          <input
                            name={"typecheck"}
                            type="checkbox"
                            checked={
                              this.state.typechecked === "lav" ? true : false
                            }
                            value="lav"
                            onChange={(e) => {
                              let a = document.getElementsByName("typecheck");
                              for (let i = 0; i <= a.length; i++) {
                                if (a[i]) {
                                  a[i].checked = false;
                                }
                              }
                              e.target.checked = true;
                              this.setState({
                                typechecked: "lav",
                              });
                            }}
                          />
                          <span className="checkmark3"></span>
                        </label>
                      </div>
                      <div
                        style={{
                          display: "grid",
                          gap: "10px",
                          gridTemplateColumns:
                            "repeat(auto-fill, minmax(90px, 1fr))",
                          justifyItems: "center",
                        }}
                      >
                        {this.renderInputs()}
                      </div>
                    </React.Fragment>
                  ) : (
                    <div
                      style={{
                        display: "grid",
                        gap: "10px",
                        gridTemplateColumns:
                          "repeat(auto-fill, minmax(90px, 1fr))",
                        justifyItems: "center",
                      }}
                    >
                      {this.renderHorario()}
                    </div>
                  )}
                </div>
                <hr />
                <div className="info-perfil container fw-500">
                  <div
                    className="d-flex text-danger gps-perfil"
                    style={{ marginBottom: "10px" }}
                  >
                    <ReactSVG src={repartologo} className="gps-svg" />
                    Información de Reparto
                  </div>
                  <div className="booleanos-perfil pt-3 container f-13 fw-500">
                    {this.separarPalabras(datosPV.reparto)}
                  </div>
                </div>
                <hr />
                <div className="info-perfil container fw-500">
                  <div
                    className="d-flex text-danger gps-perfil"
                    style={{ marginBottom: "10px" }}
                  >
                    <ReactSVG src={metodopagologo} className="gps-svg" />
                    Medios de Pago (Selecciona los que usas)
                  </div>
                  <div className="d-flex flex-column booleanos-perfil booleanos-medio-pago p-3 container">
                    <div className="slot efectivo">
                      <div className="f-13 fw-500 mp-name">EFECTIVO</div>
                      <div
                        className="medio-pago"
                        onClick={() => {
                          if (
                            this.state.deshabilitarSwitchEfectivo === "none"
                          ) {
                            MySwal.fire({
                              icon: "error",
                              title: "¡Debes tener al menos un medio de pago!",
                              showConfirmButton: false,
                              timer: 2000,
                              customClass: "swalIconCustom",
                            });
                          }
                        }}
                      >
                        <label
                          className="switch mp-switch"
                          style={{
                            pointerEvents:
                              this.state.deshabilitarSwitchEfectivo,
                          }}
                        >
                          <input
                            type="checkbox"
                            id="Efectivo"
                            defaultChecked={
                              datosPV.mediosPago
                                ? datosPV.mediosPago.efectivo
                                : null
                            }
                            onClick={async (e) => {
                              const { datosPV } = this.state;
                              const modificado = {
                                Campo: "mediosPago." + e.target.id,
                                Valor: e.target.checked.toString(),
                              };
                              datosPV.mediosPago.efectivo =
                                !datosPV.mediosPago.efectivo;
                              await this.enviarModificaciones([modificado]);
                              ////this.props.GuardarDatosPerfil();
                              this.deshabilitarSwitch();
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <hr />
                    <div className="slot posnet">
                      <div className="f-13 fw-500 mp-name">POSNET</div>
                      <div
                        className="medio-pago"
                        onClick={() => {
                          if (this.state.deshabilitarSwitchPosnet === "none") {
                            MySwal.fire({
                              icon: "error",
                              title: "¡Debes tener al menos un medio de pago!",
                              showConfirmButton: false,
                              timer: 2000,
                              customClass: "swalIconCustom",
                            });
                          }
                        }}
                      >
                        <label
                          className="switch mp-switch"
                          style={{
                            pointerEvents: this.state.deshabilitarSwitchPosnet,
                          }}
                        >
                          <input
                            type="checkbox"
                            id="Posnet"
                            defaultChecked={
                              datosPV.mediosPago
                                ? datosPV.mediosPago.posnet
                                : null
                            }
                            onClick={async (e) => {
                              const modificado = {
                                Campo: "mediosPago." + e.target.id,
                                Valor: e.target.checked.toString(),
                              };
                              datosPV.mediosPago.posnet =
                                !datosPV.mediosPago.posnet;
                              await this.enviarModificaciones([modificado]);
                              ////this.props.GuardarDatosPerfil();
                              this.deshabilitarSwitch();
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <hr />
                    <div className="slot billetera-virtual">
                      <div className="f-13 fw-500 mp-name">
                        BILLETERA VIRTUAL
                      </div>
                      <div
                        className="medio-pago"
                        onClick={() => {
                          if (
                            this.state.deshabilitarSwitchBilleteraVirtual ===
                            "none"
                          ) {
                            MySwal.fire({
                              icon: "error",
                              title: "¡Debes tener al menos un medio de pago!",
                              showConfirmButton: false,
                              timer: 2000,
                              customClass: "swalIconCustom",
                            });
                          }
                        }}
                      >
                        <label
                          className="switch mp-switch"
                          style={{
                            pointerEvents:
                              this.state.deshabilitarSwitchBilleteraVirtual,
                          }}
                        >
                          <input
                            type="checkbox"
                            id="BilleteraVirtual"
                            defaultChecked={
                              datosPV.mediosPago
                                ? datosPV.mediosPago.billeteraVirtual
                                : null
                            }
                            onClick={async (e) => {
                              const modificado = {
                                Campo: "mediosPago." + e.target.id,
                                Valor: e.target.checked.toString(),
                              };
                              datosPV.mediosPago.billeteraVirtual =
                                !datosPV.mediosPago.billeteraVirtual;
                              await this.enviarModificaciones([modificado]);
                              //this.props.GuardarDatosPerfil();
                              this.deshabilitarSwitch();
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ArrowUpPerfil />
          </div>
        )}
      </Consumer>
    );
  }
}
