import * as React from "react";
import ReactGA from 'react-ga';
import './modalproductonoeditorial.css';
import addDefaultSrc from '../assets/ic_cc.svg';
import urlServer from '../server'
import './NotificacionPopUp.css'

const NotificacionPopUp = ({notificacion, setVerNotif, history, traerNotificacionesAlLeer, updateNumeritoCampanaAlLeerNotif}) => {

    const validarVencimientoToken = (mensaje) => {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
                return;
            }
        }
        return
    }
        
    const renderImagen = () => {
        if (notificacion.tieneImagen) {
            return (
                <div className="image-div">
                <img alt=""
                    onError={(e) => e.target.src = addDefaultSrc}
                    onClick={() => {
                        if(notificacion.link) {
                            let url = notificacion.url
                            let a = window.open(url, '_blank'); 
                            a.focus();
                        }
                    }}
                    src={notificacion.urlImagen}
                    style={{height: '200px', width: '265px'}}
                >
                </img>
            </div>
            )
        }
    }

    const marcarNotifLeida = async (notifIndex) => {

        // step 1: google analytics
        ReactGA.event({
            category: 'Notificacion',
            action: 'Leer notificacion'
        });

        // step 2: armar url
        let apiUrl = urlServer + `/api/notificacion/leer/${notifIndex}`;

        // step 3: armar auth
        let auth = 'Bearer ' + localStorage.token

        // step 4: definir headers
        let myHeaders = {
            // Accept: "application/json",
            // 'Content-Type': 'application/json',
            Authorization: auth
        };

        // step 5: realizar la request
        await fetch(apiUrl, {
            method: 'POST',
            headers: myHeaders
        })
        .then((response) => {
            return response.json()
        })
        .then((response) => {
            if (response.exito) {
                obtenerNotificaciones();
            }
        })
        .catch(error => {
            validarVencimientoToken(error.message);
            console.log(error)
        });

    }

    const obtenerNotificaciones = () => {
        let data = {
            puntoVentaId: null,
            notificacionId: null
        }
        fetch(urlServer + '/api/notificacion/buscar', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + localStorage.token
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }
            else {
                throw response;
            }
        }).then(async (result) => {
            traerNotificacionesAlLeer(result)
            let cantidadNotif = await obtenerCantidadNotificacionesNoLeidas(true)
            updateNumeritoCampanaAlLeerNotif(cantidadNotif)
            
        }).catch((error) => {
            validarVencimientoToken(error.message);
            console.log(error);
        })
    }

    const obtenerCantidadNotificacionesNoLeidas = async (fromUpdate = false) => {
        let data = {
            puntoVentaId: null,
            notificacionId: null
        }
        const response = await fetch(urlServer + '/api/notificacion/cantidadObtener', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'bearer ' + localStorage.token
            }
        }).then((response) => {
            if (response.status === 200) {
                return response.json();
            }

            throw response;

        }).then((result) => {
            if(!fromUpdate){
                this.setState({ notificacionesNoLeidasCantidad: result })
                return
            }
            
            return result
        }).catch((error) => {
            validarVencimientoToken(error.message);
            console.log(error);
        })

        return response
    }

    return (
    
        <div className="modal-noedit" id="productoNoEditorial">
            <div className="modal-content-noedit" >
                <div className="modal-header d-flex">
                    <h6 className="modal-title">
                        {notificacion.titulo}
                    </h6>
                </div>
                <div className="modal-body-notificacion" style={{textAlign: 'left', cursor: notificacion.link ? 'pointer' : 'default'}}
                 onClick={() => {
                    if(notificacion.link) {
                        let url = notificacion.url
                        let a = window.open(url, '_blank'); 
                        a.focus();
                    }
                }}
                >
                    <div>
                        {renderImagen()}
                    </div>
                    <div className="text-justify pt-3">
                        {notificacion.mensaje}
                    </div>
                    
                </div>
                <div className="modal-footer" style={{justifyContent: 'center'}}>
                    <div className='confirmar-boton-datos rounded' 
                                style={{
                                    display: 'grid',
                                    alignContent: 'center',
                                    placeContent: 'center',                                                   
                                    color: 'white',
                                    fontWeight: '500',
                                    fontSize: '1.20em',                                                
                                    width: '45%',
                                    height: '42px',
                                    bottom: '40px',
                                    backgroundColor: '#3085d6', 
                                    lineHeight: 1
                                }}
                                onClick={() => {
                                    
                                    setVerNotif(false);
                                    if(!notificacion.leida) {
                                        marcarNotifLeida(notificacion.notificacionId)
                                    }
                                }}>
                                Aceptar
                    </div>
                </div>
                
                
            </div>
        </div>
    )
}

export default NotificacionPopUp
