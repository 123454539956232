import React, { Component } from "react";
import Spinner from "./spinner";
import "./login.css";
import urlServer from "../server";
import { withRouter } from "react-router-dom";
import AppContext from "../context/AppContext";

class Precio extends Component {
    state = {
        preciotapa: "",
        recargointerior: "",
        precioTapaEntera: "",
        precioTapaDecimal:"",
        loading: true,
        alerts: [],
    };

    static contextType = AppContext;

    async componentDidUpdate() {
        
        this.ayudaBorrar();
        this.cambiarIcono();
    }

    async componentDidMount() {
        
        document.title = "Consultar Precio";
      
        try {
            let myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Content-Type", "application/json");
            const apiBaseUrl = urlServer + `/api/tienda/ObtenerPrecioQR?${this.props.match.params.sku}`;
            //la api no requiere permiso
            const response = await fetch(apiBaseUrl, {
                method: "GET",
                headers: myHeaders,
            });

            const result = await response.text();

            const res = JSON.parse(result);

            if (response.status === 200) {

                var arrayPrecio = res.preciotapa.split("."); 
                var precioEntero = arrayPrecio[0];
                var precioDecimal = arrayPrecio[1];
                this.setState({
                    preciotapa: res.preciotapa.replace('.', ','),
                    precioTapaEntera: precioEntero,
                    precioTapaDecimal: precioDecimal,
                    recargointerior: res.recargointerior.replace('.', ','),
                    loading: false,
                });

                window.history.replaceState(null, '', `${res.matnr}`);
             
            } else {
                this.setState(
                    {
                        loading: false,
                    },
                    () => {
                        this.setResultado({
                            encabezado: "Error",
                            mensaje: "El enlace ha expirado o es Inválido",
                        });
                    }
                );
            }
        } catch (error) {
            this.setState(
                {
                    loading: false,
                },
                () => {
                    this.setResultado({
                        encabezado: "Error",
                        mensaje: "Se ha producido un error, por favor, ingrese nuevamente",
                    });
                }
            );
        }
    }

    setResultado = ({ encabezado, mensaje }) => {
        document.getElementById(
            "precio-form"
        ).innerHTML = `<div><h4 class="mb-5">¡${encabezado}!</h4><div class="f-13-5">${mensaje}</div></div>`;
    };

    ayudaBorrar = () => {
        document.getElementById(
            "helpFreshdeskBottom"
        ).style.display="none";
    };

    volverPaginaAnterior = () => {
      /*  window.history.go(-1);*/ 
        window.location.replace("http://www.google.com")
    };

    cambiarIcono = () => {
        var newFavicon = document.createElement('link');
        newFavicon.rel = 'icon';
        newFavicon.href = '../codigo-qr.ico'; // Reemplaza con la URL del nuevo icono
        newFavicon.type = 'image/x-icon';

        var currentFavicon = document.querySelector('link[rel="icon"]');

        if (currentFavicon) {
            document.head.removeChild(currentFavicon);
        }
        document.head.appendChild(newFavicon);
    }

    render() {
        const { loading, precioTapaEntera, precioTapaDecimal, recargointerior} = this.state;
    
        return (
          <React.Fragment>
                <div id="precioSAPConsultar" className="h-100vh w-100">
                    {loading ? (
                        <Spinner />
                    ) : (
                <div id="precio-form">
                    <div class="circuloPrecioSAP">
                                    <div class="precioSAPTapa">
                                        <div class="precioSAPTapaSigno">$</div>
                                        <div class="precioSAPTapaEntera">{precioTapaEntera},</div>
                                        <div class="precioSAPTapaDecimal">{precioTapaDecimal}</div>
                                    </div>
                        <div class="precioSAPRecargo">RECARGO DE ENVÍO AL</div>
                        <div class="precioSAPRecargo" >INTERIOR <b>${recargointerior}</b></div>
                        <div class="precioSAPBotton">PRECIO EXCLUSIVO PARA CANAL KIOSCOS</div>
                    </div>
                    <div>
                        <div class="d-flex justify-content-center align-items-center ">
                            <div class="d-flex justify-content-center align-items-center barraCerrarPrecioSAP"
                                onClick={this.volverPaginaAnterior}>
                                Cerrar
                            </div>
                        </div>
                    </div>
                               
                </div>
              )}
            </div>
          </React.Fragment>
        );
    }
}

export default withRouter(Precio);
