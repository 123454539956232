import * as React from "react";
import { Title } from "../components/title";
import { ReactSVG } from "react-svg";
import Spinner from "../components/spinner";
import urlServer from "../server";
import restar from "../assets/restar.svg";
import sumar from "../assets/sumar.svg";
import eraser from '../assets/eraser.svg';
import AsyncSelect from "react-select/async";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactGA from 'react-ga';
import server from "../server";

const MySwal = withReactContent(Swal);

export default class Devoluciones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            plantillaSelected: {},
            plantillas: [],
            plantillaEdiciones: [],
            timerId: [],
            cargaSuperada: [],
            accordion: false,
            filtroPlantillaDevolucion: {
                "fechaDesde": new Date((new Date()).setDate((new Date()).getDate() - 7)).toISOString().slice(0,10),
                "fechaHasta": new Date((new Date()).setDate((new Date()).getDate() + 7)).toISOString().slice(0,10)
              },
            plantillaId: null
        };
    }

    validarVencimientoToken(mensaje) {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
            }
        }
        return
    }

    limpiarPlantillaSeleccionada = () => {
        this.setState({plantillaSelected: null});
        this.setState({plantillaEdiciones: []});
    }

    reqPlantillas = async (string) => {
        const palabras = string ? string : "";
        const fechaDesde = this.state.filtroPlantillaDevolucion.fechaDesde.slice(0,10);
        const fechaHasta = this.state.filtroPlantillaDevolucion.fechaHasta.slice(0,10);
        const pageSize = 100;
        const columnaAOrdenar = 'FECHA ASC';
        
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.token,
        };
        const data = {
            palabrasABuscar: palabras,
            fechaVigenciaDesde: fechaDesde,
            fechaVigenciaHasta: fechaHasta,
            pageSize: pageSize,
            columnaAOrdenar: columnaAOrdenar,
        };
        const respuesta = await fetch(
            urlServer +
            "/api/plantilla/devolucion/" +
            JSON.parse(localStorage.infoToken).entidad_id,
            {
                method: "POST",
                redirect: "manual",
                body: JSON.stringify(data),
                headers,
            }
        )
            .then((response) => response.text())
            .catch((error) => {
                this.validarVencimientoToken(error.message);
                console.log("error", error);
            })
            .then(result => {

                const res = JSON.parse(result);

                this.validarVencimientoToken(res.message);

                if (res.map) {
                    let options = res.map((pub) => {
                        return {
                            value: pub.plantillaId,
                            label:
                                (pub.yaCerrada || pub.yaCargada)
                                    ? pub.descripcion + " "
                                    : pub.descripcion,
                        };
                    });
                    options = [...options];
                    this.setState({
                        plantillas: JSON.parse(result),
                        options,
                    });
                    return options;
                }
            }
            )
            .catch((error) => {
                console.log("error", error);
                this.validarVencimientoToken(error.message);
            });
        return respuesta;
    };

    clearFilter = () => {
        this.setState({
            filtroPlantillaDevolucion: {
                fechaDesde: new Date((new Date()).setDate((new Date()).getDate() - 7)).toISOString().slice(0,10),
                fechaHasta: new Date((new Date()).setDate((new Date()).getDate() + 7)).toISOString().slice(0,10)
            }
        })
    }

    reqEdiciones = async (plantillaId) => {

        ReactGA.event({
            category: 'Devoluciones',
            action: 'Listar Ediciones'
        });
        this.setState({
            loading: true,
            plantillaId: plantillaId
        });
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.token,
        };
        const respuesta = await fetch(
            urlServer +
            "/api/distribucion/devolucionRapida/plantilla/" +
            plantillaId +
            "/pv/" +
            JSON.parse(localStorage.infoToken).entidad_id,
            {
                method: "POST",
                redirect: "manual",
                body: JSON.stringify(),
                headers,
            }
        )
            .then((response) => response.text())
            .catch((error) => {
                this.validarVencimientoToken(error.message);
                console.log("error", error);
                this.setState({
                    loading: false,
                });
            })
            .then((result) => {

                let res = JSON.parse(result);
                this.validarVencimientoToken(res.message);

                res.ediciones = res.ediciones.map((edicion) => {
                    edicion.cantidadDevuelta = 0;
                    edicion.backupDevolucionesPorPlantilla = edicion.devolucionesPorPlantilla
                    return edicion;
                });

                this.setState({
                    plantillaEdiciones: [],
                });
                this.setState({
                    plantillaEdiciones: res,
                    loading: false,
                    cargaSuperada: []
                });
                return result;
            })
            .catch((error) => {
                console.log("error", error);
                this.validarVencimientoToken(error.message);
                this.setState({
                    loading: false,
                });
            });
        return respuesta;
    };

    componentDidMount() {
        document.title = "Devoluciones";
        this.reqPlantillas();
    }

    // MANEJA LA SELECCIÓN DE PLANTILLA
    handleChange = async (newValue) => {
        const { plantillas } = this.state;
        if (newValue) {
            const plantillaSelected = plantillas.filter((a) => parseInt(a.plantillaId) === parseInt(newValue.value))[0];
            if (newValue.value) {
                this.setState({
                    plantillaSelected,
                    plantillaValue: null
                });
                this.reqEdiciones(newValue.value);
            }
        }
        return newValue;
    };

    guardarPlantilla = async (edicion) => {
        const plantilla = this.state.plantillaSelected;
        const { plantillaEdiciones } = this.state;
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.token,
        };
        const data = {
            plantillaId: plantilla.plantillaId,
            puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
            devoluciones: plantillaEdiciones.ediciones
                .filter(
                    (e) => e.edicionId === edicion.edicionId && e.cantidadDevuelta >= 0)
                .map((edicion) => {
                    const {
                        edicionDistribuidorSucursalId,
                        edicionId,
                        cantidadDevuelta,
                        subcuentaId
                    } = edicion;
                    return {
                        edicionDistribuidorSucursalId,
                        edicionId,
                        carga: cantidadDevuelta,
                        subcuentaId
                    };
                }),
        };
        const respuesta = await fetch(
            urlServer +
            "/api/distribucion/devolucionRapida/plantilla/" +
            plantilla.plantillaId +
            "/pv/" +
            JSON.parse(localStorage.infoToken).entidad_id +
            "/grabar",
            {
                method: "POST",
                redirect: "manual",
                body: JSON.stringify(data),
                headers,
            }
        )
            .then((response) => response.json())
            .catch((error) => {
                this.validarVencimientoToken(error.message);
                console.log("error", error);
            })
            .then((result) => {
                if (result.exito) {
                    var ediciones = this.state.plantillaEdiciones.ediciones.map((edicion) => {
                        edicion.cantidadDevuelta = 0;
                        edicion.backupDevolucionesPorPlantilla = edicion.devolucionesPorPlantilla
                        return edicion;
                    })
                    this.setState({
                        plantillaEdiciones: {
                            ...this.state.plantillaEdiciones,
                            ediciones,
                        },
                    });
                } else {
                    if (result !== null && result.exito === false) {
                    // muestro mensaje de error
                    var mensaje = '';
                    mensaje = result.devoluciones.mensaje;
                    if (mensaje !== '') {
                        MySwal.fire({
                            icon: 'error',
                            title: mensaje,                            
                            showConfirmButton: true,
                            confirmButtonText: 'Aceptar',
                            customClass: 'swalIconCustom'
                        }).then(async (result) => {
                            const newValue = await this.reqPlantillas(plantilla.descripcion);
                            this.handleChange(newValue[0]);
                        });
                        }
                    }
                }
                return result;
            })
            .catch((error) => {
                this.validarVencimientoToken(error.message);
                console.log("error", error);
            });
        return respuesta;
    };

    cerrarPlantilla = async () => {
        MySwal.fire({
            title: "Confirmación de cierre de devolución",
            text: "¿Desea cerrar las devoluciones? Esta acción no podrá deshacerse",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
            customClass: 'swalIconCustom'
        })
            .then(async (result) => {
            if (result.value) {

                ReactGA.event({
                    category: 'Devoluciones',
                    action: 'Cerrar Devolucion'
                });
                
                    // Cerrar Plantilla          
                    const plantilla = this.state.plantillaSelected;
                    const headers = {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + localStorage.token,
                    };
                    const respuesta = await fetch(
                        urlServer +
                        "/api/distribucion/devolucionRapida/plantilla/" +
                        plantilla.plantillaId +
                        "/pv/" +
                        JSON.parse(localStorage.infoToken).entidad_id +
                        "/cerrar",
                        {
                            method: "POST",
                            redirect: "manual",
                            body: JSON.stringify(),
                            headers,
                        }
                    )
                        .then((response) => response.text())
                        .catch((error) => {
                            this.validarVencimientoToken(error.message);
                            console.log("error", error);
                        })
                        .then(async (result) => {
                            result = JSON.parse(result);
                            if (result !== null && result.exito === false) {
                                // muestro mensaje de error
                                var mensaje = '';
                                    mensaje = result.devoluciones.mensaje;
                                    if (mensaje !== '') {
                                        MySwal.fire({
                                            icon: 'error',
                                            title: mensaje,
                                            showConfirmButton: true,
                                            confirmButtonText: 'Aceptar',                                            
                                            customClass: 'swalIconCustom'
                                        }).then(async (result) => {
                                            const newValue = await this.reqPlantillas(plantilla.descripcion);
                                            this.handleChange(newValue[0]);
                                        });
                                    }
                            } else {
                                MySwal.fire({
                                    icon: "success",
                                    title: "Devolución enviada con éxito",
                                    showConfirmButton: false,
                                    timer: 1500,
                                    customClass: 'swalIconCustom'
                                }).then(async (res) => {
                                    const newValue = await this.reqPlantillas(plantilla.descripcion);
                                    this.handleChange(newValue[0]);
                                });
                            }
                            return result;
                        })
                        .catch((error) => {
                            this.validarVencimientoToken(error.message);
                            console.log("error", error);
                        });
                    return respuesta;
                }
            })
            .catch ((error) => {
            this.validarVencimientoToken(error.message);
            console.log("error", error);
        });
    };

    componentWillUnmount() {
        this.props.hideMenu(true);
    }

    mostrarTotalEjemplaresDevolver = () => {
        var total = 0;
        var aux = this.state.plantillaEdiciones.ediciones.map((card, index) => {
            return { devolucionesPorPlantilla: Number.isInteger(card.devolucionesPorPlantilla) ? card.devolucionesPorPlantilla : 0 };
        })
        for (var a = 0; a < aux.length; a++) {
            total += aux[a].devolucionesPorPlantilla;
        }
        return total;
    }

    mostrarTotalCarga = () => {
        var total = 0;
        var aux = this.state.plantillaEdiciones.ediciones.map((card, index) => {
            return { carga: card.carga };
        })
        for (var a = 0; a < aux.length; a++) {
            total += aux[a].carga;
        }
        return total;
    }

    mostrarTotalAcreditado = () => {
        var total = 0;
        var aux = this.state.plantillaEdiciones.ediciones.map((card, index) => {
            return { acreditado: card.devolucionesAcreditadas };
        })
        for (var a = 0; a < aux.length; a++) {
            total += aux[a].acreditado;
        }
        return total;
    }

    definirBackGround = (edicion) => {
        var color = 'white'
        if (edicion.carga < edicion.devolucionesPorPlantilla || edicion.devolucionesPorPlantilla === 'empty') {
            color = 'hsla(14, 100%, 53%, 0.5)'
        }
        return color
    }

    superaLaCarga = (edicion) => {
        let elemento = document.getElementById(`${edicion.edicionId}`);
        if (elemento) {
            let valorInput = parseInt(elemento.value)
            if(valorInput > edicion.carga) {
                return true;
            }
    
            return false;
        }
    }

    existeEnArreglo = (id) => {
        const { cargaSuperada } = this.state
        const index = cargaSuperada.indexOf(id)
        return index
    }

    pushIdEnArregloCargaSuperada = (edicion) => {
        const { cargaSuperada } = this.state
        let arregloAuxiliar = [...cargaSuperada]

        // reviso si existe
        let id = edicion.edicionId

        const indexEnCargaSuperada = this.existeEnArreglo(id)

        if(indexEnCargaSuperada === -1) {
            arregloAuxiliar.push(id)
            this.setState({cargaSuperada: [...arregloAuxiliar]})
        }
    }

    quitarIdDeArregloCargaSuperada = (edicion) => {
        const { cargaSuperada } = this.state
        let arregloAuxiliar = [...cargaSuperada]

        // reviso si existe
        let id = edicion.edicionId

        const indexEnCargaSuperada = this.existeEnArreglo(id)

        if(indexEnCargaSuperada !== -1) {
            arregloAuxiliar.splice(indexEnCargaSuperada, 1);
            this.setState({cargaSuperada: [...arregloAuxiliar]})
        }
    }

    algunaEdicionSuperaSuCarga = () => {
        const { cargaSuperada } = this.state

        return cargaSuperada.length > 0 ? true : false
    }

    levantarModalErrorCarga = () => {
        var msjprodexc = 'Existen ediciones en la plantilla que superan su carga.'
                
        MySwal.fire({
            icon: 'error',
            title: msjprodexc,
            showConfirmButton: true,
            confirmButtonText: 'Aceptar',
            customClass: 'swalIconCustom'
        });
    }

    imprimirDevoluciones = () => {
        document.getElementById('open-modal').click();

        ReactGA.event({
            category: 'Devoluciones',
            action: 'Imprimir'
        });

        var url = urlServer + '/api/distribucion/devolucion/plantilla/imprimir'
        var data = {
            plantillaId: this.state.plantillaId,
            puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
            puntoVentaDescripcion: '',
            soloEntregados: true,
            distribuidorSucursalId: null,
            distribuidor: ''
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.arrayBuffer();
        }).then((result) => {
            document.getElementById('close-modal').click();
            var byteArray = new Uint8Array(result);
            var mimeType = 'application/pdf';
            var blob = new Blob([byteArray], {type: mimeType});
            var data = window.URL.createObjectURL(blob);
            var link = document.createElement('a');

            if (window.__POIsNativeMode) {
                    link.href = `blobDownload/${this.state.plantillaSelected.descripcion}.pdf/${byteArray}`;
            } else {
                link.href = data;
                link.download = `${this.state.plantillaSelected.descripcion}.pdf`;

                setTimeout(function(){
                    window.URL.revokeObjectURL(data);
                }, 100);
            }

            link.click();
            this.validarVencimientoToken(result.message);

        }).catch((error) => {
            console.log('error al imprimir devoluciones ', error);
            this.validarVencimientoToken(error.message);
            document.getElementById('close-modal').click();
        });
    }

    render() {
        const { loading, plantillaSelected, plantillaEdiciones, filtroPlantillaDevolucion } = this.state;

        return (
            <div id="devoluciones" className="container text-left">
                <Title 
                    title="Devoluciones"
                    accordion={this.state.accordion}
                    alterAccordion={() => {this.setState({accordion: !this.state.accordion});}} 
                />
                {/* FILTROS */}
                <div className={"row overflow-hidden" }>
                    <div className={"filter d-flex justify-content-between overflow-hidden" + (this.state.accordion ? '  mt-0' : ' ')} >  
                      <div style={{width: '33%'}} >
                        <div style={{marginBottom: '12px'}} >
                          Desde
                        </div>
                        <div>
                            <input id="fecha-desde" className="form-control filter-input" type="date" value={this.state.filtroPlantillaDevolucion.fechaDesde} onChange={e => {
                          let fechaHasta = document.getElementById('fecha-hasta')
                          fechaHasta.min = e.target.value
                          this.setState({
                            filtroPlantillaDevolucion: {
                              fechaDesde: e.target.value,
                              fechaHasta: fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                            }
                          })
                          fechaHasta.value = fechaHasta.value? (e.target.value > fechaHasta.value ? e.target.value : fechaHasta.value) : new Date().toISOString().slice(0,10)
                          }} />
                        </div>
                      </div>
                      <div style={{width: '33%'}} >
                        <div style={{marginBottom: '12px'}} >
                          Hasta
                        </div>
                        <div>
                          <input id="fecha-hasta"  className="form-control filter-input" type="date" value={this.state.filtroPlantillaDevolucion.fechaHasta} onChange={e => this.setState({filtroPlantillaDevolucion: { ...filtroPlantillaDevolucion, fechaHasta: e.target.value} })} />
                        </div>
                      </div>
                      <div style={{width: '24%'}} >
                        <div className="eraser" onClick={() => this.clearFilter()}>
                          LIMPIAR
                          <ReactSVG src={eraser} style={{width: '16px'}} />
                        </div>
                        <div style={{width: '100%' }} className="btn button-theme " onClick={() => {
                            this.limpiarPlantillaSeleccionada();
                            this.reqPlantillas();
                        } }> Filtrar </div>
                      </div>
                    </div>
                </div>
                {/* FIN FILTROS */}

                {/* MODAL DESCARGA */}
                <button id="open-modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#loader" style={{display: 'none'}}>
                    </button>
                    <div className="modal" id="loader" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content text-center">
                            <div className="modal-body">
                            <Spinner />
                            </div>
                            Exportando...
                            <div className="modal-footer" style={{display: 'none'}}>
                            <button id="close-modal" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                        </div>
                    </div>
                {/* FIN MODAL DESCARGA */}

                {/* REQUIERE PLANTILLAS */}
                <div className="w-100 " style={{ marginBottom: "10px" }}>
                    <AsyncSelect
                        className="fontAwesome"
                        inputId="busqueda"
                        value={this.state.plantillaValue}
                        defaultOptions={this.state.options}
                        id="test"
                        loadOptions={this.reqPlantillas}
                        onChange={this.handleChange}
                        noOptionsMessage={() => "Escriba el nombre de una plantilla"}
                        placeholder={"Búsqueda por Plantilla"}
                    />
                </div>
                {/* // REQUIERE PLANTILLAS // */}

                <div className="text-center f-16 fw-400">
                    {plantillaSelected ? (
                        (plantillaSelected.yaCerrada || plantillaSelected.yaCargada) ? (
                            <React.Fragment>
                                <div className="d-flex justify-content-center align-items-center">
                                    <div>
                                        {plantillaSelected.descripcion}{" "}
                                        <span className="fontAwesome"></span>
                                    </div>
                                    <div className="pl-2">
                                        <div className="printer">
                                            <span onClick={this.imprimirDevoluciones}>
                                                
                                            </span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </React.Fragment>
                        ) 
                        :
                        plantillaSelected.descripcion ?
                        ( 
                            <div className="d-flex justify-content-center align-items-center">
                                <div>
                                    {plantillaSelected.descripcion}
                                </div>
                                <div className="pl-2">
                                    <div className="printer">
                                        <span onClick={this.imprimirDevoluciones}>
                                            
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        (
                            <div>
                                &nbsp;
                            </div>
                        )
                        
                    ) : null}
                </div>
                
                {loading ? (
                    <Spinner style={{ fontSize: "8px" }} />
                ) : (
                    <React.Fragment>
                        <div className="cards">
                            {/* ITERANDO EDICIONES */}
                            {plantillaEdiciones.ediciones
                                    ? plantillaEdiciones.ediciones.map((edicion, index) => {
                                    const totalDevoluciones = edicion.backupDevolucionesPorPlantilla + edicion.cantidadDevuelta;
                                    return (
                                        //PRIMERA COLUMNA DE LA CARD
                                        <div key={index}
                                            className="box justify-content-between align-items-center days"
                                            style={{ padding: "10px", marginTop: "10px" }}>
                                            <div className="">
                                                {/*Titulo de la card*/}
                                                <div style={{
                                                    color: "#EA3F3F",
                                                    fontWeight: "500",
                                                    fontSize: "14px",
                                                    textAlign: "justify",
                                                }}>
                                                   {edicion.productoDescripcion}
                                                </div>
                                                {/*descripcion de la edicion y precio */}
                                                <div className="d-flex justify-content-between align-items-start w-400" style={{ marginTop: "5px" }}>
                                                    <div className="desc-reclamo" style={{paddingRight: '10px'}}>
                                                        ED:
                                                        <span className="ml-1">
                                                            {edicion.edicionDescripcionActual
                                                                .replace("Ed. ", "")
                                                                .replace("Ed.", "")}
                                                        </span>
                                                    </div>
                                                    <div className="desc-reclamo">
                                                        PRECIO:
                                                        <span className="ml-1">${edicion.precioAImprimir.toFixed(2)}</span>
                                                    </div>
                                                </div>
                                                
                                                {/*la carga*/}
                                                {plantillaEdiciones &&
                                                    (plantillaEdiciones.yaCerrada ||
                                                        plantillaEdiciones.yaCargada  ) ?
                                                        <div className="d-flex justify-content-between align-items-center w-400" style={{ marginTop: "3px"}}>
                                                            <div className="desc-reclamo">
                                                                CARGA:
                                                                <span className="ml-1">{edicion.carga}</span>
                                                            </div>                                                    
                                                            <div className="desc-reclamo">
                                                                DEVUELTO:
                                                                <span className="ml-1">{edicion.devolucionesPorPlantilla}</span>
                                                            </div>                                                    
                                                            <div className="desc-reclamo">
                                                                ACREDITADO:
                                                                <span className="ml-1">{edicion.devolucionesAcreditadas}</span>
                                                            </div>
                                                        </div>
                                                :
                                                 <div className="d-flex justify-content-between align-items-center w-400" style={{marginTop: "3px"}}>
                                                    <div className="desc-reclamo">
                                                        CARGA:
                                                        <span className="ml-1">{edicion.carga}</span>
                                                    </div>    
                                                 </div>
                                                }   
                                            </div>
                                            {/*SEGUNDA COLUMNA DE LA CARD*/}
                                            <div
                                                className="w-100 footerItem align-items-center d-flex justify-content-between"
                                                style={{ width: "100%", minWidth: "88px", marginTop: "5px" }}>
                                                {plantillaEdiciones &&
                                                    (plantillaEdiciones.yaCerrada ||
                                                        plantillaEdiciones.yaCargada) ? (
                                                    <span style={{ fontSize: 35 }}
                                                        className="fontAwesome">
                                                        
                                                    </span>
                                                ) : (

                                                    <React.Fragment>
                                                        {/*Tipo SP1 o SP2*/}
                                                        {edicion.pedido ?
                                                        <div className="badge badge-theme-red f-13-5 mt-1" style={{ float: "left" }}>
                                                            {edicion.pedido}
                                                        </div>
                                                        :
                                                        <div className="f-13-5 mt-1" style={{ float: "left" }}>
                                                            &nbsp;
                                                        </div>
                                                        }
                                                        <div className="d-flex justify-content-between align-items-center elemento-botones-input">
                                                            {/* BOTON RESTAR CANTIDAD */}
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    background: totalDevoluciones === 0 || (plantillaSelected && plantillaSelected?.yaCerrada) || this.superaLaCarga(edicion) ? "#FCFCFC" : "#F4F4F4",
                                                                    width: "26px",
                                                                    height: "26px",
                                                                    borderRadius: "50%",
                                                                    padding: "5px",
                                                                }}
                                                                onClick={() => {
                                                                    if (totalDevoluciones > 0 && !this.superaLaCarga(edicion) && !(plantillaSelected && plantillaSelected?.yaCerrada)) {
                                                                        edicion.devolucionesPorPlantilla = parseInt(document.getElementById(`${edicion.edicionId}`).value);
                                                                        edicion.devolucionesPorPlantilla--
                                                                        edicion.cantidadDevuelta = edicion.devolucionesPorPlantilla;
                                                                        let { ediciones } = this.state.plantillaEdiciones;
                                                                        edicion.backupDevolucionesPorPlantilla = edicion.devolucionesPorPlantilla;
                                                                        ediciones.forEach(function (ed) {
                                                                            if (ed.edicionId !== edicion.edicionId) {
                                                                                ed.cantidadDevuelta = 0;
                                                                            }
                                                                        });
                                                                        let ind = ediciones.findIndex((e) => e.edicionId === edicion.edicionId);
                                                                        ediciones[ind] = edicion;
                                                                        this.setState({
                                                                            plantillaEdiciones: {
                                                                                ...this.state.plantillaEdiciones,
                                                                                ediciones,
                                                                            },
                                                                        });
                                                                        this.guardarPlantilla(edicion);
                                                                    }
                                                                }}
                                                            >
                                                                <ReactSVG
                                                                    src={restar}
                                                                    style={{
                                                                        color: totalDevoluciones === 0 || (plantillaSelected && plantillaSelected?.yaCerrada) || this.superaLaCarga(edicion)? "#EAEAEA" : "#8E95A5",
                                                                        width: "11px",
                                                                        height: "18px",
                                                                    }} />
                                                            </div>
                                                            {/* // BOTON RESTAR CANTIDAD // */}

                                                            {/* CANTIDAD A MOSTRAR */}

                                                            <div className="f-13-5" style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                                <input
                                                                    style={{ backgroundColor: this.definirBackGround(edicion), border: '#343435 solid 1px' }}
                                                                    type='number'
                                                                    className='input-of-cantidades text-center'
                                                                    onInput={(e) => e.target.value = e.target.value.slice(0, 3)}
                                                                    min="0"
                                                                    max="999"
                                                                    maxLength='3'
                                                                    id={`${edicion.edicionId}`}

                                                                    value={edicion.devolucionesPorPlantilla}
                                                                    autoComplete="off"
                                                                    onFocus={(e) => {
                                                                        e.target.select()
                                                                    }}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === "Enter") {
                                                                            e.currentTarget.blur()
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        let regex = /[^0-9]/g
                                                                        if (regex.test(e.target.value)) {
                                                                            e.target.value = e.target.value.replace(regex, '')
                                                                        }
                                                                        edicion.devolucionesPorPlantilla = e.target.value === "" || edicion.devolucionesPorPlantilla === "empty" ? 0 : parseInt(e.target.value);

                                                                            if (edicion.carga >= edicion.devolucionesPorPlantilla) {
                                                                                // Sacar del arreglo cargaSuperada la edicion
                                                                                this.quitarIdDeArregloCargaSuperada(edicion)

                                                                                let { ediciones } = this.state.plantillaEdiciones;

                                                                                edicion.cantidadDevuelta = edicion.devolucionesPorPlantilla;
                                                                                edicion.backupDevolucionesPorPlantilla = edicion.devolucionesPorPlantilla;
                                                                                let ind = ediciones.findIndex(e => e.edicionId === edicion.edicionId);
                                                                                ediciones.forEach(function (ed) {
                                                                                    if (ed.edicionId !== edicion.edicionId) {
                                                                                        ed.cantidadDevuelta = 0;
                                                                                    }
                                                                                });
                                                                                ediciones[ind] = edicion;
                                                                                this.setState({ ediciones });
                                                                                if (edicion.devolucionesPorPlantilla >= 0 && edicion.carga >= edicion.devolucionesPorPlantilla && edicion.cantidadDevuelta >= 0) {
                                                                                    this.guardarPlantilla(edicion);
                                                                                }
                                                                            } else {
                                                                                // cargar en el arreglo de cargaSuperada la edicion
                                                                                this.pushIdEnArregloCargaSuperada(edicion)
                                                                                }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        let regex = /[^0-9]/g
                                                                        if (regex.test(e.target.value)) {
                                                                            e.target.value = e.target.value.replace(regex, '')
                                                                        }

                                                                        e.target.value = e.target.value === "0" ? e.target.value : e.target.value.replace(/^(0+)/g, '');
                                                                        edicion.devolucionesPorPlantilla = e.target.value === "" ? 'empty' : parseInt(e.target.value);

                                                                        let { ediciones } = this.state.plantillaEdiciones;
                                                                            
                                                                        let ind = ediciones.findIndex(e => e.edicionId === edicion.edicionId)
                                                                        ediciones[ind] = edicion
                                                                        this.setState({ ediciones })
                                                                        
                                                                    }}
                                                                />
                                                            </div>

                                                            {/* // CANTIDAD A MOSTRAR // */}

                                                            {/* BOTON SUMAR CANTIDAD */}
                                                            <div
                                                                className="d-flex justify-content-center align-items-center"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    background: edicion.carga > edicion.devolucionesPorPlantilla && !plantillaSelected?.yaCerrada ? "#F4F4F4" : "#FCFCFC",
                                                                    width: "26px",
                                                                    height: "26px",
                                                                    borderRadius: "50%",
                                                                    padding: "5px",

                                                                }}
                                                                onClick={() => {
                                                                    if (edicion.carga > totalDevoluciones && !plantillaSelected.yaCerrada) {
                                                                        edicion.devolucionesPorPlantilla = parseInt(document.getElementById(`${edicion.edicionId}`).value);
                                                                        edicion.devolucionesPorPlantilla++
                                                                        edicion.cantidadDevuelta = edicion.devolucionesPorPlantilla;

                                                                        let { ediciones } = this.state.plantillaEdiciones;
                                                                        edicion.backupDevolucionesPorPlantilla = edicion.devolucionesPorPlantilla;
                                                                        ediciones.forEach(function (ed) {
                                                                            if (ed.edicionId !== edicion.edicionId) {
                                                                                ed.cantidadDevuelta = 0;
                                                                            }
                                                                        });
                                                                        let ind = ediciones.findIndex((e) => e.edicionId === edicion.edicionId);

                                                                        ediciones[ind] = edicion;
                                                                        this.setState({
                                                                            plantillaEdiciones: {
                                                                                ...this.state.plantillaEdiciones,
                                                                                ediciones,
                                                                            },
                                                                        });

                                                                        this.guardarPlantilla(edicion);
                                                                    }
                                                                }}
                                                            >
                                                                <ReactSVG
                                                                    src={sumar}
                                                                    style={{
                                                                        color: edicion.carga > edicion.devolucionesPorPlantilla && !plantillaSelected?.yaCerrada ? "#8E95A5" : "#EAEAEA",
                                                                        width: "11px",
                                                                        height: "18px",
                                                                    }} />
                                                            </div>
                                                            {/* // BOTON SUMAR CANTIDAD // */}
                                                        </div>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })
                                : null}
                            {/* // ITERANDO EDICIONES // */}
                        </div>
                   
                        {/* BOTON ENVIAR (para cerrar plantilla) */}
                        {plantillaEdiciones.ediciones !== undefined 
                            && plantillaEdiciones.ediciones.length !== 0  
                            && plantillaEdiciones.length !== 0 
                            && plantillaSelected 
                            && !plantillaSelected?.yaCerrada
                            && !plantillaSelected?.yaCargada ? (
                            <div>
                               <div id="barra-cantidad-devolver" className="barra-cantidad-devolver" style={{ backgroundColor: 'white', color: '#224372', fontWeight: 'bold' }}>
                                    <div className="d-flex justify-content-between">
                                        <div style={{ lineHeight: '1.2' }}>
                                            Carga: {this.mostrarTotalCarga()}
                                        </div>
                                        <div style={{ lineHeight: '1.2' }}>
                                            Devuelto: {this.mostrarTotalEjemplaresDevolver()}
                                        </div>
                                    </div>
                                </div>

                               <div id="boton-enviar"
                                    className="d-flex justify-content-center align-items-center barra-enviar">
                                    <div className="d-flex justify-content-center align-items-center"
                                        onClick={() => {
                                            if(this.algunaEdicionSuperaSuCarga()) {
                                               this.levantarModalErrorCarga()
                                            } else {
                                                this.cerrarPlantilla();
                                            }
                                        }}
                                        style={{
                                            background: "#224372",
                                            color: "white",
                                            fontSize: "12px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            borderRadius: "16px",
                                            width: "90px",
                                            height: "33px",
                                        }}>
                                        Enviar
                                    </div>
                               </div>
                            </div>
                            
                        ) : 
                           plantillaSelected?.yaCerrada || plantillaSelected?.yaCargada  ? (
                            <div>
                                <div id="boton-enviar" className="barra-cantidad-devolver-cerrada" style={{ backgroundColor: 'white', color: '#224372', fontWeight: 'bold' }}>
                                        <div className="d-flex justify-content-between">
                                            <div style={{ lineHeight: '1.2' }}>
                                                Carga: {this.mostrarTotalCarga()}
                                            </div>
                                            <div style={{ lineHeight: '1.2' }}>
                                                Devuelto: {this.mostrarTotalEjemplaresDevolver()}
                                            </div>
                                            <div style={{ lineHeight: '1.2' }}>
                                                Acreditado: {this.mostrarTotalAcreditado()}
                                            </div>
                                        </div>
                                </div>
                            </div>
                           )
                           : null
                        }
                        {/* // BOTON ENVIAR (para cerrar plantilla) // */}
                    </React.Fragment>
                )}
            </div>
        );
    }
}
