import React from 'react'
import addDefaultSrc from '../assets/ic_cc.svg';
import './modalproductonoeditorial.css';
import './NotificacionPopUp.css'

const PopUp = ({popUp, marcarNotifLeida, consultarMostrarPopUpLista, obtenerNotificaciones}) => {

    const renderPopUpOAnuncio = () => {

        const renderImagen = () => {
            if (popUp.tieneImagen) {
                return(
                    <img alt=""
                        className="d-block w-100 carousel-img"
                        src={popUp.urlImagen}
                        onError={(e) => e.target.src = addDefaultSrc}
                        style={{height: '200px', width: '265px'}}
                        onClick={() => {
                            if (popUp.link) {
                                let url = popUp.url
                                let a =  window.open(url, '_blank')
                                a.focus();
                            }

                        }}
                    />
                )
            }
        }
        return (
            <div className="" id="productoNoEditorial">
                <div className="" >
                    <div className="modal-header d-flex">
                        <h6 className="modal-title">
                            {popUp.titulo}
                        </h6>
                    </div>
                    <div className="modal-body-notificacion" style={{textAlign: 'left', cursor: popUp.link ? 'pointer' : 'default'}}
                        onClick={() => {
                            if(popUp.link) {
                                let url = popUp.url
                                let a = window.open(url, '_blank'); 
                                a.focus();
                            }
                        }}
                    >
                        <div>
                            {renderImagen()}
                        </div>
                        <div className="text-justify pt-3">
                            {popUp.mensaje}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="popUpNotif" key={popUp.notificacionId}>
            <div className="modal-content">
                {renderPopUpOAnuncio()}
                <div className="modal-footer">
                    <button 
                        className='close-button' 
                        onClick={async () => {
                            let campanita = document.getElementById('campanita');
                            if(campanita) campanita.style.pointerEvents = 'all'
                            let body = document.getElementById('body')
                            body.style.pointerEvents = 'all';
                            marcarNotifLeida(popUp.notificacionId, consultarMostrarPopUpLista, obtenerNotificaciones);

                            // id 1 representa la accion "Refresh".
                            if(popUp.accion === 1) {
                                window.location.reload();
                            }
                        }}
                        style={{
                            display: popUp.esInvasivo ?  'none' : 'all'
                        }}
                    >
                        Aceptar
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopUp