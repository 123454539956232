import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import restar from '../assets/restar.svg'
import sumar from '../assets/sumar.svg'
import tienda from '../assets/ic_cc.svg'
import eliminar from '../assets/eliminar.svg'
import Spinner from '../components/spinner';
import urlServer from '../server'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';
import AppContext from "../context/AppContext";
import { guardarConexion } from '../guardarConexion';
import { version } from '../../package.json'

const MySwal = withReactContent(Swal)

const versionActual = version.replace(/\./g, '')

export default class Carrito extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            producto: this.props.props.location.state ? (this.props.props.location.state.producto.ediciones ? {
                ...this.props.props.location.state.producto,
                publicacionTipoId: 1,
            } : this.props.props.location.state.producto) : null,
            loading: true,
            busqueda: '',
            busquedaPorCategoria: '',
            busquedaPorEdicionBanner: '',
            publicationCategoria: [],
            categoriaSeleccionada: null,
            redirect: false,
            flag: true,
            flagCatalogoCategoria: true,
            backendrows: this.props.props.location.state && this.props.props.location.state.producto.ediciones ? this.props.props.location.state.producto.ediciones.map(edicion => {
                if (!edicion.cantidad && edicion.cantidad !== 0) {
                    edicion.cantidad = 0
                }
                return edicion
            }) : [],
            pedido: [],
            successCC: false,
            seleccionadasFlag: true,
            diasSemana: [
                {
                    dia: 'Lunes',
                    DiaSemana: 2,
                    cantidad: 0,
                },
                {
                    dia: 'Martes',
                    DiaSemana: 3,
                    cantidad: 0,
                },
                {
                    dia: 'Miércoles',
                    DiaSemana: 4,
                    cantidad: 0,
                },
                {
                    dia: 'Jueves',
                    DiaSemana: 5,
                    cantidad: 0,
                },
                {
                    dia: 'Viernes',
                    DiaSemana: 6,
                    cantidad: 0,
                },
                {
                    dia: 'Sábado',
                    DiaSemana: 7,
                    cantidad: 0,
                },
                {
                    dia: 'Domingo',
                    DiaSemana: 1,
                    cantidad: 0,
                },
            ],
            ultimasrows: [],
            siguientesrows: [],
            categoriaId: 0,
            bannerMarketingShow: false,
            bannerSeleccionado: null,
            bannerEdicionesConfiguradas: [],
            banners: [],
            puntos: this.props.puntos,
            dineroDisponibleMontoDiario: null,
            montoDiarioControl: false,
            publicacionesConExcedente: [],
            listadoPedido: {},
            carritoVirtual: [],
            arregloExcedioPuntos: [],
            stockControl: false,
            canjeHabilitado: false,
            canjeHabilitadoMensaje: '',
            //incentivoHabilitado: false,
            incentivoPedidoCompraHabilitado: false,
            pedidoCompraHabilitado: false,
            pedidoCompraHabilitadoMensaje: '',
            retiroPresencial: false,
            timerId: []
        }
    }

    static contextType = AppContext;

    history = createBrowserHistory()

    validarVencimientoToken(mensaje) {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
                return;
            }
        }
        //this.versionResquest();
        return
    }

    actualizarPermisos = (versionDB) => {
        var infoToken = JSON.parse(localStorage.infoToken)
        var data = {
            usuarioNombre: infoToken.name,
            ambitoId: 7,
            entidadId: infoToken.entidad_id,
            permisoNombre: 'kiosco'
        }
        var url = urlServer + "/api/account/permisos/kiosco/buscar";
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((permisos) => {

            infoToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] = [...permisos];
            localStorage.setItem("infoToken", JSON.stringify(infoToken));
            localStorage.setItem("versionLocal", versionDB);

            MySwal.fire({
                icon: 'info',
                title: 'Se est&aacute actualizando una nueva versi&oacuten de ParadaOnLine.',
                showConfirmButton: false,
                timer: 2000,
                customClass: 'swalIconCustom'
            }).then((result) => {
                if (result.value) {
                    this.setState({ loading: true })
                    window.location.reload();
                } else {
                    window.location.reload();
                }
            }).catch(error => {
                console.log('error', error)
            })

        }).catch((error) => {
            console.log('buscar permisos > error :', error)
        })

    }

    versionResquest = async () => {
        const headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
        }

        const url = urlServer + "/api/app/version"

        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            headers: headers
        })
            .then(response => response.json())
            .then(result => {

                var versionDB = 0;
                if (typeof result !== 'undefined' && result.length > 0) {
                    versionDB = parseInt(result[0].versionValor, 10);
                }

                if (versionDB > versionActual && (versionDB > localStorage.versionLocal || localStorage.versionLocal === undefined)) {
                    this.actualizarPermisos(versionDB)
                }
            })
            .catch(error => {
                console.log('versionResquest > error :', error)
            })

        return respuesta
    }

    mostrarCanje = () => {
        var result = 'none';
        //muestro si sumo cuando compra en pesos o si puedo hacer canje
        if (this.state.incentivoPedidoCompraHabilitado || this.state.canjeHabilitado) {
            result = '';
        }
        return result;
    }

    mostrarMsjDSAlGuardar = () => {
        var result = false;
        //si la configuracion de pesos y canje estan deshabilitadas muestro el msje general de ds deshabilitada
        if (this.state.incentivoPedidoCompraHabilitado === false && this.state.canjeHabilitado === false) {
            result = true;
        } 
        return result;
    }


    mostrarMensajeDetalladoPeso = (edicion) => {
        var result = 'none';
        //para mostrar el detalle compra en peso tiene que esta deshabilitado y compra con canje tiene que esta habilitado
        if (edicion.utilizaPunto === false && this.state.pedidoCompraHabilitado === false && this.state.canjeHabilitado ===true){
            result = 'inline';
        }
        return result;
    }


    mostrarMensajeDetalladoCanje = (edicion) => {
        var result = 'none';
        //para mostrar el detalle compra con canje tiene que esta deshabilitado y compra en pesos tiene que esta habilitado
        if (edicion.utilizaPunto === true && this.state.canjeHabilitado === false && this.state.pedidoCompraHabilitado === true) {
            result = 'inline';
        }
        return result;
    }



    retiroPresencialHabilitado = () => {
        const Links = JSON.parse(localStorage.infoToken)[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];

        if (typeof Links !== 'undefined') {
            Links.forEach(element => {
                if (element === 'KIOSCO.Tienda.RetiroPresencial') {
                    this.setState({ retiroPresencial: true });
                }
            });
        }
    }

    dataTienda = async (confirmarPedido = false) => {

        ReactGA.event({
            category: 'Tienda/CargarPedido',
            action: 'Mostrar Catalogo'
        });

        this.setState({
            carritoCantidad: 0,
            carritoVirtual: [],
            loading: true
        })

        // Recuperar ediciones del carrito
        var urlCarrito = urlServer + "/api/tienda/carrito/obtener";
        var dataCarrito = {
            UsuarioId: null
        };

        this.puntos();
        const respuesta = await fetch(urlCarrito, {
            method: 'POST',
            body: JSON.stringify(dataCarrito),
            headers: {
                'Authorization': 'bearer ' + localStorage.token,
                'Content-Type': 'application/json'
            }
        }).then(responseCarrito => {
            return responseCarrito.json();
        }).then(resultCarrito => {
            this.validarVencimientoToken(resultCarrito.message);
            var totalPuntosAcumulado = 0;
            resultCarrito.filter(e => e.cantidad > 0).map(function puntosTotalDeEdiciones(e) {
                totalPuntosAcumulado = e.cantidad * e.puntos + totalPuntosAcumulado
            })

            if (resultCarrito && resultCarrito[0].enProceso) {
                Swal.fire({
                    title: 'Existe un pedido en proceso',
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Aceptar',
                    customClass: 'swalIconCustom',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then((result) => {
                    if (result.value) {
                        window.location.reload();
                    }
                })
            }

            const dataCatalogo = resultCarrito.map((edicion, index) => {
                let cantidad = 0
                for (let i = 0; i < resultCarrito.length; i++) {
                    if (resultCarrito[i].edicionId === edicion.edicionId) {
                        cantidad += resultCarrito[i].cantidad
                    }
                }

                edicion = {
                    ...edicion,
                    stockInsuficiente: false,
                    stockInicial: cantidad + edicion.stockDisponibleAlmacen,
                    puntosInicial: totalPuntosAcumulado + this.state.puntos,
                    cantidadBackUp: edicion.cantidad
                };

                return edicion
            })

            this.setState({
                backendrows: dataCatalogo,
                producto: dataCatalogo,
                carritoVirtual: [...dataCatalogo]
            })
            this.props.actualizarNumeroCarrito(resultCarrito.length)
        }).catch(errorCarrito => {
            this.setState({ loading: false })
            this.validarVencimientoToken(errorCarrito.message);
            console.log("error al precargar carrito", errorCarrito);
        });

        return respuesta
    }

    // Esta es la segunda validacion, que se ejecuta despues de la funcion "controlMontoDiario", la cual toma el state
    // montoDiarioControl, que es verdadero o falso y determina si la validacion "controlMontoDiario" fue verdadero o falso.
    controlCantidadPedidoDiario = (montoDiarioControl) => {
        const url = urlServer + '/api/tienda/cantidadPedidoDiario'
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }
        const data = {
            puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
            edicionId: null
        }
        var respuesta = fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
            .then(response => response.json())
            .then(result => {

                this.validarVencimientoToken(result.message);
                // Una vez la respuesta es Exitosa, hago un control para saber cual es el mensaje de error que voy a utilizar (si es por monto diario, por cantidad pedido o ambos)
                // o si fue exitoso. Aviso: el control por stock viene luego si es que nada falla.

                this.setState({ publicacionesConExcedente: result })
                const { publicacionesConExcedente, backendrows } = this.state
                let flagEncontroExcedente = false;
                if (publicacionesConExcedente.length > 0) {
                    //var limitePedido = publicacionesConExcedente[0].limitePedido

                    backendrows.forEach(element => {

                        if (element.cantidad > 0) {
                            var indice = publicacionesConExcedente.findIndex(ed => ed.edicionId === element.edicionId)
                            if (indice !== -1) {
                                flagEncontroExcedente = true;
                            }
                        }
                    });
                }

                if (flagEncontroExcedente) {
                    var msjprodexc = `Existen productos que exceden la cantidad de ejemplares diarios permitidos. Disminuya la cantidad solicitada o saque el producto del carrito.`;

                    MySwal.fire({
                        icon: 'error',
                        title: msjprodexc,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar',
                        customClass: 'swalIconCustom'
                    });

                    publicacionesConExcedente.forEach(elementConExcedente => {
                        backendrows.forEach(elementEdiciones => {
                            if (elementConExcedente.edicionId === elementEdiciones.edicionId) elementEdiciones.totalPedido = elementConExcedente.cantidadTotal
                        });
                    });

                    let body = document.getElementById('body');
                    let root = document.getElementById('root');
                    body.style.pointerEvents = 'all'
                    root.style.pointerEvents = 'all'

                    this.setState({
                        backendrows: backendrows,
                        loading: false
                    });

                } else if (montoDiarioControl) {

                    var msjmontoexc = "No puede realizar pedidos. Por favor, comuníquese con su Distribuidora. </br> Disponible para compra: $" + this.state.dineroDisponibleMontoDiario;

                    MySwal.fire({
                        icon: 'error',
                        title: msjmontoexc,
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar',
                        customClass: 'swalIconCustom'
                    });

                    let body = document.getElementById('body');
                    let root = document.getElementById('root');
                    body.style.pointerEvents = 'all'
                    root.style.pointerEvents = 'all'

                    this.setState({
                        backendrows: this.state.backendrows,
                        loading: false
                    });

                } else {
                    // Dentro de esta funcion, luego de todos los controles hechos arriba, controla el stock y si esta todo bien se ejecuta "enviarPedido" que guarda el pedido
                    this.verificarStockDisponible()
                }

            }).catch((error) => {
                this.validarVencimientoToken(error.message);
                console.log('error al crear pedido de tienda ', error);
                this.setState({ loading: false });
            });

        return respuesta
    }

    // Al hacer click en el boton "Confirmar Pedido" para enviar el pedido en el carrito, primero se ejecuta esta funcion
    // que tiene como objetivo devolver verdadero o falso. Esta es la primer validacion
    controlMontoDiario = () => {
        const url = urlServer + '/api/tienda/montoDiario'
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }

        const data = {
            puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
            nombreTienda: "Tienda Agea"
        }

        var respuesta = fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
            .then(response => response.json())
            .then(result => {
                this.validarVencimientoToken(result.message);
                var total = this.mostrarTotalCompraTienda();
                if (result.monto !== 0 && result.disponible < total) {
                    this.setState({ dineroDisponibleMontoDiario: result.disponible })
                    if (result.disponible <= 0) {
                        this.setState({ dineroDisponibleMontoDiario: 0 })
                    }
                    this.controlCantidadPedidoDiario(true)
                } else {
                    this.controlCantidadPedidoDiario(false)
                }
            }).catch((error) => {
                this.validarVencimientoToken(error.message);
                console.log('error al crear pedido de tienda ', error);
                this.setState({ loading: false });
            });

        return respuesta
    }

    showExceso = (edicion) => {
        const { publicacionesConExcedente } = this.state;

        let tieneExceso = false;
        if (publicacionesConExcedente.length > 0) {
            for (let index = 0; index < publicacionesConExcedente.length; index++) {
                if (publicacionesConExcedente[index].edicionId === edicion.edicionId && edicion.totalPedido > publicacionesConExcedente[index].limitePedido) {
                    tieneExceso = true;
                    break;
                }
            }
        }

        return tieneExceso ? 'inline' : 'none';
    }

    showMensajeExceso = (edicion) => {
        const { publicacionesConExcedente } = this.state;

        let tieneExceso = false;
        if (publicacionesConExcedente.length > 0) {
            for (let index = 0; index < publicacionesConExcedente.length; index++) {
                if (publicacionesConExcedente[index].edicionId === edicion.edicionId && edicion.totalPedido > publicacionesConExcedente[index].limitePedido) {
                    tieneExceso = true;
                    break;
                }
            }
        }

        return tieneExceso && edicion.stockInicial - edicion.cantidad >= 0 ? 'inline' : 'none';
    }

    quitarDePublicacionesConExcedente = (edicion) => {
        const { publicacionesConExcedente } = this.state;

        let ind = publicacionesConExcedente.findIndex(e => e.edicionId === edicion.edicionId)
        if (ind !== -1 && edicion.cantidad <= edicion.limitePedido) {
            publicacionesConExcedente.splice(ind, 1)
            this.setState({ publicacionesConExcedente: publicacionesConExcedente })
        }

    }

    calcularExceso = (edicion) => {
        const { publicacionesConExcedente } = this.state;
        let cantidad;
        for (let index = 0; index < publicacionesConExcedente.length; index++) {
            if (publicacionesConExcedente[index].edicionId === edicion.edicionId && edicion.totalPedido > publicacionesConExcedente[index].limitePedido) {
                cantidad = edicion.totalPedido - publicacionesConExcedente[index].limitePedido
                break;
            }
        }

        return cantidad
    }

    puntos = async () => {

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token
        }

        const data = {
            nombreTienda: "Tienda Agea",
            puntoVentaId: null
        }

        const url = urlServer + "/api/incentivo/puntos/obtener"
        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            body: JSON.stringify(data),
            headers
        })
            .then(response => response.json())
            .then(resultPuntos => {
                this.validarVencimientoToken(resultPuntos.message);

                var disponible = resultPuntos[0].disponible;
                var diasRestantes = resultPuntos[0].diasRestantes;
                var darAviso = resultPuntos[0].darAviso

                this.setState({
                    puntos: disponible,
                    diasRestantes: diasRestantes,
                    darAviso: darAviso

                }, () => this.setState({ loading: false }))
            })
            .catch(error => {
                this.validarVencimientoToken(error.message);
                console.log('error', error)
                this.setState({
                    loading: false
                })
            });
        return respuesta
    }

    obtenerConfiguracionCanjeHabilitado = () => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.Canje.Habilitado',
            entidadId: null,
            devuelveMensaje: true,
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.token
            }

        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.setState({
                canjeHabilitado: result[0].valor === "1" ? true : false,
                canjeHabilitadoMensaje: result[0].mensaje
            })

        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error al obtener la configuracion de incentivo habilitado')
        })
    }

    obtenerConfiguracionIncentivoPedidoCompraHabilitado = () => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.Incentivos.PedidoCompra.Habilitado',
            entidadId: null
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.token
            }

        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.setState({
                incentivoPedidoCompraHabilitado: result[0].valor === "1" ? true : false
            })
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error al obtener la configuracion de incentivo Pedido Compra habilitado')
        })
    }

    obtenerConfiguracionPedidoCompraHabilitado = () => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.PedidoCompra.Habilitado',
            devuelveMensaje: true,
            entidadId: null
        }
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "bearer " + localStorage.token
            }

        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.setState({
                pedidoCompraHabilitado: result[0].valor === "1" ? true : false,
                pedidoCompraHabilitadoMensaje: result[0].mensaje
            })
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error al obtener la configuracion de incentivo Pedido Compra habilitado')
        })
    }

    componentDidMount() {
        this.retiroPresencialHabilitado();
        this.dataTienda();
        this.obtenerConfiguracionCanjeHabilitado();
        this.obtenerConfiguracionIncentivoPedidoCompraHabilitado();
        this.obtenerConfiguracionPedidoCompraHabilitado();
    }

    enviarPedido = async() => {
        
        ReactGA.event({
            category: 'Tienda/CargarPedido',
            action: 'Enviar Pedido'
            });
        
        const url = urlServer + '/api/pedidopasadofuturo/pedidoPasado/guardar'
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }
        if (this.state.producto.publicacionTipoId !== 2) { // NO DIARIO
            var listadoPedido = this.state.backendrows.filter(e => e.cantidad>0).map((ed)=>{
                const data = {
                    edicionId: ed.edicionId,                    
                    cantidad: ed.cantidad,
                    utilizaPunto: ed.utilizaPunto,
                    tipoDeEntrega: ed.tipoDeEntrega
                }
                return data;
            });

            const data = {
               pedidos: listadoPedido, 
               puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
               usuarioId: JSON.parse(localStorage.infoToken).usuario_id,
               nombreTienda: "Tienda Agea",
                //tienePermisoIncentivo: this.tienePermisoIncentivo(),
               tienePermisoRetiroPresencial: this.state.retiroPresencial
            };
            const promesa = await fetch(url, {
                method: 'POST',
                redirect: 'manual',
                body: JSON.stringify(data),
                headers
            }).then(response => {
                if (parseInt(response.status) !== 200) { throw response }

                if (parseInt(response.status) === 200) {
                    return response.json()
                }
            }).then(result => {
                this.validarVencimientoToken(result.message);
                if (!result.esValido && typeof result.esValido !== "undefined") {
                    MySwal.fire({
                        icon: 'error',
                        title: result.mensaje,
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: 'swalIconCustom'
                    })
                
                    this.dataTienda();
                    this.actualizarCantidadCarrito();

                    this.obtenerConfiguracionCanjeHabilitado();
                    this.obtenerConfiguracionIncentivoPedidoCompraHabilitado();
                    this.obtenerConfiguracionPedidoCompraHabilitado();

                    this.setState({loading: false})

                    let body = document.getElementById('body');
                    let root = document.getElementById('root');
                    body.style.pointerEvents = 'all'
                    root.style.pointerEvents = 'all'

                    return result
                }

                if (!result.exito) {
                    Swal.fire({
                        title: result.mensaje,
                        // text: msj,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Aceptar',
                        customClass: 'swalIconCustom',
                        allowOutsideClick: false,
                        allowOutsideKey: false
                        }).then((result) => {
                        if (result.value) {
                            window.location.reload()
                        }
                    })
                    return result
                }

                let usuarioId = JSON.parse(localStorage.getItem('infoToken')).usuario_id;
                guardarConexion('PEDIDOSTIENDA', usuarioId, {pedidoPasadoContenedorId: result.pedidoPasadoContenedorId})
                this.incentivoPedidoCompraHabilitadoMostrarPuntos(result.pedidoPasadoContenedorId);
            }).catch(err => {
                this.validarVencimientoToken(err.message);

                console.log(err)
                MySwal.fire({
                icon: 'error',
                title: err.mensaje || err.message,
                showConfirmButton: false,
                timer: 2000,
                customClass: 'swalIconCustom'
                })
                
                let body = document.getElementById('body');
                let root = document.getElementById('root');
                body.style.pointerEvents = 'all'
                root.style.pointerEvents = 'all'

                this.dataTienda();
                this.actualizarCantidadCarrito();
                this.setState({loading: false})
            }) 

            return promesa;
        } 
    }

    definirBackGround = (edicion) => {
        var color = 'white'
        if((this.estaEnArregloPuntos(edicion) && edicion.utilizaPunto) || edicion.cantidad === 'empty' || this.mostrarExcesoStock(edicion) === 'inline' || edicion.excedidoStock < 0) {
            color = 'hsla(14, 100%, 53%, 0.5)'
        }
            
        return color
    }

    calcularExcedentePedido = (edicion) => {
        return Math.abs(edicion.stockInicial - edicion.cantidad)
    }
    
    mostrarExcesoStock = (edicion) => {
        const { carritoVirtual } = this.state;
        let suma= 0
        carritoVirtual.forEach(e => {
            if(e.edicionId === edicion.edicionId) {
                suma += e.cantidad
            }

            return suma
        } )

       return edicion.stockInicial - suma < 0 ? 'inline' : 'none'
       
    }

    checkearEdicionEnCarrito = (edicion) => {
        const { carritoVirtual } = this.state
        let ind = carritoVirtual.findIndex(e => e.edicionId === edicion.edicionId && e.utilizaPunto === edicion.utilizaPunto)
        if (ind === -1 && edicion.cantidad > 0) {
            carritoVirtual.push(edicion)
            this.setState({
                carritoCantidad: edicion.cantidad === 0 ?  this.state.carritoCantidad : this.state.carritoCantidad + 1,
                carritoVirtual
            })
        } else if (edicion.cantidad === 0 && ind !== -1) {
            carritoVirtual.splice(ind, 1)
            this.setState({
                carritoCantidad: edicion.cantidad === 0 ?  this.state.carritoCantidad - 1 : this.state.carritoCantidad,
                carritoVirtual
            })
        }
    }

    checkearEdicionConExcesoEnStock = () => {
        const { backendrows } = this.state;
        let mostrarConfrimarPedido = true;
        backendrows.forEach(element => {
            if (element.stockInicial - element.cantidad < 0) {
                mostrarConfrimarPedido = false;
            }
        }); 

        return mostrarConfrimarPedido
    }

    checkearEdicionEnArregloPuntos = (edicion, totalPuntos) => {
        const { arregloExcedioPuntos } = this.state;
        var ind = arregloExcedioPuntos.findIndex((e) => edicion.edicionId === e.id && edicion.utilizaPunto === e.utilizaPunto);

        if (totalPuntos < 0 && ind === -1) {
            arregloExcedioPuntos.push(edicion);
        } else if (totalPuntos >= 0) {
            arregloExcedioPuntos.splice(0,arregloExcedioPuntos.length);
        }
    }

    incentivoPedidoCompraHabilitadoMostrarPuntos = async (pedidoPasadoContenedorId) => {
        var url = urlServer + '/api/configuracionItem/configuracion/buscarLigeroPorClave';
        var data = {
            clave: 'Tienda.Incentivos.PedidoCompra.Habilitado',
            entidadId: null
        }

        await fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers:{
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.validarVencimientoToken(result.message);
            var incentivoHabilitadoMostrarAcumulado = result[0].valor === '1';
            if (incentivoHabilitadoMostrarAcumulado)
            {
                this.mostrarPuntosAcumulados(pedidoPasadoContenedorId);
            }
            else
            {
                MySwal.fire({
                    icon: 'success',
                    title: 'Pedido realizado con éxito!',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: 'swalIconCustom'
                })

                let body = document.getElementById('body');
                let root = document.getElementById('root');
                body.style.pointerEvents = 'all'
                root.style.pointerEvents = 'all'

                this.setState({
                    redirect: true,
                    successCC: true,
                    loading: false
                }) 
               
            }

        }).catch((error) => {
            let body = document.getElementById('body');
            let root = document.getElementById('root');
            body.style.pointerEvents = 'all'
            root.style.pointerEvents = 'all'
            this.validarVencimientoToken(error.message);
            console.log('error al verificar si incentivo habilitado, ', error);
            this.setState({loading: false})
        })
    }

    obtenerTotalPuntos = async (pedidoPasadoContenedorId) => {
        var url = urlServer + `/api/pedidopasadofuturo/obtenerTotalPuntos/${pedidoPasadoContenedorId}`;

        const respuesta = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
            
        }).then((response) => {
            return response.json();        
        }).then((result) => {
            return result[0].totalPuntosAcumulado
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error, ', error);
        })

        return respuesta
    }

    calcularPuntos = (edicion) => {
        
        let totalPuntosAcumulado = 0;
        this.state.backendrows.filter(e => e.cantidad > 0 && e.utilizaPunto).map(function puntosTotalDeEdiciones(e) {
            totalPuntosAcumulado = e.cantidad * e.puntos + totalPuntosAcumulado
        })
        let totalPuntos = edicion.puntosInicial - (totalPuntosAcumulado ? totalPuntosAcumulado : 0)
        return totalPuntos
    }

    mostrarPuntosAcumulados = (pedidoPasadoContenedorId) => {
        var url = urlServer + '/api/configuracionItem/consultar/configuracion';
        var data = {
            entornoId: 5,
            ambitoId: 4,
            clave: 'Incentivos.Relacion.Pesos.Puntos',
            entidadId: null,
            incluirItemsSinConfigurar: true
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
            
        }).then((response) => {
            return response.json();        
        }).then(async (result) => {
            this.validarVencimientoToken(result.message);
            var puntosGanados = await this.obtenerTotalPuntos(pedidoPasadoContenedorId);
            var msj = 'Pedido realizado con éxito!';
            var leyendaPuntos = '';
            if (puntosGanados === 1 ) leyendaPuntos = 'punto';
            if (puntosGanados > 1 ) leyendaPuntos = 'puntos';
            if (puntosGanados > 0 ) msj = `Pedido realizado con éxito! Ha sumado ${puntosGanados} ${leyendaPuntos}`;
            MySwal.fire({
                icon: 'success',
                title: msj,
                showConfirmButton: false,
                timer: 3000,
                customClass: 'swalIconCustom'
            })

            let body = document.getElementById('body');
            let root = document.getElementById('root');
            body.style.pointerEvents = 'all'
            root.style.pointerEvents = 'all'
            
            this.setState({
                redirect: true,
                successCC: true
            })
        }).catch((error) => {
            this.validarVencimientoToken(error.message);

            let body = document.getElementById('body');
            let root = document.getElementById('root');
            body.style.pointerEvents = 'all'
            root.style.pointerEvents = 'all'
            console.log('error, ', error);

            this.setState({loading: false})
        })
    }

    verificarStockDisponible = () => {
        if(!this.state.seleccionadasFlag){

            ReactGA.event({
                category: 'Tienda/CargarPedido',
                action: 'Ver Carrito'
              });

            this.setState({
                seleccionadasFlag: true
            });
            this.setState({loading: false});
            return;
        }

        var stockSuficiente = true;
        var urlStock = urlServer + '/api/tienda/edicionTiendaStockDisponible';
        var filtro = null; 
        var promesas = this.state.backendrows.filter(c => c.cantidad > 0).map((edicion, index) => {
            filtro = {
                edicionId: edicion.edicionId,
                cantidad: edicion.cantidad,
                nombreAlmacenGlobal: 'Tienda Agea'
            };

            return fetch(urlStock, {
                method: 'POST',
                body: JSON.stringify(filtro),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'bearer ' + localStorage.token
                }
            }).then(response => {
                return response.json();
            }).then(result => {
                this.validarVencimientoToken(result.message);

                if(result.stockResultante < 0)
                {
                    edicion.excedidoStock = result.stockResultante;
                    edicion.stockInsuficiente = true;
                    var index = this.state.backendrows.findIndex(c => c.edicionId === edicion.edicionId)
                    this.state.backendrows[index] = edicion;
                    stockSuficiente = false;
                }

            }).catch(error => {
                this.validarVencimientoToken(error.message);
                console.log('error: ' + error);
                this.setState({loading: false});
            })
        })

        Promise.all(promesas).then(() => {
            if(stockSuficiente === true)
                this.enviarPedido();
            else
            {
                this.dataTienda(true);

                MySwal.fire({
                    icon: 'error',
                    title: 'Productos con stock insuficiente. Disminuya la cantidad solicitada o saque el producto del carrito.',
                    showConfirmButton: true,
                    confirmButtonText: 'Aceptar',
                    customClass: 'swalIconCustom'                    
                });

                let body = document.getElementById('body');
                let root = document.getElementById('root');
                body.style.pointerEvents = 'all'
                root.style.pointerEvents = 'all'

                this.setState({loading: false});
            }
        });
    }

    redireccionar =()=>{
        if(this.state.redirect){
            if(this.state.successCC ){
                var path = this.props.props.location.pathname === '/ClubParadaOnline' ? this.props.props.location.pathname : '/Tienda' 
                return <Redirect to={{
                    pathname: `${path}/MisPedidos`
                }} />
            }
            return <Redirect push to={{
                pathname: this.state.seleccionadasFlag?"/Tienda":"/"
            }} />
        }
    }

    mostrarTotalCompraTienda = () => {
        var aux = [];
        aux = this.state.backendrows.filter(a => a.cantidad > 0 && (!a.utilizaPunto || a.utilizaPunto === 0))
        aux = aux.map((card, index) => {
            return {precioVenta: card.precioVenta, cantidad: card.cantidad, etiquetaDescuento: card.etiquetaDescuento, precioConDescuento:card.precioConDescuento, esInterior:card.esInterior}
        });

        var total = 0;
        var subTotal = 0;
        for(var a = 0; a < aux.length; a++)
        {
            subTotal = this.redondearPrecio(aux[a].esInterior === false  && aux[a].etiquetaDescuento.length > 0 ? aux[a].precioConDescuento : aux[a].precioVenta) * aux[a].cantidad;
            total += subTotal;
        }
        return this.redondearPrecio(total);
    }

    mostrarTotalPuntoTienda = () => {
        var aux = [];
        aux = this.state.backendrows.filter(a => a.cantidad > 0 && (a.utilizaPunto === true )).map((card, index) => {
            return { cantidad: card.cantidad, puntos: card.puntos }
        });

        var totalpuntos = 0;
        var subTotaltotalPuntos = 0;
        for (var a = 0; a < aux.length; a++) {
            subTotaltotalPuntos = aux[a].cantidad * aux[a].puntos;
            totalpuntos += subTotaltotalPuntos;
        }
        return totalpuntos;
    }

    redondearPrecio = (numero) => {
        return numero > 0 ? numero.toFixed(2): 0;
    }

    addDefaultSrc(ev){
        ev.target.src = `${tienda}`
    }

    addDefaultBannerSrc(ev){
        ev.target.src = `${tienda}`
    }

    validacionPuntos = (edicion) => {
        return (edicion.utilizaPunto && (this.state.puntos < edicion.puntos || this.state.puntos < 0 || this.state.puntos - edicion.puntos < 0)) ? true : false
    }

    actualizarCantidadCarrito = () => {
        ReactGA.event({
            category: 'Tienda/actualizarCantidadCarrito',
            action: 'actualizar cantidad en carrito'
        });

        this.setState({
            loading: true
        })

        // Recuperar ediciones del carrito 
        var urlCarrito = urlServer + "/api/tienda/carrito/obtener";
        var dataCarrito = {
            UsuarioId: null
        };

        fetch(urlCarrito, {
            method: 'POST',
            body: JSON.stringify(dataCarrito),
            headers: {
                'Authorization': 'bearer ' + localStorage.token,
                'Content-Type': 'application/json'
            }
        }).then(responseCarrito => {
            return responseCarrito.json();
        }).then(resultCarrito => {
            this.validarVencimientoToken(resultCarrito.message);

            this.setState({
                loading: false
            })
            this.props.actualizarNumeroCarrito(resultCarrito.length)
        }).catch(errorCarrito => {
            this.validarVencimientoToken(errorCarrito.message);

            console.log("error al precargar carrito", errorCarrito);
        });
    }

    tiendaCarritoActualizar = (edicionId, edicionCantidad, utilizaPunto) => {
        
        var url = urlServer + '/api/tienda/carrito/actualizar';
        var data = {
            usuarioId: null,
            edicionId: edicionId,
            cantidad: edicionCantidad,
            utilizaPunto: utilizaPunto
        };
        
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'bearer ' + localStorage.token,
                'Content-Type': 'application/json' 
            }
        }).then( response => {
            return response.json();
        }).then(result => {
            this.validarVencimientoToken(result.message);
            if (edicionCantidad === 0) {
                this.actualizarCantidadCarrito();
            }
        }).catch(error => {
            this.validarVencimientoToken(error.message);
            console.log('error', error);
        });
    }

    showAviso = () => {
        const {darAviso} = this.state;
        
        return darAviso ? 'flex' : 'none'
    }

    tiendaCarritoVaciar = (esCompra, edicionId, utilizaPunto) => {
        var url = urlServer + '/api/tienda/carrito/vaciar';
        var data = {
            usuarioId: null,
            esCompra: esCompra,
            edicionId: edicionId,
            utilizaPunto: utilizaPunto
        };
        this.setState({ loading: true })
        fetch(url, {
            method:'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization': 'bearer ' + localStorage.token,
                'Content-Type': 'application/json'
            }
        }).then(response => {
            
            this.puntos();
            this.actualizarCantidadCarrito()
            this.setState({ loading: false })
            return response.json();
        }).then(result => {
            this.validarVencimientoToken(result.message);
        }).catch(error => {
            this.validarVencimientoToken(error.message);
            console.log('error', error);
        });
    }

    tiendaCarritoCambiarTipoDeEntrega = (edicion) => {
        var url = urlServer + '/api/tienda/carrito/cambiartipoentrega';
        var data = {
            edicionId: edicion.edicionId,
            puntoVentaId: null,
            cantidad: edicion.cantidad,
            usuarioId: null,
            utilizaPunto: edicion.utilizaPunto,
            tipoDeEntrega: edicion.tipoDeEntrega
        };
        
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'bearer ' + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            var aux = [...this.state.backendrows];
            var index = aux.findIndex(x => x.edicionId === edicion.edicionId && x.utilizaPunto === edicion.utilizaPunto);
            aux[index].tipoDeEntrega = edicion.tipoDeEntrega === 1 ? 0: 1;
            this.setState({
                backendrows: aux
            });
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log(error);
        });


    }

    estaEnArregloPuntos = (edicion) => {
        const { arregloExcedioPuntos } = this.state;

        let ind = arregloExcedioPuntos.findIndex(e => e.edicionId === edicion.edicionId && e.utilizaPunto === edicion.utilizaPunto)
        return ind !== -1 ? true : false
    }

    stockControl = () => {
        let stockInsuficiente = false;
        this.state.backendrows.forEach(e => {
            if (e.stockInicial - e.cantidad < 0) {
                stockInsuficiente = true;
            }
        })

        return stockInsuficiente
    }

    mostrarDSNohabilitada = () => {
        let mostrarMensaje = false;
        this.state.backendrows.forEach(e => {
            if (e.utilizaPunto === true && this.state.canjeHabilitado===false) {
                mostrarMensaje = true;
            }
            if (e.utilizaPunto === false && this.state.pedidoCompraHabilitado === false) {
                mostrarMensaje = true;
            }
        })
        return mostrarMensaje;

    }

    render() {
        const { loading, producto, seleccionadasFlag, backendrows } = this.state

        if (!this.props.props.location.state) {
            if (this.props.props.location.pathname === "/ClubParadaOnline") {
                return <Redirect push to={{
                    pathname: '/ClubParadaOnline',
                    state: {
                        producto: { "CC": true }
                    }
                }} />
            } else {
                return <Redirect push to={{
                    pathname: '/Tienda',
                    state: {
                        producto: { "CC": true }
                    }
                }} />
            }
        }

        return (
            <React.Fragment>
                <div id='cargarpedido' className="tienda text-left">
                    {this.redireccionar()}
                   
                    {loading ?

                        <Spinner style={{fontSize: '8px'}} />
                        : (<React.Fragment>
                            <div style={{display: this.mostrarCanje(), textAlign: 'center', fontWeight: 'bold', fontSize: '16px', paddingBottom: '5px', color:'#224372' }}>
                                <span>Puntos: {this.state.puntos <= 0 ? 0 : this.state.puntos}</span><br />
                                <div className='alerta-puntos' style={{display: this.showAviso()}}> 
                                    <span>Tus puntos se venceran en {this.state.diasRestantes} {this.state.diasRestantes === 1 ? 'dia' : 'dias'}</span>
                                </div>
                            </div>
                        {seleccionadasFlag ? 
                            <div style={{paddingBottom: '95px'}}>
                                {/* Carrito */}
                                { producto === null || this.state.producto.publicacionTipoId !== 2 ?
                                    (this.state.backendrows.filter(e => e.cantidad > 0 || e.cantidad === 'empty' || e.cantidad === 'cero').map((edicion, index) => {
                                        return  <div key={index} className="days itemCarTienda mb-4">
                                                    <div className="d-flex justify-content-between align-items-center pt-3">
                                                        <div className="f-13-5 fw-400" style={{color: '#343435', maxWidth: '60%'}}>
                                                            {edicion.esPreventa === true ? <div className="titleTagPreVentaItemCarrito"> Pre-venta </div>:null }
                                                            {edicion.edicionDescripcionCorta }
                                                            &nbsp;
                                                            &nbsp;
                                                            {edicion.utilizaPunto === false?
                                                                
                                                                <span  
                                                                    style={{ color: '#224372', fontWeight: 'bold' }}
                                                                > 
                                                                    $<span id={`edicion-dinero-${edicion.edicionId}`}>{this.redondearPrecio(this.redondearPrecio(edicion.esInterior === false && edicion.etiquetaDescuento.length > 0 ? edicion.precioConDescuento : edicion.precioVenta) * edicion.cantidad)}</span>
                                                                </span>
                                                                : 
                                                                <span 
                                                                    style={{ color: '#224372', fontWeight: 'bold' }}
                                                                    id={`edicion-punto-${edicion.edicionId}`}
                                                                >
                                                                    {/*edicion.puntos * (edicion.cantidad === "empty" || edicion.cantidad === "cero" && !edicion.utilizaPunto  ? 0 : edicion.cantidad)*/}
                                                                    {edicion.puntos * ((edicion.cantidad === "empty" || edicion.cantidad === "cero") && !edicion.utilizaPunto  ? 0 : edicion.cantidad)}
                                                                </span>
                                                            }
                                                            <br />
                                                            {edicion.excedidoStock < 0 ?
                                                                <span style={{fontWeight:'bold', color:'red'}}>El stock esta excedido en {edicion.excedidoStock * -1} {edicion.excedidoStock === 1 ? 'ejemplar' : 'ejemplares' } </span>
                                                            : null}
                                                            <div className='d-flex'>
                                                                <span id={`${edicion.edicionId}-${edicion.utilizaPunto ? 'canje' : 'pesos'}-aviso`} style={{color: 'red', display: this.showMensajeExceso(edicion)}}>
                                                                    Esta excedido en <span className='monto-exceso'>{ this.calcularExceso(edicion) }</span> <span> {this.calcularExceso(edicion) === 1 ? 'ejemplar' : 'ejemplares'} </span>
                                                                </span>
                                                                <span style={{color: 'red', display: this.mostrarExcesoStock(edicion)}}>
                                                                    Excedio el stock por {this.calcularExcedentePedido(edicion)} ejemplar/es.
                                                                </span>
                                                                {/*<span style={{ color: 'red', display: this.mostrarDSCompraNohabilitada(edicion) }}>*/}
                                                        <span style={{ color: 'red', display: this.mostrarMensajeDetalladoPeso(edicion) }}> {this.state.pedidoCompraHabilitadoMensaje}</span>
                                                            </div>

                                                    <div style={{
                                                        color: 'red', display: this.mostrarMensajeDetalladoCanje(edicion)}}> { this.state.canjeHabilitadoMensaje }</div>
                                                    <div style={{ display: this.estaEnArregloPuntos(edicion) && this.state.canjeHabilitado && edicion.utilizaPunto && this.state.puntos < 0 ? 'inline' : 'none', color: 'red' }}>Sus puntos no son suficientes.</div>
                                                        </div>
                                                        {/*BOTONES*/}
                                                        <div className="d-flex justify-content-between align-items-center " style={{width: '140px'}}>
                                                            <div className="d-flex justify-content-center align-items-center" 
                                                                style={{
                                                                    cursor: 'pointer', 
                                                                    background: !edicion.cantidad ? '#FCFCFC' : '#F4F4F4', 
                                                                    width: '26px', 
                                                                    height: '26px', 
                                                                    borderRadius: '50%'
                                                                }} 
                                                                onClick={() => {
                                                                    if (edicion.cantidad > 0) {
                                                                        edicion.cantidad--
                                                                        let arregloCantidad = this.state.backendrows.filter(e => e.cantidad > 0)
                                                                        arregloCantidad.forEach(e => {
                                                                            if(e.edicionId === edicion.edicionId) {
                                                                                e.stockDisponibleAlmacen = e.stockDisponibleAlmacen + 1
                                                                            }
                                                                        } )
                                                                        let totalPuntos = this.calcularPuntos(edicion);
                                                                        this.checkearEdicionEnArregloPuntos(edicion, totalPuntos);
                                                                        let canjeOTienda = edicion.utilizaPunto ? 'canje' : 'tienda'
                                                                        let elem = document.getElementById(`${canjeOTienda}-${edicion.edicionId}`)
                                                                        if (elem) {
                                                                            elem.value = edicion.cantidad
                                                                        }
                                                                        this.quitarDePublicacionesConExcedente(edicion)
                                                                        edicion.cantidadBackup = edicion.cantidad
                                                                        this.tiendaCarritoActualizar(edicion.edicionId, edicion.cantidad, edicion.utilizaPunto)
                                                                        edicion.totalPedido--
                                                                        if (edicion.stockInsuficiente) edicion.excedidoStock++;
                                                                        if(edicion.excedidoStock >= 0) edicion.stockInsuficiente = false
                                                                        let { backendrows } = this.state
                                                                        let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.utilizaPunto === edicion.utilizaPunto)
                                                                        backendrows[ind] = edicion
                                                                        this.setState({
                                                                            backendrows,
                                                                            puntos: totalPuntos
                                                                        })
                                                                    }
                                                                }}
                                                            >
                                                                <ReactSVG src={restar} style={{color: !edicion.cantidad? '#EAEAEA': '#8E95A5', width: '11px'}} />
                                                            </div>
                                                            &nbsp;
                                                            &nbsp;
                                                            <div className="f-13-5 fw-400" >
                                                                <input
                                                                    style={{
                                                                        backgroundColor: this.definirBackGround(edicion),
                                                                        border: '#343435 solid 1px' 
                                                                    }}
                                                                    key={index}
                                                                    type='number' 
                                                                    className='input-of-cantidades text-center'
                                                                    maxLength='5'
                                                                    min="0"
                                                                    max="99999"
                                                                    id= { edicion.utilizaPunto ? `canje-${edicion.edicionId}` : `tienda-${edicion.edicionId}`}
                                                                    value= {edicion.cantidad === "cero" ? 0 : edicion.cantidad}
                                                                    autoComplete="off"
                                                                    onFocus={(e) => {
                                                                        e.target.select()
                                                                    }}
                                                                    onInput={(e) => e.target.value = e.target.value.slice(0, 5)}
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === "Enter") {
                                                                            e.currentTarget.blur()
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        let { backendrows } = this.state;
                                                                        let regex = /[^0-9]/g
                                                                        if (regex.test(e.target.value)) {
                                                                            e.target.value = e.target.value.replace(regex,'')
                                                                        }
                                                                        edicion.cantidad = e.target.value === "" || edicion.cantidad === 'cero' || edicion.cantidad === "empty" ? 0 : parseInt(e.target.value);
                                                                        // INICIO VERSION 2 
                                                                        this.state.backendrows.map(elemento => {
                                                                            let cantidad = 0
                                                                            for (let i = 0; i < backendrows.length; i++) {
                                                                                if(elemento.edicionId === backendrows[i].edicionId) {
                                                                                    cantidad += backendrows[i].cantidad === 'empty' || backendrows[i].cantidad === 'cero' ? 0 : backendrows[i].cantidad                                                                               

                                                                                }
                                                                            }

                                                                            elemento.stockDisponibleAlmacen = elemento.stockInicial - cantidad
                                                                        })
                                                                        if (edicion.stockInsuficiente) edicion.excedidoStock = edicion.cantidad
                                                                        if(edicion.excedidoStock >= 0) edicion.stockInsuficiente = false
                                                                        edicion.totalPedido = edicion.cantidad
                                                                        this.quitarDePublicacionesConExcedente(edicion)
                                                                        let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && edicion.utilizaPunto === e.utilizaPunto)
                                                                        backendrows[ind] = edicion
                                                                        let totalPuntos = this.calcularPuntos(edicion)
                                                                        this.setState({ 
                                                                            backendrows,
                                                                            puntos: totalPuntos
                                                                        })
                                                                        if (edicion.stockDisponibleAlmacen >= 0 && this.mostrarExcesoStock(edicion) === 'none' && this.state.puntos >= 0 && edicion.cantidadBackUp !== edicion.cantidad )  {
                                                                            edicion.cantidadBackUp = edicion.cantidad
                                                                            
                                                                            this.checkearEdicionEnCarrito(edicion)
                                                                            this.tiendaCarritoActualizar(edicion.edicionId, edicion.cantidad, edicion.utilizaPunto)
                                                                        } else if(edicion.stockDisponibleAlmacen < 0) {
                                                                            MySwal.fire({
                                                                                icon: 'error',
                                                                                title: `La cantidad del ejemplar ${edicion.edicionDescripcionCorta} ha excedido el stock.`,
                                                                                showConfirmButton: true,
                                                                                confirmButtonText: 'Aceptar'
                                                                            });
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        let regex = /[^0-9]/g
                                                                        if (regex.test(e.target.value)) {
                                                                            e.target.value = e.target.value.replace(regex,'')
                                                                        }
                                                                        e.target.value =  e.target.value === "0" ?  e.target.value :  e.target.value.replace(/^(0+)/g, '');
                                                                        edicion.cantidad = e.target.value === "" ? 'empty' : e.target.value === "0" ? 'cero' : parseInt(e.target.value);
                                                                        this.state.backendrows.map(elemento => {
                                                                            let cantidad = 0
                                                                            for (let i = 0; i < backendrows.length; i++) {
                                                                                if(elemento.edicionId === backendrows[i].edicionId) {
                                                                                    cantidad += backendrows[i].cantidad === 'empty' || backendrows[i].cantidad === 'cero' ? 0 : backendrows[i].cantidad                                                                               

                                                                                }
                                                                            }

                                                                            elemento.stockDisponibleAlmacen = elemento.stockInicial - cantidad
                                                                        })
                                                                    
                                                                        let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && edicion.utilizaPunto === e.utilizaPunto)
                                                                        backendrows[ind] = edicion
                                                                        let totalPuntos = this.calcularPuntos(edicion)
                                                                        this.checkearEdicionEnArregloPuntos(edicion, totalPuntos)
                                                                        this.setState({
                                                                            backendrows: backendrows, 
                                                                            puntos: edicion.utilizaPunto ? totalPuntos : this.state.puntos
                                                                        })
                                                                    }}
                                                                />  
                                                            </div>
                                                            &nbsp;
                                                            &nbsp;
                                                            <div className="d-flex justify-content-center align-items-center" 
                                                                style={{
                                                                    background: ((edicion.stockDisponibleAlmacen <= 0 || this.validacionPuntos(edicion)) || this.showExceso(edicion) === 'inline'? '#FCFCFC' : '#F4F4F4'),
                                                                    width: '26px', 
                                                                    height: '26px', 
                                                                    borderRadius: '50%', 
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => {
                                                                    if (edicion.stockDisponibleAlmacen > 0 && (!edicion.utilizaPunto || (edicion.utilizaPunto && this.state.puntos >= edicion.puntos)) && this.showExceso(edicion) === 'none') {
                                                                        edicion.cantidad++;
                                                                        let arregloCantidad = this.state.backendrows.filter(e => e.cantidad > 0)
                                                                        arregloCantidad.forEach(e => {
                                                                            if(e.edicionId === edicion.edicionId) {
                                                                                e.stockDisponibleAlmacen = e.stockDisponibleAlmacen - 1
                                                                            }
                                                                        } )

                                                                        let canjeOTienda = edicion.utilizaPunto ? 'canje' : 'tienda'
                                                                        let elem = document.getElementById(`${canjeOTienda}-${edicion.edicionId}`)
                                                                        if (elem) {
                                                                            elem.value = edicion.cantidad
                                                                        }
                                                                        edicion.cantidadBackup = edicion.cantidad
                                                                        this.tiendaCarritoActualizar(edicion.edicionId, edicion.cantidad, edicion.utilizaPunto)
                                                                        edicion.totalPedido++

                                                                        if(edicion.stockInsuficiente) edicion.excedidoStock--;
                                                                        if(edicion.excedidoStock >= 0) edicion.stockInsuficiente = false
                                                                        let { backendrows } = this.state
                                                                        let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.utilizaPunto === edicion.utilizaPunto )
                                                                        backendrows[ind] = edicion
                                                                        this.setState({
                                                                            backendrows,
                                                                            puntos: this.state.puntos - (edicion.utilizaPunto === true ? edicion.puntos : 0)
                                                                        })
                                                                    }
                                                                }}>
                                                            <ReactSVG src={sumar} style={{ color: (edicion.stockDisponibleAlmacen <= 0 || this.validacionPuntos(edicion)) || this.showExceso(edicion) === 'inline' ? '#EAEAEA': '#8E95A5', width: '11px', height: '18px'}} />
                                                            </div>
                                                            { !this.state.retiroPresencial ? 
                                                            <div>
                                                                    {/* Quitar edicion del carrito */}
                                                                    <ReactSVG src={eliminar}
                                                                        style={{
                                                                            margin: '3px 0px 3px 3px',
                                                                            width: '23px',
                                                                            height: '23px',
                                                                            color: '#224372',
                                                                            background: '#C7E6F9',
                                                                            borderRadius: '50%'
                                                                        }}
                                                                        onClick={() => {
                                                                            edicion.stockDisponibleAlmacen += edicion.cantidad;
                                                                            var auxcant = edicion.cantidad;
                                                                            edicion.cantidad = 0;
                                                                            this.tiendaCarritoVaciar(0, edicion.edicionId, edicion.utilizaPunto);
                                                                            var index = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.utilizaPunto === edicion.utilizaPunto)
                                                                            backendrows[index] = edicion;
                                                                          
                                                                            if (index !== -1) {
                                                                               backendrows.splice(index, 1); 
                                                                            }
                                                                           
                                                                                this.setState({
                                                                                    backendrows: backendrows,
                                                                                    puntos: this.state.puntos + (edicion.utilizaPunto === true ? edicion.puntos * auxcant : 0)
                                                                                })
                                                                            }}/>
                                                            </div>
                                                            :null}
                                                            
                                                        </div>    
                                                    </div>  
                                                    { this.state.retiroPresencial ?     
                                                    <div className={`d-flex justify-content-between align-items-center ${edicion.edicionEsTipoDeEntregaMixta === true ? '': 'pb-3'}`}>
                                                        <div style={{color: '#224372', fontWeight:'bold'}}>
                                                            {edicion.tipoDeEntrega === 1 && edicion.oficinaRetiro !== null ?
                                                            <span>Retiro Presencial {edicion.oficinaRetiro}</span>
                                                            :
                                                            <span>Envio desde la Distribuidora</span>}
                                                        </div>
                                                    </div>    
                                                    :null } 
                                                    { this.state.retiroPresencial ? 
                                                    <div>
                                                        <div className="d-flex justify-content-between align-items-center">
                                                            { edicion.edicionEsTipoDeEntregaMixta === true && edicion.oficinaRetiro !== null ? 
                                                            <div style={{paddingBottom: edicion.edicionEsTipoDeEntregaMixta === true ? '1rem': '0rem'}}>
                                                                <div className="d-flex justify-content-center align-items-center" onClick={() => {
                                                                        Swal.fire({
                                                                            title: `Se modifica el tipo de entrega a: ${edicion.tipoDeEntrega === 1 ? 'Envio desde la distribuidora': 'Entrega Presencial'}`,
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            cancelButtonText: 'Cancelar',
                                                                            confirmButtonText: 'Confirmar',
                                                                            customClass: 'swalIconCustom'
                                                                            }).then((result) => {
                                                                            if (result.value) {
                                                                                this.tiendaCarritoCambiarTipoDeEntrega(edicion);
                                                                            }
                                                                        })

                                                                    }}
                                                                    style={{
                                                                        background: "#224372", color: "white", fontSize: "12px",
                                                                        textAlign: "center", cursor: "pointer", borderRadius: "6px",
                                                                        width: "170px", height: "33px"
                                                                    }}> Modificar Tipo de Entrega
                                                                </div>
                                                            </div>
                                                            : 
                                                            <div>
                                                                &nbsp;
                                                            </div>
                                                            }
                                                            <div style={{paddingBottom: '1rem'}}>
                                                                <div>
                                                                    {/* Quitar edicion del carrito */}
                                                                    <ReactSVG src={eliminar}
                                                                        style={{
                                                                            margin: '3px 0px 3px 3px',
                                                                            width: '23px',
                                                                            height: '23px',
                                                                            color: '#224372',
                                                                            background: '#C7E6F9',
                                                                            borderRadius: '50%'
                                                                        }}
                                                                        onClick={() => {
                                                                            edicion.stockDisponibleAlmacen += edicion.cantidad;
                                                                            var auxcant = edicion.cantidad;
                                                                            edicion.cantidad = 0;
                                                                            this.tiendaCarritoVaciar(0, edicion.edicionId, edicion.utilizaPunto);
                                                                            var index = backendrows.findIndex(e => e.edicionId === edicion.edicionId && e.utilizaPunto === edicion.utilizaPunto)
                                                                           
                                                                            if (index !== -1) {
                                                                                backendrows.splice(index, 1);
                                                                            }

                                                                            this.setState({
                                                                                backendrows: backendrows,
                                                                                    puntos: this.state.puntos + (edicion.utilizaPunto === true ? edicion.puntos * auxcant : 0)
                                                                                })
                                                                            }}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>     
                                                    :null}                              
                                                </div>
                                    }))
                                    :null}
                            </div>
                        : null}

                        {seleccionadasFlag && Object.keys(this.state.timerId).length === 0 &&  (
                            <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                                    <div className="d-flex justify-content-center align-items-center"
                                        onClick={() => {
                                            this.props.volverDeCarrito(seleccionadasFlag, this.state.backendrows)
                                        }}
                                        style={{
                                            background: "#224372",
                                            color: "white",
                                            fontSize: "12px",
                                            textAlign: "center",
                                            cursor: "pointer",
                                            borderRadius: "16px",
                                            width: "128px",
                                            height: "33px"
                                        }}>Seguir Comprando
                                    </div>
                            </div>
                        
                        )}
                        {this.state.backendrows.filter(e => e.cantidad > 0).length !== 0 ? 
                            <div>
                                {seleccionadasFlag? 
                                    <div id="barraPrecio" className="barra-precio" style={{backgroundColor:'white', color:'#224372', fontWeight:'bold'}}>
                                            <div className="d-flex justify-content-between">
                                                <div >
                                                    Total Pedido: $<span id='dinero-total'>{this.mostrarTotalCompraTienda()}</span>
                                                </div>
                                                
                                                <div style={{ display: this.mostrarCanje() }}>
                                                    Total Puntos: <span id='puntos-total'>{this.mostrarTotalPuntoTienda()}</span>
                                                </div>
                                            </div>
                                          
                                    </div>
                                    : null
                                }
                                { Object.keys(this.state.timerId).length === 0 && 
                                <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                                    {seleccionadasFlag && (
                                        <div className="d-flex justify-content-center align-items-center" style={{background: "#224372", color: "white", fontSize: "12px", textAlign: "center", cursor: "pointer", borderRadius: "16px", width: "88px", height: "33px"}} onClick={() => {
                                            backendrows.forEach(function(valor, indice, backendrows) {
                                                valor.stockDisponibleAlmacen += valor.cantidad;
                                                valor.cantidad = 0;
                                                backendrows[indice] = valor;
                                            });
                                            this.setState({
                                                backendrows
                                            })
                                            this.tiendaCarritoVaciar(0, null, null);
                                        }}>
                                            Vaciar Carrito
                                        </div>
                                    )}
                                    &nbsp;
                                    &nbsp;
                                    {seleccionadasFlag && (
                                            <div className="d-flex justify-content-center align-items-center"
                                                onClick={() => {
                                                    this.props.volverDeCarrito(seleccionadasFlag, this.state.backendrows)
                                                }}
                                                style={{
                                                    background: "#224372", color: "white", fontSize: "12px",
                                                    textAlign: "center", cursor: "pointer", borderRadius: "16px",
                                                    width: "115px", height: "33px"
                                                }}> Seguir Comprando
                                            </div>
                                    )}
                                    &nbsp;
                                    &nbsp;
                                    {
                                    this.state.puntos < 0 ?
                                        null
                                        :
                                        <div className="d-flex justify-content-center align-items-center"
                                                        onClick={() => {
                                                            var msjprodexc = ''

                                                            //informar que no tiene permiso y debe sacar ediciones
                                                            if (this.mostrarDSNohabilitada()) {
                                                               
                                                                msjprodexc = 'Por el momento no puede realizar esta acción. Para continuar retire los productos indicados del carrito.'
                                                                //si las dos opciones estan deshabilitada muestro msj generico (msj de tienda.habilidato)
                                                                if (this.mostrarMsjDSAlGuardar()) {
                                                                    
                                                                    msjprodexc = 'No puede realizar pedidos. Por favor, comuníquese con su Distribuidora'

                                                                    MySwal.fire({
                                                                        icon: 'error',
                                                                        title: msjprodexc,
                                                                        showConfirmButton: false,
                                                                        timer: 2000,
                                                                        customClass: 'swalIconCustom'
                                                                    });
                                                                } else {
                                                                    MySwal.fire({
                                                                        icon: 'error',
                                                                        title: msjprodexc,
                                                                        showConfirmButton: true,
                                                                        confirmButtonText: 'Aceptar',
                                                                        customClass: 'swalIconCustom'
                                                                    });
                                                                }

                                                               
                                                            }
                                                            else {
                                                                if (this.stockControl()) {
                                                                    msjprodexc = 'Productos con stock insuficiente. Disminuya la cantidad solicitada o saque el producto del carrito.'

                                                                    MySwal.fire({
                                                                        icon: 'error',
                                                                        title: msjprodexc,
                                                                        showConfirmButton: true,
                                                                        confirmButtonText: 'Aceptar',
                                                                        customClass: 'swalIconCustom'
                                                                    });
                                                                }
                                                                else {
                                                                    if (this.state.seleccionadasFlag) {
                                                                        let msj = "Está a punto de realizar un pedido sin devolución.";

                                                                        Swal.fire({
                                                                            title: '¿Desea realizar este pedido?',
                                                                            text: msj,
                                                                            icon: 'warning',
                                                                            showCancelButton: true,
                                                                            confirmButtonColor: '#3085d6',
                                                                            cancelButtonColor: '#d33',
                                                                            cancelButtonText: 'Cancelar',
                                                                            confirmButtonText: 'Confirmar Pedido',
                                                                            customClass: 'swalIconCustom'
                                                                        }).then((result) => {
                                                                            if (result.value) {
                                                                                this.setState({ loading: true }, () => {
                                                                                    let body = document.getElementById('body');
                                                                                    let root = document.getElementById('root');
                                                                                    body.style.pointerEvents = 'none';
                                                                                    root.style.pointerEvents = 'none';
                                                                                    this.controlMontoDiario();
                                                                                })
                                                                            }
                                                                        })
                                                                    } else {
                                                                        this.verificarStockDisponible();
                                                                    }
                                                                }

                                                            }
                                                        }}
                                            style={{
                                                background: "#224372",
                                                color: "white",
                                                fontSize: "12px",
                                                textAlign: "center",
                                                cursor: "pointer",
                                                borderRadius: "16px",
                                                width: "105px",
                                                height: "33px"
                                                
                                            }}>{!this.state.seleccionadasFlag ? "Ver Carrito" : "Confirmar Pedido"}
                                        </div>
                                    }
                                    
                                </div>
                                }
                            </div>
                            : null }
                            </React.Fragment>)
                    }
                </div>
            </React.Fragment>
        )
    }
}