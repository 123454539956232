import * as React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import PermisosApp from '../components/permisos.json';
import Devoluciones from '../pages/devoluciones';
import CargasDevoluciones from '../pages/cargasdevoluciones';

export default class PedidosCargasDevoluciones extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            render:[],
            results: [],
            links: [],
            accordion: false,
            urlRedirect:""
        }
    }

    history = createBrowserHistory()
    
    setLinks = this.props.setLinks
    componentDidMount() {
      document.title = "Devoluciones"

      this.setState({
        urlRedirect: "/Distribucion"
      })
    }

    allowedLinks = (arrLinks) => {
        const Links = JSON.parse(localStorage.infoToken)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
        const allowedLinks = arrLinks.map((e, index, self) => {
          const { path } = e.props
          if (path.toLowerCase().includes("distribucion")) {
            const initSlice = path.indexOf('/', path.indexOf('/') + 1)
            const stringlink = path.slice(initSlice + 1, undefined)
            var permisosUser = [];
            if (typeof Links !== 'undefined') {
              permisosUser = Links.filter(e => e.includes(stringlink))
            }
            if(permisosUser.length > 0) {
              return e
            }
          } else {
            return e
          }
        })
        return allowedLinks
      }

      componentWillUnmount() {
        this.props.hideMenu(true)
      }

      render(){
        const {urlRedirect} = this.state;
  
        return (
          <React.Fragment>
          <Router history={ this.props.props.history } > 
              <Switch>
                <Route exact path={urlRedirect} render={ (props) => {
                  const Links = JSON.parse(localStorage.infoToken)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]
                 
                  if (typeof Links !== 'undefined') 
                  {
                    const LinksApp = Links.filter(e => PermisosApp.includes(e))
                    var primerlink = [];
                    
                      primerlink = LinksApp.filter(e => e.toLowerCase().indexOf("distribucion.devoluciones") !== -1)[0] 
                                || LinksApp.filter(e => e.toLowerCase().indexOf("entregasdevoluciones") !== -1)[0] 
                    
                    if(primerlink && primerlink.toLowerCase().indexOf("distribucion.devoluciones") !== -1) {
                      return <Devoluciones hideMenu={this.props.hideMenu} props={props} />
                    } else if (primerlink && primerlink.toLowerCase().indexOf("entregasdevoluciones") !== -1) {
                      return <CargasDevoluciones hideMenu={this.props.hideMenu} props={props}  />
                    }  else {
                      return <div></div>
                    }
                  }else {
                    return <div></div>
                  }
                } }/>
                {this.allowedLinks([
                  <Route key={0} exact path={urlRedirect+"/Devoluciones"} render={ (props) => <Devoluciones props={props} hideMenu={this.props.hideMenu} /> }/>,
                  <Route key={1} exact path={urlRedirect+"/EntregasDevoluciones"} render={ (props) => <CargasDevoluciones hideMenu={this.props.hideMenu} props={props}/> }/>,
                ])}
              </Switch>
            </Router>
          </React.Fragment>
          )
      }
}