import React from 'react'
import { ReactSVG } from 'react-svg';
import gps from '../assets/gps.svg'
import money from '../assets/money.svg'
import urlServer from '../server'

const PopUpDatosUsuario = ({mostrarPopUp, mostrarDatos, datosIncompletos, hidePerfil, datosPV, updateMostrarPopUp, updateMostrarDatos, camposAModificar}) => {

    const validarVencimientoToken= (mensaje)=> {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
                return;
            }
        }
        return
    }

    const ArmarCadenaDatosFaltantes = () => {
        let cadena = '';

        if (camposAModificar.length === 1) {
            cadena += camposAModificar[0] + '.'
        } else {
            for (let i = 0; i < camposAModificar.length; i++) {
                if (i  === camposAModificar.length - 1 ) {
                    cadena += ' y ' + camposAModificar[i] + '.'
                } else if( i  === camposAModificar.length - 2 ) {
                    cadena += camposAModificar[i]
                }
                else {
                    cadena += camposAModificar[i] + ', '
                }
            }
        }
        
        return cadena
    }

    const renderPopUp = () => {
        return mostrarPopUp  ? 'flex' : 'none'
    }

    
    const GuardarDatosPerfil = () => {
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }
        const respuesta = fetch(urlServer + '/api/app/datosPerfil/guardar', {
            method: 'POST',
            redirect: 'manual',
            body: {},
            headers
        }).then(response => response.json())
        .catch(error => {
            validarVencimientoToken(error.message);
            console.log('error', error); 
        })
       
        return respuesta
    }

    return(
        <div className='popup' style={{display: mostrarPopUp ? 'flex' : 'none', fontFamily: 'Roboto', fontWeight: 600}}> 
            {!mostrarDatos ?
                <div className='d-flex flex-column justify-content-space-evenly align-items-center' style={{height: '90%'}}>
                    <div className='popup-icono' style={{height: '100%',color: datosIncompletos ? '#d33' : '#3085d6', borderColor: datosIncompletos ? '#d33' : '#3085d6' }}>
                        <div className='icono-contenedor' >
                            {datosIncompletos ?
                                <div className='icono' style={{color: '#d33'}}>!</div>
                                :
                                <div className='icono'>i</div>
                            } 
                        </div>
                    </div>
                    <div className='mensaje-contenedor' style={{marginTop: '15px', marginLeft:'7px', marginRight:'7px', marginBottom:'10px'}}>
                        {datosIncompletos ?
                            <span className='popup-mensaje' >
                                Estimado vendedor, desde la opción de Mi perfil, complete la información sobre: {ArmarCadenaDatosFaltantes()}
                            </span>
                            :
                            <span className='popup-mensaje'>
                                Estamos actualizando los datos del perfil. Por favor, verifique y confirme si los mismos son correctos.
                            </span>
                        }
                    </div>
                    <div className="popup-button" 
                        style={{ 
                                display: 'grid',
                                alignContent: 'center',                                                                                                        
                                placeContent: 'center',                                                    
                                color: 'white', 
                                fontWeight: '500', 
                                fontSize: '1.20em',
                                height: '45px',
                                position: 'relative', 
                                bottom:'0px',
                                padding:'0px',
                                marginTop: '10px',
                                backgroundColor: datosIncompletos ? '#d33' : '#3085d6',
                                lineHeight: 1
                            }}
                            onClick={ () => {
                                if (datosIncompletos) {
                                    hidePerfil();
                                    updateMostrarPopUp(false)
                                } else {
                                    updateMostrarDatos(true)
                                }
                            }}>
                            Continuar                                                    
                    </div>
                </div>
                :
                <div className='d-flex h-100 w-100 flex-column' style={{color: 'black', display: renderPopUp()}} >
                    <div className='dataPV-telefono container'>
                        <div className='container text-danger gps-perfil d-flex justify-content-center mt-2'>
                            <ReactSVG src={gps} className="" style={{width: '10px', height: '10px', color: '#224372'}}/>
                            <span className='' style={{paddingLeft: '5px'}}>Datos Actuales</span>
                        </div>
                        <div className='dato-pv border border-5 p-2 tel2d-flex flex-column mb-2'>
                            <div className='text-left'>{datosPV.informacionContacto.nombre + ' ' + datosPV.informacionContacto.apellido}</div>
                            <div className='text-left'> Telefono: {datosPV.informacionContacto.tel}</div> 
                        </div>
                        <div className='border border-5 p-2 mb-2'>
                            <div className='dato-pv calle d-flex flex-column'>
                                <div className='text-left'> Distribuidora: {datosPV.informacionPuntoVenta.distribuidora}</div> 
                            </div>
                            <div className=' dato-pv partido flex-column d-flex'> 
                                <div className='text-left' style={{ display: datosPV.informacionPuntoVenta.tipo === "DDRCloud" ? 'none' : 'inline' }}>
                                    Linea: { datosPV.informacionPuntoVenta.numeroLinea} 
                                </div> 
                            </div> 
                            <div className=' dato-pv partido flex-column d-flex'> 
                                <div className='text-left'> Vendedor: {datosPV.informacionPuntoVenta.codigoVendedor}</div> 
                            </div>
                            <div className=' dato-pv localidad flex-column d-flex'>
                                <div className='text-left'> Paquete: {datosPV.informacionPuntoVenta.paquete}</div>  
                            </div>
                        </div>
                        <div className='border border-5 p-2 mb-2'>
                            <div className='dato-pv calle d-flex flex-column'>
                                <div className='text-left'> Calle: { datosPV.domicilio.calle } </div> 
                            </div>
                            <div className='dato-pv calle d-flex flex-column'>
                                <div className='text-left'> Altura: { datosPV.domicilio.numero } </div> 
                            </div>
                            <div className=' dato-pv provincia flex-column d-flex'> 
                                <div className='text-left'> Provincia: {datosPV.domicilio.provinciaDescripcion}</div> 
                            </div>
                            <div className=' dato-pv partido flex-column d-flex'> 
                                <div className='text-left'> Partido: {datosPV.domicilio.partidoDepartamentoDescripcion}</div> 
                            </div>
                            <div className=' dato-pv localidad flex-column d-flex'>
                                <div className='text-left'> Localidad: {datosPV.domicilio.localidadDescripcion}</div>  
                            </div>
                        </div>
                        <div className='container text-danger gps-perfil d-flex justify-content-center'>
                            <ReactSVG src={money} className="" style={{width: '10px', height: '10px', fill: '#224372'}}/>
                            <span className='' style={{paddingLeft: '5px'}}>Medios Pago</span>
                        </div>
                        <div className='border border-5 p-2 mb-2'>
                            <div className=' dato-pv localidad flex-column d-flex' style={{display: !datosPV.mediosPago.efectivo ? 'none' : 'inline'}}>
                                <div className='text-left' style={{display: !datosPV.mediosPago.efectivo ? 'none' : 'inline'}}> Efectivo </div>  
                            </div>
                            <div className=' dato-pv localidad flex-column d-flex' style={{display: !datosPV.mediosPago.billeteraVirtual ? 'none' : 'inline'}}>
                                <div className='text-left' style={{display: !datosPV.mediosPago.billeteraVirtual ? 'none' : 'inline'}}> Billetera Virtual </div>  
                            </div>
                            <div className=' dato-pv localidad flex-column d-flex' style={{display: !datosPV.mediosPago.posnet ? 'none' : 'inline'}}>
                                <div className='text-left' style={{display: !datosPV.mediosPago.posnet ? 'none' : 'inline'}}> Posnet </div>  
                            </div>
                        </div>
                    </div>
                    
                    <div className='confirmar-actualizar-botones container' >
                        <div className='confirmar-boton-datos rounded' 
                            style={{
                                display: 'grid',
                                alignContent: 'center',
                                placeContent: 'center',                                                    
                                color: 'white', 
                                fontWeight: '500', 
                                fontSize: '1.20em',                                                    
                                width: '45%',
                                height: '42px',      
                                bottom: '40px',
                                backgroundColor: '#d33', 
                                lineHeight: 1
                            }}
                            onClick={() => {
                                updateMostrarDatos(false);
                                updateMostrarPopUp(false);
                                let campanita = document.getElementById('campanita');
                                if(campanita) campanita.style.pointerEvents = 'all'
                                let body = document.getElementById('body');
                                body.style.pointerEvents = 'all'
                                GuardarDatosPerfil()
                            }}>
                            Confirmar Datos
                        </div>
                        <div className='actualizar-boton-datos rounded' 
                            style={{
                                display: 'grid',
                                alignContent: 'center',
                                placeContent: 'center',                                                    
                                color: 'white', 
                                fontWeight: '500', 
                                fontSize: '1.20em',                                                    
                                width: '45%',
                                height: '42px',
                                bottom: '40px',
                                backgroundColor: '#3085d6', 
                                lineHeight: 1
                            }}
                            onClick={() => {
                                hidePerfil()
                                updateMostrarPopUp(false);
                                updateMostrarDatos(false);
                            }}>
                            Modificar Datos
                        </div>
                    </div>
                    
                </div>
            }
        </div>
    )
}

export default PopUpDatosUsuario