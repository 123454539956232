import './modalsinglebutton.css'
import React from 'react'
import { ArrowUpModalSingleButton } from './items'

function ModalSingleButton(props) {
    
    if(!props.verModal)
    {
        return null;
    }

    return (
        <div className="modal-singlebutton" id='modal-singlebutton'>
            <div className="modal-content">
                <div className="modal-header">
                    <div className="modal-title">
                        {props.titulo}
                    </div>
                </div>
                <div className="modal-body" id='modal-body-id-singlebutton'>
                    <div>
                        {props.children}
                    </div>
                    <ArrowUpModalSingleButton />
                </div>
                <div className="modal-footer">
                    <button className='close-button' onClick={props.accion}>
                        {props.textoBoton}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ModalSingleButton