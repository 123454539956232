import * as React from 'react';
import { Title } from '../components/title'
import Spinner from '../components/spinner'
import urlServer from '../server'
import ReactGA from 'react-ga';
import { ReactSVG } from 'react-svg';
import eraser from '../assets/eraser.svg'

//const DateInitFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/, '$3/$2/$1 $4:$5:$6').slice(0, -14)} else { return "" } };

const DateFormatter = ( value ) => {if(value) { return value.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1')} else { return "" } };

export default class HojaRuta extends React.Component {
    constructor(props){
      super(props);
      this.state={
        render:[],
        results: [],
        fecha: new Date().toISOString(),
        fechaFiltrada: "",
        loading: false,
        rows: [],
        accordion: false,
        initPostHojaRuta: {
          puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
          fecha: new Date().toISOString().slice(0,10)
        },
        postHojaRuta: {
          puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
          fecha: new Date().toISOString().slice(0,10)
        },
      }
    }
    validarVencimientoToken(mensaje)
    {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
            }
        }
        return
    }

    reqHojaRuta = async () => {

      ReactGA.event({
        category: 'Suscripciones/HojaDeRuta',
        action: 'Listar Hoja de Ruta'
      });

      const response = await this.filtrarHojaRuta(this.state.postHojaRuta)
      return await response
    }

    filtrarHojaRuta = async (data) => {    
      //const fechaHoja = date ? date : `${new Date().toISOString()}`
      
      this.setState({
        loading: true
      })
      this.setState({
        fechaFiltrada: DateFormatter(data.fecha)
        //fechaFiltrada: date ? DateFormatter(date) : DateInitFormatter(new Date().toISOString())
      })

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }

      fetch(urlServer + "/api/suscripciones/HojaRuta/listado", {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(data),
        headers,
      })
      .then(response => {
        if (parseInt(response.status) === 200) {
          return response.text()
        } else {
          const error = new Error(response.status)
          return error
        }
      })
      .catch(error => {
        console.log('error', error)
        this.validarVencimientoToken(error.message);
        this.setState({
          loading: false
        })
      })
      .then(result => {
        let res = JSON.parse(result);
        this.validarVencimientoToken(res.message);
        this.setState({
          rows: (JSON.parse(result))  ,
          loading: false
        })
      })
      .catch(error => {
        console.log('error', error)
        this.validarVencimientoToken(error.message);
        this.setState({
          loading: false
        })
      });
    }

    imprimirHojaRuta = async () => {

      ReactGA.event({
        category: 'Suscripciones/HojaDeRuta',
        action: 'Exportar Hoja de Ruta'
      });

      document.getElementById('open-modal').click()
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + localStorage.token,
      }
      const dataBody = {
        reporteTipo: 1,
        puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
        fecha: this.state.postHojaRuta.fecha ,//new Date().toISOString().slice(0, -14),
      }
      const respuesta = await fetch(urlServer + '/api/suscripciones/HojaRuta/Listado', {
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(dataBody),
        headers
      }).then((response) => {
            return response.arrayBuffer();
        })
        .catch((error) => {
            document.getElementById('close-modal').click();
            console.log('error', error);
            this.validarVencimientoToken(error.message);
        })
        .then((result) => {
            document.getElementById('close-modal').click();
            var resultcontent = result;
            var byteArray = new Uint8Array(resultcontent);
            const newBlob = new Blob([byteArray], { type: "application/pdf" });
            const data = window.URL.createObjectURL(newBlob);
            let link = document.createElement('a');
            if (window.__POIsNativeMode) {
                link.href = `blobDownload/HojaDeRuta-Suscripcion-${dataBody.fecha.replace('/', '-').replace('/', '-') }.pdf/${byteArray}`;
            } else {
                link.href = data;
                link.download = `HojaDeRuta-Suscripcion-${dataBody.fecha}.pdf`;
                setTimeout(function () {
                    window.URL.revokeObjectURL(data);
                }, 100);
            }
            link.click();
          })
      .catch(error => {
        document.getElementById('close-modal').click();
        console.log('error', error);
        this.validarVencimientoToken(error.message);
      })

      return await respuesta
    }
    
    setLinks = this.props.setLinks

    clearFilter = () => {
      const fecha = document.getElementById('fecha') ? document.getElementById('fecha') : null
      fecha.value = fecha ? '' : null
      this.setState({
        postHojaRuta: this.state.initPostHojaRuta
      })
    }
    
    componentDidMount() {
      this.reqHojaRuta()
    }

    componentWillUnmount() {
      this.props.hideMenu(true)
    }


    render(){
      const { rows, fechaFiltrada, loading } = this.state
      
      return ( <div id="hojaRuta" className="backapp container text-left">
                <Title 
                  title='Hoja de Ruta'
                  accordion={this.state.accordion}
                  alterAccordion={() => {this.setState({accordion: !this.state.accordion});}}
                />
                <div id="filtro" className={"row overflow-hidden"}>
                  <div className={"filter d-flex justify-content-between overflow-hidden" + (this.state.accordion ? '  mt-0' : ' ')} >
                    <div style={{width: '50%'}} >
                      <div style={{marginBottom: '12px'}} >
                        Fecha
                      </div>
                      <div>
                          <input id="fecha" className="form-control filter-input" type="date"
                              value={this.state.postHojaRuta.fecha}
                              onChange={e => {
                              this.setState({
                                postHojaRuta: {
                                  ...this.state.postHojaRuta, 
                                  fecha: e.target.value
                                }
                              })
                            }} />
                      </div>
                    </div>
                    <div style={{width: '24%'}} >
                      <div className="eraser" onClick={() => this.clearFilter()}>
                        LIMPIAR
                        <ReactSVG src={eraser} style={{width: '16px'}} />
                      </div>
                      <div style={{width: '100%' }} className="btn button-theme " onClick={() => {this.reqHojaRuta(this.fecha)}}> Filtrar </div>
                    </div>
                  </div>
                </div>
                <div className="fecha-hoja-ruta fontWeight: '500'">
                  {fechaFiltrada}
                </div>
                <button id="open-modal" type="button" className="btn btn-primary" data-toggle="modal" data-target="#pdf-loader" style={{display: 'none'}}>
                </button>
                <div className="modal fade" id="pdf-loader" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content text-center">
                      <div className="modal-body">
                        <Spinner />
                      </div>
                      Descargando PDF...
                      <div className="modal-footer" style={{display: 'none'}}>
                        <button id="close-modal" type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="printers">
                  <div className="d-flex" onClick={() => this.imprimirHojaRuta()}>
                    <div className="printer">
                      <span>
                        
                      </span>
                    </div>
                    <div className="printer-text">
                      <span>
                        Hoja de ruta
                      </span>
                    </div>
                  </div>
                </div>
                {loading ? 
                <Spinner style={{fontSize: '8px'}} />
                :
                <div className="cards" >
                  {rows.length > 0 ? 
                  rows.map((card, index) => {
                    return card.suscriptores.map((suscriptor, index) => {
                      return(
                        <div key={index} className="box" style={{color: "#8E95A5"}} >
                          <div className="d-flex justify-content-between" style={{marginBottom: '15px'}} >
                            <div className="f-16" style={{color: '#EA3F3F', fontFamily: 'Roboto', fontWeight: '500'}} >
                              {suscriptor.suscriptor}
                            </div>
                          </div>
                          <div className="d-flex" >
                            <div className="w-50 hrutadesc">
                              PUB:
                              <span className="ml-1 hrutavalor">
                                {card.ProductoDescripcion}
                              </span>
                            </div>
                            <div className="w-50 hrutadesc">
                              DIR:
                              <span className="ml-1 hrutavalor">
                                {suscriptor.calle} {" " + suscriptor.altura} {suscriptor.piso ? " " + suscriptor.piso + "º" : ""} {suscriptor.depto ? " " + suscriptor.depto : ""}
                              </span>
                            </div>
                          </div>
                        </div>)
                    })

                  }) 
                  : <div className="not-found" >
                    No se encontraron resultados para la fecha {this.state.fechaFiltrada}
                  </div>}
                </div>
                }
              </div>
        )
    }
}