import urlServer from './server'
import { version } from '../package.json'
import { getExpiracionToken } from './localStorageManager'
const versionApp = version.replace(/\./g, '')


export async function guardarConexion(moduloClave, usuarioId, params = null) {
    const userAgent = window.navigator.userAgent;
    var deviceType = "Unknown";
    var browser = "Unknown";
    var modeloYVersion = "Unknown";

    // @TipoDispositivo
    if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)) {
        deviceType = 'Celular'
    } else {
        deviceType = 'Pc'
    }

    // @OS
    var OSName = "Unknown";
    if (userAgent.indexOf('Windows') !== -1) {
        OSName = 'Windows'
        if (userAgent.indexOf("Windows NT 10.0") !== -1) OSName = "Windows 10";
        if (userAgent.indexOf("Windows NT 6.3") !== -1) OSName = "Windows 8.1";
        if (userAgent.indexOf("Windows NT 6.2") !== -1) OSName = "Windows 8";
        if (userAgent.indexOf("Windows NT 6.1") !== -1) OSName = "Windows 7";
        if (userAgent.indexOf("Windows NT 6.0") !== -1) OSName = "Windows Vista";
        if (userAgent.indexOf("Windows NT 5.1") !== -1) OSName = "Windows XP";
        if (userAgent.indexOf("Windows NT 5.0") !== -1) OSName = "Windows 2000";
    }
    if (userAgent.indexOf("Mac") !== -1) OSName = "Mac/iOS";
    if (userAgent.indexOf("X11") !== -1) OSName = "UNIX";
    if (userAgent.indexOf("Linux") !== -1) OSName = "Linux";
    if (userAgent.indexOf("Android") !== -1) OSName = "Android";

    // @Browser
    if (userAgent.indexOf("Firefox") > -1) {
        browser = "Mozilla Firefox";
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
        browser = "Samsung Internet";
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browser = "Opera";
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (userAgent.indexOf("Trident") > -1) {
        browser = "Microsoft Internet Explorer";
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (userAgent.indexOf("Edge") > -1) {
        browser = "Microsoft Edge (Legacy)";
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (userAgent.indexOf("Edg") > -1) {
        browser = "Microsoft Edge (Chromium)";
        // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
    } else if (userAgent.indexOf("Chrome") > -1) {
        browser = "Google Chrome or Chromium";
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Apple Safari";
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
        browser = "Unknown";
    }

    // @ModeloYVersion
    // Ejemplo User Agent: "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    // Obtengo todo el substring de userAgent que se encuentra entre los primeros dos parentesis, ya que en todos los userAgent, ahi esta la version y modelo.
    // substring extraido del ejemplo: iPhone; CPU iPhone OS 11_4 like Mac OS X
    var firstIndex = userAgent.indexOf('(');
    var secondIndex = userAgent.indexOf(')', firstIndex);
    modeloYVersion = userAgent.substring(firstIndex + 1, secondIndex);

    const headers = {
        "Content-Type": 'application/json',
        "Accept": 'application/json',
        Authorization: 'Bearer' + localStorage.token
    }

    var pedidoPasadoContenedorId = null;
    var pedidoFuturoContenedorId = null;
    var reclamoTiendaId = null;
    var reclamoSuscripcionId = null;

    if (params !== null) {
        pedidoPasadoContenedorId = params.pedidoPasadoContenedorId || null;
        pedidoFuturoContenedorId = params.pedidoFuturoContenedorId || null;
        reclamoTiendaId = params.reclamoTiendaId || null; 
        reclamoSuscripcionId = params.reclamoSuscripcionId || null; 
    }


    let expiracionToken = getExpiracionToken();

    var urlActual = window.location.pathname;

    const data = {
        tipoDispositivo: deviceType,
        modeloYVersion: modeloYVersion,
        oS: OSName,
        browser: browser,
        moduloClave: moduloClave,
        userAgent: userAgent,
        usuarioId: usuarioId,
        pedidoPasadoContenedorId: pedidoPasadoContenedorId,
        pedidoFuturoContenedorId: pedidoFuturoContenedorId,
        reclamoId: reclamoTiendaId,
        reclamoMensajeId: reclamoSuscripcionId,
        versionApp,
        urlActual,
        expiracionToken
    };
  
    const url = urlServer + "/api/app/guardarConexionUsuario"    
    const respuesta = await fetch(url,{
        method: 'POST',
        redirect: 'manual',
        body: JSON.stringify(data),
        headers: headers
    })
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.log(error))

    return respuesta
}