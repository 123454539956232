import * as React from 'react';
import { Redirect } from 'react-router-dom'
import { ReactSVG } from 'react-svg';
import { Title } from '../components/title'
import restar from '../assets/restar.svg'
import sumar from '../assets/sumar.svg'
import pedidos from '../assets/pedidos.svg'
import backArrow from '../assets/backArrow.svg'
import Spinner from '../components/spinner';
import urlServer from '../server'   
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { createBrowserHistory } from 'history'
import ReactGA from 'react-ga';
import CarritoPedidoDistribuidora from '../components/carritopedidodistribuidora'
//import { DataArrayRounded } from '@mui/icons-material';
import { version } from '../../package.json'

const MySwal = withReactContent(Swal)

const versionActual = version.replace(/\./g, '')

export default class CargarPedido extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            producto: this.props.props.location.state ? (this.props.props.location.state.producto.ediciones ? {
                ...this.props.props.location.state.producto,
                publicacionTipoId: 1,
            } : this.props.props.location.state.producto) : null,
            loading: false,
            busqueda: '',
            stock:[],
            redirect: false,
            flag: true,
            backendrows: this.props.props.location.state && this.props.props.location.state.producto.ediciones ? this.props.props.location.state.producto.ediciones.map(edicion => {
                    if (!edicion.cantidad && edicion.cantidad !== 0) {
                        edicion.cantidad = 0
                    }
                    return edicion
            }) : [],
            pedido: [],
            successCC: false,
            seleccionadasFlag: false,
            diasSemana: [
                {
                    dia: 'Lunes',
                    DiaSemana: 2,
                    cantidad: 0,
                },
                {
                    dia: 'Martes',
                    DiaSemana: 3,
                    cantidad: 0,
                },
                {
                    dia: 'Miércoles',
                    DiaSemana: 4,
                    cantidad: 0,
                },
                {
                    dia: 'Jueves',
                    DiaSemana: 5,
                    cantidad: 0,
                },
                {
                    dia: 'Viernes',
                    DiaSemana: 6,
                    cantidad: 0,
                },
                {
                    dia: 'Sábado',
                    DiaSemana: 7,
                    cantidad: 0,
                },
                {
                    dia: 'Domingo',
                    DiaSemana: 1,
                    cantidad: 0,
                },
            ],
            edicionesDiario: [],
            ultimasrows: [],
            siguientesrows: [],
            nroElementosCarrito: 0,
            busquedaTextoMostrarEnInput: '',
            timerId:[]
        }
    }

    history = createBrowserHistory()

    validarVencimientoToken(mensaje)
    {
        if (mensaje) {
            if (mensaje === "Authorization has been denied for this request." || mensaje.indexOf("Unexpected token") !== -1) {
                localStorage.removeItem('ddr-auth');
                localStorage.removeItem('ddr-token');
                localStorage.removeItem('is_authenticated');
                localStorage.removeItem('token');
                localStorage.removeItem('infoToken');
                // localStorage.removeItem('refresh-token');
                localStorage.removeItem('expires_in');
                localStorage.removeItem('expires_at');
                window.location.reload();
            }
        }
        //this.versionResquest();
        return
    }

    actualizarPermisos = (versionDB) => {
        var infoToken = JSON.parse(localStorage.infoToken)
        var data = {
            usuarioNombre: infoToken.name,
            ambitoId: 7,
            entidadId: infoToken.entidad_id,
            permisoNombre: 'kiosco'
        }
        var url = urlServer + "/api/account/permisos/kiosco/buscar";
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((permisos) => {

            infoToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] = [...permisos];
            localStorage.setItem("infoToken", JSON.stringify(infoToken));
            localStorage.setItem("versionLocal", versionDB);

            MySwal.fire({
                icon: 'info',
                title: 'Se est&aacute actualizando una nueva versi&oacuten de ParadaOnLine.',
                showConfirmButton: false,
                timer: 2000,
                customClass: 'swalIconCustom'
            }).then((result) => {
                if (result.value) {
                    this.setState({ loading: true })
                        window.location.reload();
                } else {
                    window.location.reload();
                }
            }).catch(error => {
                console.log('error', error)
            })

        }).catch((error) => {
            console.log('buscar permisos > error :', error)
        })

    }

    versionResquest = async () => {
        const headers = {
            "Content-Type": 'application/json',
            "Accept": 'application/json',
        }

        const url = urlServer + "/api/app/version"

        const respuesta = await fetch(url, {
            method: 'POST',
            redirect: 'manual',
            //body: JSON.stringify(data),
            headers: headers
        })
            .then(response => response.json())
            .then(result => {

                var versionDB = 0;
                if (typeof result !== 'undefined' && result.length > 0)
                {
                    versionDB = parseInt(result[0].versionValor,10);
                }

                if (versionDB > versionActual && (versionDB > localStorage.versionLocal || localStorage.versionLocal === undefined)) {
                    this.actualizarPermisos(versionDB)
                    
                }
            })
            .catch(error => {
                this.validarVencimientoToken(error.message);
                console.log('versionResquest > error :', error)
            })

        return respuesta
    }
    
    componentDidMount() {
        if(!this.state.backendrows || this.state.backendrows.length < 1) {
            this.ediciones();
        }
        document.title = "Cargar Pedido";
    }

    // funciones input inicio

    definirBackGround = (edicion) => {
        
        if(edicion.cantidad === null)
        {
            return 'hsla(14, 100%, 53%, 0.6)'
        }
        else{
            return 'white'
        }
    }

    handleValueInput = (cantidadActual) => {
        if (cantidadActual === "") {
            return ""
        } else {
            return cantidadActual
        }
    }

    // funciones input fin

    
    ediciones = async () => {

        ReactGA.event({
            category: 'Pedidos/CargarPedido',
            action: 'Listar Ediciones'
          });

        this.setState({
            loading: true
        })
        if(this.state.producto) {

            if (this.state.producto.publicacionTipoId !== 2) { // NO DIARIO

                const headers = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    Authorization: 'Bearer ' + localStorage.token,
                }
                const data = {
                    productoId: this.state.producto.productoId,
                    fechaHasta: new Date(new Date().setDate(new Date().getDate()-1)).toISOString(),
                    columnaParaOrdenar: "EDICIONFECHA DESC",
                    pageSize: 50                
                }
                const url = urlServer + "/api/edicion/buscarediciones/crearpedido"

                const respuesta = await fetch(url, {
                    method: 'POST',
                    redirect: 'manual',
                    body: JSON.stringify(data),
                    headers
                })
                .then(response => response.json())
                .then(async result => {
                    this.validarVencimientoToken(result.message);
                    let res = result
                    if(res.filter(e => !e.edicionId).length === 0) {
                        const siguiente = {
                            edicionId: null,
                            descripcion: "Siguiente Edición",
                            cantidad: 0
                        }
                        res = [
                            siguiente,
                            ...res,
                        ]
                    } else {
                        const ednull = res.filter(e => !e.edicionId).filter(e => e.descripcion.toLowerCase().indexOf('siguiente') !== -1)[0]
                        let ediciones = res.filter(e=> e.edicionId)
                        result = [
                            ednull,
                            ...ediciones,
                        ]
                    }
                    const data = res.map(edicion => {
                        if (!edicion.cantidad && edicion.cantidad !== 0) {
                            edicion.cantidad = 0
                        }
                        return edicion
                    })


                    // Obtengo ediciones cargadas en el carrito

                    const url = urlServer + '/api/pedidopasadofuturo/carrito/obtener'
                    var filtro = {
                        usuarioId: null
                    }
            
                    fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(filtro),
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + localStorage.token
                        }
                    }).then((response) => {
                        return response.json();
                    }).then((result) => {
                        this.validarVencimientoToken(result.message);
                        this.setState({nroElementosCarrito: result.length})
                        var ediciones = data.map((ed, index) => {
                            var ind = result.findIndex(x => x.edicionId === ed.edicionId && x.productoId === this.state.producto.productoId)
                            if(ind!== -1)
                            {
                                ed.cantidad = result[ind].cantidad
                            }
                            else{
                                ed.cantidad = 0
                            }
                            ed = {
                                ...ed,
                                cantidadBackup: ed.cantidad
                            }
                            return ed;
                        })

                        
                        this.setState({
                            backendrows: ediciones,
                            // rows: res,
                            loading: false
                        })
                    });

                })
                .catch(error => {
                    this.validarVencimientoToken(error.message);
                    console.log('error', error)
                    this.setState({
                        loading: false
                    })
                });
                return respuesta
            }
            else{ // DIARIO

                // Obtengo ediciones cargadas en el carrito

                const url = urlServer + '/api/pedidopasadofuturo/carrito/obtener'
                var filtro = {
                    usuarioId: null
                }
        
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(filtro),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.token
                    }
                }).then((response) => {
                    return response.json();
                }).then((result) => {
                    this.validarVencimientoToken(result.message);
                    this.setState({nroElementosCarrito: result.length})
                    var aux = JSON.parse(JSON.stringify(this.state.diasSemana));
                    var ediciones = aux.map((dia, index) => {
                        var ind = result.findIndex(x => x.productoId === this.state.producto.productoId && x.diaSemana === dia.DiaSemana)
                        if(ind !== -1)
                        {
                            dia.cantidad = result[ind].cantidad;
                        }
                        else{
                            dia.cantidad = 0;
                        }

                        dia = {
                            ...dia,
                            cantidadBackup: dia.cantidad
                        }
                        return dia;
                    })

                    this.setState({
                        edicionesDiario: ediciones,
                        loading: false
                    })
                }).catch((error) => {
                    this.validarVencimientoToken(error.message);
                    console.log('Error al ediciones cargadas en el carrito > ', error);
                });
            }
        }
        this.setState({
            loading: false
        })
    }

    enviarPedido = () => {
    
        ReactGA.event({
            category: 'Pedidos/CargarPedido',
            action: 'Enviar Pedido'
            });

        const url = urlServer + '/api/pedidopasadofuturo/guardar'
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            Authorization: 'Bearer ' + localStorage.token,
        }
        if (this.state.producto.publicacionTipoId !== 2) { // NO DIARIO
            //let error = false
            this.state.backendrows.filter(e => e.cantidad > 0).map(async (edicion) => {
                const data = {
                    productoId: this.state.producto.productoId,
                    edicionId: edicion.edicionId,
                    puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
                    usuarioId: JSON.parse(localStorage.infoToken).usuario_id,
                    cantidad: edicion.cantidad,
                    nombreTienda: null,
                }
                const respuesta = await fetch(url, {
                    method: 'POST',
                    redirect: 'manual',
                    body: JSON.stringify(data),
                    headers
                })
                .then(response => {
                    if (parseInt(response.status) !== 200) {throw response }

                    if(parseInt(response.status) === 200) {
                        return response.json()
                    } 
                })
                .then(result => {
                    this.validarVencimientoToken(result.message);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Pedido realizado con éxito!',
                        showConfirmButton: false,
                        timer: 1500,
                        customClass: 'swalIconCustom'
                    })
                    
                    this.setState({
                        redirect: true
                    })

                })
                .catch( err => {
                    this.validarVencimientoToken(err.message);

                    if (typeof err.text === 'function') {
                      err.text().then(errorMessage => {
                         var msj=JSON.parse(errorMessage)
                        
                        MySwal.fire({
                            icon: 'error',
                            title: msj.message,
                            showConfirmButton: false,
                            timer: 2000,
                            customClass: 'swalIconCustom'
                        })})                      
                    } else {
                        console.log(err)
                        MySwal.fire({
                        icon: 'error',
                        title: 'Ha ocurrido un error.',
                        showConfirmButton: false,
                        timer: 2000,
                        customClass: 'swalIconCustom'
                        })
                    }
                }) 
                return respuesta
            })
        } else {
            let error = false
            this.state.diasSemana.filter(e => e.cantidad > 0).map(async (dia) => {
                const data = {
                    productoId: this.state.producto.productoId,
                    puntoVentaId: JSON.parse(localStorage.infoToken).entidad_id,
                    usuarioId: JSON.parse(localStorage.infoToken).usuario_id,
                    cantidad: dia.cantidad,
                    diaSemana: dia.DiaSemana,
                }

                const respuesta = await fetch(url, {
                    method: 'POST',
                    redirect: 'manual',
                    body: JSON.stringify(data),
                    headers
                })
                .then(response => response.json())
                .then(result => {
                    this.validarVencimientoToken(result.message);
                })
                .catch(error => {
                    this.validarVencimientoToken(error.message);
                    console.log('error', error)
                    error = true
                });
                return respuesta
            })
            if (!error) {
                MySwal.fire({
                    icon: 'success',
                    title: 'Pedido realizado con éxito!',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: 'swalIconCustom'
                })
                this.setState({
                    redirect: true
                })
            } else {
                MySwal.fire({
                    icon: 'error',
                    title: 'Ha ocurrido un error.',
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: 'swalIconCustom'
                })
            }
        }
    }

    actualizarCarrito = (edicionId, cantidad, diaSemana) => {
        const url = urlServer + '/api/pedidopasadofuturo/carrito/actualizar'
        var data = {
            edicionId: edicionId,
            productoId: this.state.producto.productoId,
            cantidad: cantidad,
            diaSemana: diaSemana
        }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.token
            }
        }).then((response) => {
            return response.json();
        }).then((result) => {
            this.validarVencimientoToken(result.message);
        }).catch((error) => {
            this.validarVencimientoToken(error.message);
            console.log('error ', error)
        })
    }

    goBack = ()=>{
        this.setState({
            redirect: true,
        })
    }

    redireccionar =()=>{
        if(this.state.redirect){
            if(this.state.successCC){
                return <Redirect push to={{
                    pathname: "/Tienda/MisPedidos"
                }} />
            }
            return <Redirect push to={{
                pathname: "/Pedidos"
            }} />
        }
    }

    volverDeCarrito = () => {
        this.setState({seleccionadasFlag: false})
        this.ediciones();
        
    }

    render() {

        const { loading, producto, edicionesDiario, seleccionadasFlag, backendrows, nroElementosCarrito } = this.state
        
        if(!this.props.props.location.state) {
            return <Redirect to="/Pedidos/MisPedidos"/>
        }

        return (
            <React.Fragment> 
                {
                seleccionadasFlag ?
                    <CarritoPedidoDistribuidora volverDeCarrito = {this.volverDeCarrito} />
                :
                <React.Fragment>
                    <div id="backarrow" className="position-fixed back-arrow-box" onClick={this.goBack}>
                        <ReactSVG src={backArrow} />
                    </div>

                    <div id='cargarpedido' className="container text-left">
                                <React.Fragment>
                                <div className="d-flex justify-content-between">
                                    <Title classes=""title={seleccionadasFlag ? 'Ediciones seleccionadas' : "Cargar Pedido"}/>
                                    <div className="position-relative" style={{marginTop: '53px'}}>
                                        <ReactSVG src={pedidos} style={{width: '27px', height: '27px', color: '#8E95A5'}} />
                                        <div className="position-absolute d-flex justify-content-center align-items-center f-11" style={{right: '-8px', top: '-8px', background: '#EA3F3F', color: 'white', borderRadius: '50%', width: '16px', height: '16px'}}>
                                            { this.state.nroElementosCarrito }
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="titulo-producto">
                                    {producto.descripcion}
                                </div>
                            </React.Fragment>
                            
                        {this.redireccionar()}
                        {loading ? 
                        <Spinner style={{fontSize: '8px'}} />
                        :
                        (<React.Fragment>
                        {this.state.producto.publicacionTipoId !== 2 ? 
                                <div className="pedido" style={{paddingBottom: '95px'}}>

                                    {/* Pestañas pedidos */}
                                    {this.state.producto.publicacionTieneRelanzamiento ? <div className="tabs d-flex justify-content-between w-100 " style={{borderBottom: '1px solid gray', marginBottom: '18px'}}>
                                            <div className={"tablinks col-6 text-center" + (this.state.flag ? ' active' : '')} onClick={(e) => {this.setState({flag: true})}}>Últimas Ediciones</div>
                                            <div className={"tablinks col-6 text-center" + (!this.state.flag ? ' active' : '')} onClick={(e) => {this.setState({flag: false})}}>Siguientes Ediciones</div>
                                        </div> : null 
                                    }
                                        
                                    <div> 
                                        {/* Campo de busqueda para pedidos */}
                                        <div className="w-100" style={{marginBottom: '18px'}}>
                                            <input className="w-100 form-control"
                                                type="text"
                                                placeholder="Buscar"
                                                onChange={(e) => { this.setState({ 
                                                    busqueda: e.target.value.normalize('NFD').replace(/\p{Diacritic}/gu, "").replace(/\./g, ''),
                                                    busquedaTextoMostrarEnInput: e.target.value
                                                }) }} 
                                                value={this.state.busquedaTextoMostrarEnInput}/>
                                        </div>


                                    {/* Pedidos ultimas ediciones */}
                                    {backendrows.filter(e => (this.state.flag ? (e.edicionId > 0) : (typeof (e.edicionId) !== "number"))).filter(a => JSON.stringify(Object.values(a)).normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().indexOf(this.state.busqueda.toLowerCase()) !== -1).map((edicion, index) => {
                                            
                                        return <div key={index} className="box justify-content-between align-items-center days" style={{ padding: "10px", marginTop: "10px"}}>
                                            <div className="f-13-5 fw-500" style={{ color: '#343435', textAlign: 'justify' }}>
                                                {edicion.descripcion + (edicion.descripcion.indexOf(edicion.edicionNumeroFecha) !== -1 ? "" : (" " + (edicion.edicionNumeroFecha ? edicion.edicionNumeroFecha : "")))}
                                            </div>
                                            {/*CONTENEDOR*/}
                                            <div className="w-100 footerItem align-items-center d-flex" style={{ justifyContent: "right", paddingTop: "10px" }}>
                                                <div className="d-flex justify-content-between align-items-center elemento-botones-input">
                                                {/*RESTAR*/}
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{ cursor: 'pointer', background: !edicion.cantidad ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%', padding: '5px' }}
                                                    onClick={() => {
                                                        if (edicion.cantidad > 0) {
                                                            edicion.cantidad--
                                                            edicion.cantidadBackup = edicion.cantidad
                                                            let elemento = document.getElementById(edicion.edicionId);
                                                            if ( elemento ) {
                                                                elemento.value = edicion.cantidad;
                                                            } else {
                                                                elemento = document.getElementById('PF');
                                                                elemento.value = edicion.cantidad
                                                            }
                                                            let { backendrows } = this.state
                                                            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                                            backendrows[ind] = edicion
                                                            this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                                                            this.setState({
                                                                backendrows,
                                                                nroElementosCarrito: edicion.cantidad === 0 ? nroElementosCarrito - 1 : nroElementosCarrito
                                                            })
                                                        }
                                                    }}>
                                                    <ReactSVG src={restar} style={{ color: !edicion.cantidad ? '#EAEAEA' : '#8E95A5', width: '11px' }} />
                                                </div>
                                                {/*INPUT*/}
                                                <div className="f-13-5"
                                                    style={{ marginLeft: '10px', marginRight: '10px' }}>
                                                    <input style={{ backgroundColor: this.definirBackGround(edicion), border: '#343435 solid 1px'}}
                                                        type='number' 
                                                        autocomplete="off"
                                                        className='input-of-cantidades text-center'
                                                        maxLength='3'
                                                        id={edicion.edicionId ? edicion.edicionId : 'PF'}
                                                        key = {edicion.edicionId}
                                                        value ={edicion.cantidad}
                                                        onFocus={(e) => {
                                                            e.target.select()
                                                        }}
                                                        max="9999"
                                                        onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                                        onKeyPress={(e) => {
                                                            if(e.key === "Enter") {
                                                                e.currentTarget.blur()
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            let regex = /[^0-9]/g
                                                            if (regex.test(e.target.value)) {
                                                                e.target.value = e.target.value.replace(regex,'')
                                                            }
                                                            e.target.value =  e.target.value === "0" ?  e.target.value :  e.target.value.replace(/^(0+)/g, '');
                                                            //var cantidadAnterior = edicion.cantidad;
                                                            edicion.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
                                                            let { backendrows } = this.state;
                                                            let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
                                                            backendrows[ind] = edicion
                                                            //this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                                                            this.setState({
                                                                backendrows

                                                            })
                                                        }}
                                                        onBlur={(e) => {
                                                            let regex = /[^0-9]/g
                                                            if (regex.test(e.target.value)) {
                                                                e.target.value = e.target.value.replace(regex,'')
                                                            }
                                                            if(e.target.value === "")
                                                            {
                                                                edicion.cantidad = 0;
                                                                let nroElementosCarrito = this.state.nroElementosCarrito;
                                                                if(edicion.cantidad !== edicion.cantidadBackup)
                                                                {
                                                                    nroElementosCarrito = (edicion.cantidad === 0 || edicion.cantidad === null) && edicion.cantidadBackup > 0 ? this.state.nroElementosCarrito - 1 : (edicion.cantidad > 0 && (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null) ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito)
                                                                    edicion.cantidadBackup = 0;
                                                                    this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                                                                }

                                                                let { backendrows } = this.state;
                                                                let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
                                                                backendrows[ind] = edicion
                                                                this.setState({ 
                                                                    backendrows,
                                                                    nroElementosCarrito
                                                                    })
                                                            }   
                                                            else {
                                                                var nroElementosCarrito = (edicion.cantidad === 0 || edicion.cantidad === null) && edicion.cantidadBackup > 0 ? this.state.nroElementosCarrito - 1 : (edicion.cantidad > 0 && (edicion.cantidadBackup === 0 || edicion.cantidadBackup === null) ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito)
                                                                e.target.value =  e.target.value === "0" ?  e.target.value :  e.target.value.replace(/^(0+)/g, '');
                                                                edicion.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
                                                                if(edicion.cantidad !== edicion.cantidadBackup)
                                                                {
                                                                    this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                                                                    edicion.cantidadBackup = edicion.cantidad;
                                                                    let { backendrows } = this.state;
                                                                    let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId);
                                                                    backendrows[ind] = edicion
                                                                    this.setState({
                                                                        backendrows,
                                                                        nroElementosCarrito
                                                                    })
                                                                }
                                                            }
                                                        }}
                                                    />

                                                </div>
                                                {/*SUMAR*/}
                                                <div className="d-flex justify-content-center align-items-center"
                                                    style={{ cursor: 'pointer', background: edicion.cantidad === 9999 ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%', padding: '5px', pointerEvents: edicion.cantidad >= 9999 ? 'none' : '' }}
                                                    onClick={() => {
                                                        edicion.cantidad++
                                                        edicion.cantidadBackup = edicion.cantidad
                                                        let elemento = document.getElementById(edicion.edicionId);
                                                        if ( elemento ) {
                                                            elemento.value = edicion.cantidad;
                                                        } else {
                                                            elemento = document.getElementById('PF');
                                                            elemento.value = edicion.cantidad
                                                        }
                                                        
                                                        let { backendrows } = this.state
                                                        let ind = backendrows.findIndex(e => e.edicionId === edicion.edicionId)
                                                        backendrows[ind] = edicion
                                                        this.actualizarCarrito(edicion.edicionId, edicion.cantidad, null)
                                                        this.setState({
                                                        backendrows,
                                                        nroElementosCarrito: edicion.cantidad === 1 ? nroElementosCarrito + 1 : nroElementosCarrito
                                                    })
                                                    }}>
                                                    <ReactSVG src={sumar} style={{ width: '11px', height: '18px', color: '#8E95A5' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            
                                    })}

                                </div>
                            </div>:
                                <div className="pedido" style={{paddingBottom: '95px'}}>
                                    <div className="f-16 fw-400 text-center" style={{marginBottom: '30px'}}>
                                        Pedido futuro
                                    </div>
                                    <div>
                                        {this.state.edicionesDiario.map((dia, index) => {
                                            return  <div key={index} className="d-flex justify-content-between align-items-center days" >
                                                        <div className="f-13-5 fw-400" style={{color: '#343435', maxWidth: '66%'  }}>
                                                            {dia.dia}
                                                        </div>
                                                        {/*CONTENERDOR*/}
                                                        <div className="d-flex justify-content-between align-items-center" style={{ width: '88px' }}>
                                                            {/*RESTAR*/}
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{ cursor: 'pointer', background: dia.cantidad === 0 ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%' }}
                                                                onClick={() => {
                                                                        if (dia.cantidad > 0) {
                                                                            let { edicionesDiario } = this.state
                                                                            edicionesDiario[index].cantidad--
                                                                            edicionesDiario[index].cantidadBackup--

                                                                            let elemento = document.getElementById(`dia-${dia.DiaSemana}`);
                                                                            if ( elemento ) {
                                                                                elemento.value = dia.cantidad;
                                                                            }
                                                                            this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana)
                                                                            this.setState({
                                                                                edicionesDiario,
                                                                                nroElementosCarrito: dia.cantidad === 0 ? nroElementosCarrito - 1 : nroElementosCarrito

                                                                            })
                                                                        }
                                                                    }}>
                                                                    <ReactSVG src={restar} style={{color: dia.cantidad === 0 ? '#EAEAEA': '#8E95A5', width: '11px'}} />
                                                            </div>
                                                            {/*INPUT*/}
                                                            <div className="f-13-5">
                                                                <input
                                                                    style={{ backgroundColor: this.definirBackGround(dia), border: '#343435 solid 1px'}}
                                                                    type='number' 
                                                                    autocomplete="off"
                                                                    className='input-of-cantidades text-center'
                                                                    maxLength='3'
                                                                    //id={'dia-'+`${dia.DiaSemana}`}
                                                                    id={`dia-${dia.DiaSemana}`}
                                                                    value ={dia.cantidad}
                                                                    onFocus={(e) => {
                                                                        e.target.select()
                                                                    }}
                                                                    max="9999"
                                                                    onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
                                                                    onKeyPress={(e) => {
                                                                        if(e.key === "Enter") {
                                                                            e.currentTarget.blur()
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {
                                                                        //var cantidadAnterior = dia.cantidad;
                                                                        let regex = /[^0-9]/g
                                                                        if (regex.test(e.target.value)) {
                                                                            e.target.value = e.target.value.replace(regex,'')
                                                                        }
                                                                        e.target.value =  e.target.value === "0" ?  e.target.value :  e.target.value.replace(/^(0+)/g, '');
                                                                        dia.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
                                                                        edicionesDiario[index].cantidad = dia.cantidad
                                                                        //this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana)
                                                                        this.setState({
                                                                            edicionesDiario
                                                                        })
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        let regex = /[^0-9]/g
                                                                        if (regex.test(e.target.value)) {
                                                                            e.target.value = e.target.value.replace(regex,'')
                                                                        }
                                                                        if(e.target.value === "")
                                                                        {
                                                                            dia.cantidad = 0;
                                                                            let nroElementosCarrito = this.state.nroElementosCarrito;
                                                                            if(dia.cantidad !== dia.cantidadBackup)
                                                                            {
                                                                                nroElementosCarrito = (dia.cantidad === 0 || dia.cantidad === null) && dia.cantidadBackup > 0 ? this.state.nroElementosCarrito - 1 : (dia.cantidad > 0 && (dia.cantidadBackup === 0 || dia.cantidadBackup === null) ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito)
                                                                                dia.cantidadBackup = 0;
                                                                                this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana)
                                                                            }

                                                                            edicionesDiario[index] = dia;
                                                                            this.setState({ 
                                                                                edicionesDiario,
                                                                                nroElementosCarrito
                                                                             })
                                                                        }   
                                                                        else {
                                                                            let nroElementosCarrito = (dia.cantidad === 0 || dia.cantidad === null) && dia.cantidadBackup > 0 ? this.state.nroElementosCarrito - 1 : (dia.cantidad > 0 && (dia.cantidadBackup === 0 || dia.cantidadBackup === null) ? this.state.nroElementosCarrito + 1 : this.state.nroElementosCarrito)
                                                                            e.target.value =  e.target.value === "0" ?  e.target.value :  e.target.value.replace(/^(0+)/g, '');
                                                                            dia.cantidad = e.target.value === "" || e.target.value < 0 ? null : parseInt(e.target.value);
                                                                            if(dia.cantidad !== dia.cantidadBackup)
                                                                            {
                                                                                dia.cantidadBackup = dia.cantidad;
                                                                                this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana);
                                                                                edicionesDiario[index] = dia;
                                                                                this.setState({ 
                                                                                    edicionesDiario,
                                                                                    nroElementosCarrito
                                                                                 })
                                                                            }
                                                                        }
                                                                    }}
                                                                /> 
                                                            </div>
                                                            {/*SUMAR*/}
                                                            <div className="d-flex justify-content-center align-items-center"
                                                                style={{ cursor: 'pointer', background: dia.cantidad === 9999 ? '#FCFCFC' : '#F4F4F4', width: '26px', height: '26px', borderRadius: '50%', pointerEvents: dia.cantidad >= 9999 ? 'none' : '' }}
                                                                onClick={() => {
                                                                        let { edicionesDiario } = this.state
                                                            
                                                                        edicionesDiario[index].cantidad++
                                                                        edicionesDiario[index].cantidadBackup++
                                                                        let elemento = document.getElementById(`dia-${dia.DiaSemana}`);
                                                                        if ( elemento ) {
                                                                            elemento.value = dia.cantidad;
                                                                        }
                                                                    this.actualizarCarrito(null, dia.cantidad, dia.DiaSemana)
                                                                        this.setState({
                                                                            edicionesDiario,
                                                                            nroElementosCarrito: dia.cantidad === 1 ? nroElementosCarrito + 1 : nroElementosCarrito
                                                                        })
                                                                    }}>
                                                                <ReactSVG src={sumar} style={{width: '11px', height: '18px', color: '#8E95A5'}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                        })}
                                    </div>
                                </div>}
                            {/*VER CARRITO*/}
                            {this.state.producto.publicacionTipoId !== 2 ? (this.state.nroElementosCarrito > 0 ? 
                            <div>
                                <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                                    <div className="d-flex justify-content-center align-items-center"
                                        onClick={() => {this.setState({seleccionadasFlag: true})}}
                                        style={{ background: "#224372", color: "white", fontSize: "12px", textAlign: "center", cursor: "pointer", borderRadius: "16px", width: "105px", height: "33px" }}>
                                        Ver Carrito
                                    </div>
                                </div>
                            </div>
                            : null) : (this.state.nroElementosCarrito > 0 ? 
                            <div id="boton-enviar" className="d-flex justify-content-center align-items-center barra-enviar">
                                <div className="d-flex justify-content-center align-items-center"
                                    onClick={() => {this.setState({seleccionadasFlag: true})}}
                                    style={{ background: "#224372", color: "white", fontSize: "12px", textAlign: "center", cursor: "pointer", borderRadius: "16px", width: "105px", height: "33px" }}>
                                    Ver Carrito
                                </div>
                            </div>
                            : null)}
                        </React.Fragment>
                        )}
                    </div>
                </React.Fragment> }
            </React.Fragment>
            )
    }
}