import React from 'react';
import brandLogo from "../assets/brand-logo.png";
import { version } from '../../package.json';

export default class MiComprobanteEnlaceExpiro extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render () {
        return (
            <React.Fragment>
                <form id="resetpassword" className="backlogin h-100vh w-100">
                    <div className="login-title">
                        <img className="brand-logo mb-0" src={brandLogo} alt="brand-logo" />
                    </div> 
                    <div id="reset-form">
                    <h4 style={{fontSize: '1.5rem'}}>Descargar Comprobante</h4>
                    <br></br>
                    <div className="d-flex justify-content-center mx-auto" 
                            style={{marginBottom: "30px", lineHeight: "20px", width: '300px', fontSize: '14px' }}>
                            El enlace ha expirado o es Inválido. Vaya a la opción de Mis Comprobante en Pedidos de Tienda para descargarlo.
                    </div>
                    <div className="version mb-1" >v{version}</div>
                    </div>
                
                </form>
            </React.Fragment>
        )
    }
}